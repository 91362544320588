import "./Navbar.css";

import React, { Component } from "react";

import InstituteNavbar from "./InstituteNavbar";
import RightMenu from "./RightMenu";
import StudentNavbar from "./StudentNavbar";
import TutorNavbar from "./TutorsNavbar";
import AdminNavbar from "./AdminNavbar";
import { connect } from "react-redux";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isDropdownOpen: false,
      isMobileMenuOpen: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  renderNavbar = (mode) => {
    if (this.props.user) {
      if (this.props.user.user) {
        return <TutorNavbar mode={mode} />;
      }

      switch (this.props.user.user_type) {
        case 0:
          return <AdminNavbar mode={mode} />
        case 1:
          return <StudentNavbar mode={mode} />;
        case 2:
          return <TutorNavbar mode={mode} />;
        case 3:
          return <InstituteNavbar mode={mode} />;
        default:
          return <RightMenu mode={mode} />;
      }
    }
  };

  render() {
    return <React.Fragment>{this.renderNavbar()}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Navbar);
