import { reviewsConstants } from "../constants";

let initialState = {
  error: null,
  reviews: {},
  loading: false,
  success: false
};

export function review(state = initialState, action) {
  switch (action.type) {
    case reviewsConstants.REVIEWS_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case reviewsConstants.REVIEWS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.reviews,
        success: true
      };
    case reviewsConstants.REVIEWS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.message
      };
    default:
      return state;
  }
}
