import { authHeader, config, handleResponse } from "../helpers";

export const classDetailService = {
  getClassDetails,
  createLiveClass,
  getLiveClasses,
  getMyLiveClasses,
  deleteLiveClasses,
  getStartUrl,
  updateLiveClass,
  getLiveClass,
};

function getClassDetails(query) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/class_details/`, requestOptions)
    .then(handleResponse)
    .then((class_details) => {
      return class_details;
    });
}

function createLiveClass(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/live_classes/`, requestOptions)
    .then(handleResponse)
    .then((live_class) => {
      return live_class;
    });
}

function getLiveClasses() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/live_classes/`, requestOptions)
    .then(handleResponse)
    .then((live_classes) => {
      return live_classes;
    });
}

function getLiveClass(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/live_classes/${id}`, requestOptions)
    .then(handleResponse)
    .then((live_classes) => {
      return live_classes;
    });
}

function updateLiveClass(body, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/live_classes/${id}/`, requestOptions)
    .then(handleResponse)
    .then((live_class) => {
      return live_class;
    });
}

function deleteLiveClasses(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/live_classes/${id}/`, requestOptions)
    .then(handleResponse)
    .then((live_classes) => {
      return live_classes;
    });
}

function getMyLiveClasses() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/live_classes/my_classes/`, requestOptions)
    .then(handleResponse)
    .then((live_classes) => {
      return live_classes;
    });
}

function getStartUrl(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(
    `${config.apiUrl}/live_classes/${id}/start_class/`,
    requestOptions
  )
    .then(handleResponse)
    .then((live_class) => {
      return live_class;
    });
}
