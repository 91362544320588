import { liveClassConstants } from "../constants";

let initialState = {
  error: null,
  liveClasses: {
    results: [],
  },
  loading: false,
  detailsLoading: false,
};

export function liveClasses(state = initialState, action) {
  switch (action.type) {
    case liveClassConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case liveClassConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        liveClasses: action.liveClasses,
      };
    case liveClassConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case liveClassConstants.LIVE_CLASS_DETAILS_LOADING:
      return {
        ...state,
        detailsLoading: true,
      };
    case liveClassConstants.LIVE_CLASS_DETAILS_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        liveClass: action.liveClass,
      };
    case liveClassConstants.LIVE_CLASS_DETAILS_ERROR:
      return {
        ...state,
        detailsLoading: false,
        detailsError: action.error,
      };
    default:
      return state;
  }
}

let myLiveClassesState = {
  error: null,
  liveClasses: {
    results: [],
  },
  loading: false,
};

export function myLiveClasses(state = myLiveClassesState, action) {
  switch (action.type) {
    case liveClassConstants.MY_LIVE_CLASSES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case liveClassConstants.MY_LIVE_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        liveClasses: action.liveClasses,
      };
    case liveClassConstants.MY_LIVE_CLASSES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
