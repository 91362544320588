import { Dropdown, Layout, Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Avatar from "../components/Avatar";
import StudentSidebar from "../components/Sidebar/StudentSidebar";
import TutorSideBar from "../components/Sidebar/TutorSidebar";
import InstituteSidebar from "../components/Sidebar/InstituteSidebar";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import { userConstants } from "../constants";

const { Header, Sider, Footer, Content } = Layout;
const { SubMenu } = Menu;

class Dashboard extends React.Component {
  state = {
    collapsed: window.screen.width <= 640,
    selected_page: "dashboard",
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  renderSidebar = () => {
    if (this.props.user) {
      if (this.props.user.user) {
        return <TutorSideBar collapse={this.state.collapsed} />;
      }

      switch (this.props.user.user_type) {
        case 0:
          return <AdminSidebar collapse={this.state.collapsed} />;
        case 1:
          return <StudentSidebar collapse={this.state.collapsed} />;
        case 2:
          return <TutorSideBar collapse={this.state.collapsed} />;
        case 3:
          return <InstituteSidebar collapse={this.state.collapsed} />;
        default:
          return <StudentSidebar collapse={this.state.collapsed} />;
      }
    }
  };

  profileMenu = () => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to="/update-profile" className="flex items-center">
            <UserOutlined className="mr-2 align-middle" /> Profile
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" onClick={this.logout}>
          Logout
        </Menu.Item>
      </Menu>
    );
  };

  logout = () => {
    localStorage.clear(userConstants.AUTH_TOKEN);
    window.location = "/";
  };
  render() {
    return (
      <Layout className="dashboard bg-white">
        {this.renderSidebar()}
        <Layout className="site-layout">
          <Header
            className="site-layout-background shadow-sm flex"
            style={{ padding: 0 }}
          >
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}

            <div className="ml-auto mr-5">
              <Avatar user={this.props.user} className="mr-2" />
              <Dropdown overlay={this.profileMenu} trigger={["click"]}>
                <a
                  className="ml-2 align-middle text-black"
                  onClick={(e) => e.preventDefault()}
                >
                  {this.props.user && this.props.user.display_name}
                  {this.props.user &&
                    this.props.user.user &&
                    this.props.user.user.display_name}
                  <DownOutlined className="align-middle" />
                </a>
              </Dropdown>
            </div>
          </Header>
          <Content
            className="site-layout-background md:mx-4 md:mt-8 mx-1 mt-4 md:px-5 px-2"
            style={{
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            © 2020 - <a href="https://sprucedutech.com">Sprucedutech</a>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withRouter(Dashboard));
