export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",

  AUTH_TOKEN: "AUTH_TOKEN",


  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCESS: "UPDATE_PROFILE_SUCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  GET_CURRENT_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
  GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
  GET_CURRENT_USER_FAILURE: "GET_CURRENT_USER_SUCCESS"
};
