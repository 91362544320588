import React, { Component } from "react";
import { Button, Col, DatePicker, Form, Input, message, Row } from "antd";

import AsyncSelect from "react-select/async/dist/react-select.esm";
import { courseService, enquiryService, tutorService } from "../../services";

class RequestTutor extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      subject: "",
      saving: false,
    };
  }

  getOptionLabel = (option) => option.name;

  getOptionValue = (option) => option.id;

  getCourses = (query) => {
    return courseService.getCourses(query).then(
      (courses) => courses["results"],
      (error) => {
        console.err(error);
      }
    );
  };

  objToString = (obj) => {
    let str = "";
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && obj[p]) {
        str += p + "   -   " + obj[p] + "\n";
      }
    }
    return str;
  };

  onFinish = (values) => {
    const data = {
      name: values.name,
      email: values.email,
      class: values.class,
      subject: values.subject ? values.subject.name : null,
      tutor: values.tutor ? values.tutor.username : null,
      tutor_id: values.tutor ? values.tutor.user.id : null,
      date: values.date.format("YYYY-MM-DD HH:mm:ss"),
    };
    const enquiry = {
      object_id: values.tutor.id,
      description: this.objToString(data),
      content_type: "employees.Employee",
    };
    this.setState({ saving: true });
    enquiryService
      .sendEnquiry(enquiry)
      .then(() => {
        this.formRef.current.resetFields();

        this.setState({ saving: false });
        message.success("Successfully requested tutor");
      })
      .catch(() => {
        this.formRef.current.resetFields();

        this.setState({ saving: false });
        message.error("Cannot create exam");
      });
  };

  onFinishFailed = (errorInfo) => {
    message.error("Couldn't submit form. Please try again");
    console.log("Failed:", errorInfo);
  };

  getTutors = (query) => {
    return tutorService.getTutors(query).then(
      (courses) => courses["results"],
      (error) => {
        console.err(error);
      }
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelectChange = (selectedOption, meta) => {
    this.setState({ [meta.name]: selectedOption.name });
  };

  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <h4 className="font-bold text-xl mb-2">Request for a class</h4>
        <div className="bg-white p-6 shadow-md">
          <Form
            name="advanced_search"
            layout="vertical"
            ref={this.formRef}
            className="full-width"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            initialValues={{ class: user.class_detail }}
          >
            <Row gutter={24}>
              <Col xs={22} sm={18} md={12} lg={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={22} sm={18} md={12} lg={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { type: "email" },
                    {
                      required: true,
                      message: "Please input your email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={22} sm={18} md={12} lg={12}>
                <Form.Item label="Class" name="class">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={22} sm={18} md={12} lg={12}>
                <Form.Item label="Subject" name="subject">
                  <AsyncSelect
                    cacheOptions
                    closeMenuOnSelect={true}
                    loadOptions={this.getCourses}
                    defaultOptions
                    placeholder="Select/Enter Subject"
                    onChange={this.handleSelectChange}
                    getOptionValue={this.getOptionValue}
                    getOptionLabel={this.getOptionLabel}
                    name="subject"
                    className=""
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={22} sm={18} md={12} lg={12}>
                <Form.Item
                  label="Tutor"
                  name="tutor"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <AsyncSelect
                    cacheOptions
                    closeMenuOnSelect={true}
                    loadOptions={this.getTutors}
                    defaultOptions
                    placeholder="Select Tutor"
                    onChange={this.handleSelectChange}
                    getOptionValue={(obj) => obj.id}
                    getOptionLabel={(obj) => obj.user.display_name}
                    name="subject"
                    className=""
                  />
                </Form.Item>
              </Col>
              <Col xs={22} sm={18} md={12} lg={12}>
                <Form.Item label="Date & Time" name="date" className="flex">
                  <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item className="text-left">
                <Button
                  type="primary"
                  loading={this.state.saving}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default RequestTutor;
