export const liveClassConstants = {
  SUCCESS: "LIVE_CLASS_SUCESS",
  ERROR: "LIVE_CLASS_ERROR",
  LOADING: "LIVE_CLASS_LOADING",
  LIVE_CLASS_DETAILS_SUCCESS: "LIVE_CLASS_DETAILS_SUCESS",
  LIVE_CLASS_DETAILS_ERROR: "LIVE_CLASS_DETAILS_ERROR",
  LIVE_CLASS_DETAILS_LOADING: "LIVE_CLASS_DETAILS_LOADING",
  MY_LIVE_CLASSES_SUCCESS: "MY_LIVE_CLASS_SUCESS",
  MY_LIVE_CLASSES_ERROR: "MY_LIVE_CLASS_ERROR",
  MY_LIVE_CLASSES_LOADING: "MY_LIVE_CLASS_LOADING",
};
