import { Button, Modal, Spin, Table } from "antd";
import React, { Component } from "react";

import TextArea from "antd/lib/input/TextArea";
import { classActivityActions } from "../../../actions";
import { classDetailActions } from "../../../actions";
import { connect } from "react-redux";

const { Column } = Table;

class ClassDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAcitivity: false,
      info: "",
      clickedRecord: null,
    };
  }
  componentDidMount() {
    this.props.dispatch(classDetailActions.getClassDetails());
  }

  createOrUpdateActivity() {
    let classDetailId = this.state.clickedRecord.id;
    if ("info" in this.props.activities[classDetailId]) {
      this.props.dispatch(
        classActivityActions.updateClassActivity(classDetailId, {
          info: this.state.info,
        })
      );
    } else {
      this.props.dispatch(
        classActivityActions.CreateClassActivity(classDetailId, {
          info: this.state.info,
        })
      );
    }
    this.setState({ showAcitivity: false });
  }

  getContent() {
    let record = this.state.clickedRecord;
    if (!record) return;
    if (record.id.toString() in this.props.activities) {
      return (
        <TextArea
          name="info"
          defaultValue={this.props.activities[record.id].info}
          onChange={(e) => {
            this.setState({ info: e.target.value });
          }}
        />
      );
    } else {
      this.props.dispatch(classActivityActions.getClassActivity(record.id));
      return (
        <div className="text-center">
          <Spin size="large" />
        </div>
      );
    }
  }

  render() {
    const { classDetails, user } = this.props;
    return (
      <React.Fragment>
        <Modal
          visible={this.state.showAcitivity}
          title="Update Today's Activity"
          okText="Save"
          onOk={() => this.createOrUpdateActivity()}
          onCancel={() => this.setState({ showAcitivity: false })}
          cancelText="Cancel"
        >
          {this.getContent()}
        </Modal>
        <h3 className="text-left text-2xl my-3 font-bold">My Classes</h3>
        <div className="flex overflow-hidden sm:rounded-lg h-full py-4">
          <div className="justify-center w-full">
            <Table dataSource={classDetails.results} rowKey="id">
              <Column
                title="Student Name"
                dataIndex={["student", "display_name"]}
                key="displayName"
              />
              <Column title="Subject" dataIndex="subject" key="subject" />
              <Column title="Timings" dataIndex="timings" key="timings" />
              <Column
                title="Start Date"
                dataIndex="start_date"
                key="startDate"
              />
              <Column title="End Date" dataIndex="end_date" key="end_date" />
              {user.user_type != 0 && (
                <Column
                  title="Action"
                  key="activity"
                  render={(text, record) => {
                    return (
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showAcitivity: true,
                            clickedRecord: record,
                          });
                        }}
                      >
                        Todays Update
                      </Button>
                    );
                  }}
                />
              )}
            </Table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { classDetails, error, loading } = state.classDetails;
  const activities = state.activities;
  const { success } = state.activities;
  return {
    activities,
    classDetails,
    error,
    loading,
    success,
  };
}

const connectedClassDetailsComponent = connect(mapStateToProps)(
  ClassDetailsComponent
);
export default connectedClassDetailsComponent;
