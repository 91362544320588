import { Alert, Button, Input } from "antd";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { connect } from "react-redux";
import { userActions } from "../../actions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;

    if (email && password) {
      dispatch(userActions.login(email, password));
    }
  }

  render() {
    const { loggingIn, type, message } = this.props;
    const { email, password } = this.state;
    return this.props.loading ? (
      <Loading />
    ) : (
      <form
        className="pb-8 mb-4 flex flex-col"
        onSubmit={this.handleSubmit}
        style={{ background: `rgba(255, 255, 255, 0.90)` }}
      >
        {type === "alert-danger" ? (
          <Alert
            className="mb-2"
            message={message.detail ?? "Incorrect username or password"}
            type="error"
            showIcon
          />
        ) : null}

        <div className="mb-8">
          <Input
            type="text"
            name="email"
            size="large"
            value={email}
            placeholder="Email Address / Username"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="mb-8">
          <Input.Password
            name="password"
            size="large"
            value={password}
            placeholder="Password"
            onChange={this.handleChange}
            required
          />
        </div>
        <div>
          <Button
          className="mt-4 custom-bottom gradient-btn"
          htmlType="submit"
          loading={loggingIn}
          type="primary"
          shape="circle"
          block={false}
          style={{ width: "80px" }}
        >
          Login
        </Button>

        <Link className="text-primary ml-1" to="/forgot-password">
          Forgot Password ?
        </Link>
        </div>
        <div className="text-sm mt-3 text-left">
          Don't have an account?
          <Link className="text-primary ml-1" to="/signup">
            Sign Up
          </Link>
        </div>
        <div className="text-sm mt-3 text-left">
          Didn't get verification mail ?
          <Link className="text-primary ml-1" to="/resend-activation-mail">
            Resend Verification Mail
          </Link>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, loggedIn, loginError } = state.authentication;
  const { user, loading } = state.user;
  const { type, message } = state.alert;
  return {
    loggingIn,
    type,
    loggedIn,
    user,
    loading,
    message
  };
}

const connectedLoginComponent = connect(mapStateToProps)(Login);
export { connectedLoginComponent as Login };
