import { alertActions } from "./";
import { userConstants } from "../constants";
import { userService } from "../services";

export const userActions = {
  login,
  logout,
  signup,
  getAll,
  updateProfile,
  getCurrentUser
};

function login(email, password) {
  return dispatch => {
    dispatch(request());
    dispatch({ type: userConstants.GET_CURRENT_USER_REQUEST });

    userService.login(email, password).then(
      data => {
        localStorage.setItem(userConstants.AUTH_TOKEN, data.access);
        dispatch(success());
        // dispatch({
        //   type: userConstants.GET_CURRENT_USER_SUCCESS,
        //   user: data.user
        // });
      },
      error => {
        dispatch(failure(error));
        dispatch({ type: userConstants.GET_CURRENT_USER_FAILURE, error });
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success() {
    return { type: userConstants.LOGIN_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function signup(user) {
  const { password } = user;
  return dispatch => {
    dispatch(request());

    userService.signup(user).then(
      user => dispatch(success(user)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.SIGNUP_REQUEST };
  }
  function success(user) {
    return { type: userConstants.SIGNUP_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.SIGNUP_FAILURE, error };
  }
}

function getCurrentUser() {
  return dispatch => {
    dispatch(request());
    userService.getCurrentUser().then(
      user => dispatch(success(user)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GET_CURRENT_USER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GET_CURRENT_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_CURRENT_USER_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService.getAll().then(
      users => dispatch(success(users)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function updateProfile(profileDetails) {
  return dispatch => {
    dispatch(request());

    userService.updateProfile(profileDetails).then(
      users => dispatch(success(users)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.UPDATE_PROFILE_REQUEST };
  }
  function success(profileDetails) {
    return { type: userConstants.UPDATE_PROFILE_SUCESS, profileDetails };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PROFILE_FAILURE, error };
  }
}
