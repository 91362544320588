import { Button, Empty } from "antd";
import { PendingEnquiryCard } from "../../../components/EnquiryComponent";
import React from "react";
import { connect } from "react-redux";
import { enquiryActions } from "../../../actions";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function EnquiriesList(props) {
  const enquiriesList = props.enquiries.results.map((enquiry) => (
    <PendingEnquiryCard
      loading={props.loading}
      enquiry={enquiry}
      key={enquiry.id}
    />
  ));
  return enquiriesList;
}

function EnquiriesListLoading() {
  let array = new Array(6);
  const enquiriesPendingList = [...array.keys()].map((data, index) => (
    <PendingEnquiryCard loading={true} key={index} />
  ));
  return enquiriesPendingList;
}

class Pending extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(enquiryActions.getEnquiries());
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold">Requested Classes</h1>
          <Link to="/request_tutor">
            <Button
              icon={<PlusOutlined style={{ verticalAlign: "baseline" }} />}
              type="primary"
            >
              Request a class
            </Button>
          </Link>
        </div>
        <div>
          {!this.props.loading &&
          (!this.props.enquiries ||
            this.props.enquiries.results.length === 0) ? (
            <Empty description="You do have any pending enquiries" />
          ) : null}
        </div>
        <div className="flex flex-wrap">
          {this.props.loading ? (
            <EnquiriesListLoading />
          ) : (
            <EnquiriesList
              enquiries={this.props.enquiries}
              loading={this.props.loading}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { enquiries, get_enquiries_request } = state.enquiry;
  return {
    enquiries,
    loading: get_enquiries_request,
  };
}

export const PendingPage = connect(mapStateToProps)(Pending);
