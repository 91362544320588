import "./LiveConference.css";

import { Button, Form, Input, Layout } from "antd";
import React, { Component } from "react";

import queryString from "query-string";
import sha1 from "js-sha1";

const { Content } = Layout;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export class JoinMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      key: "",
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({
      id: values.id,
      key: values.key,
      password: values.password,
    });
  }

  onFinish = (values) => {
    let queryParams = `fullName=${values.name}&meetingID=${this.state.id}&password=${this.state.password}&redirect=true`;
    let checksum = sha1(`join${queryParams}${this.state.key}`);
    const url = `https://live.sprucedutech.com/bigbluebutton/api/join?${queryParams}&checksum=${checksum}`;
    window.open(url);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  render() {
    return (
      <Layout className="h-full bg-gray-100">
        <Content className="bg-white m-10 p-5">
          <h2 className="text-center">Join Live Class</h2>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label="Your Name"
              name="name"
              rules={[
                { required: true, message: "Please input the name of meeting" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    );
  }
}

export default JoinMeeting;
