export const examConstants = {
  EXAM_DETAILS_REQUEST: "EXAM_DETAILS_REQUEST",
  EXAM_DETAILS_SUCCESS: "EXAM_DETAILS_SUCCESS",
  EXAM_DETAILS_ERROR: "EXAM_DETAILS_ERROR",
  EXAM_QUESTIONS_REQUEST: "EXAM_QUESTIONS_REQUEST",
  EXAM_QUESTIONS_SUCCESS: "EXAM_QUESTIONS_SUCCESS",
  EXAM_QUESTIONS_ERROR: "EXAM_QUESTIONS_ERROR",
  GET_EXAMS_REQUEST: "GET_EXAMS_REQUEST",
  GET_EXAMS_SUCCESS: "GET_EXAMS_SUCCESS",
  GET_EXAMS_ERROR: "GET_EXAMS_ERROR",
  UPDATE_EXAMS_REQUEST: "UPDATE_EXAMS_REQUEST",
  UPDATE_EXAMS_SUCCESS: "UPDATE_EXAMS_SUCCESS",
  UPDATE_EXAMS_ERROR: "UPDATE_EXAMS_ERROR",
  DELETE_EXAMS_REQUEST: "DELETE_EXAMS_REQUEST",
  DELETE_EXAMS_SUCCESS: "DELETE_EXAMS_SUCCESS",
  DELETE_EXAMS_ERROR: "DELETE_EXAMS_ERROR",
  ATTEMPTS_LIST_REQUEST: "ATTEMPTS_LIST_REQUEST",
  ATTEMPTS_LIST_SUCCESS: "ATTEMPTS_LIST_SUCCESS",
  ATTEMPTS_LIST_ERROR: "ATTEMPTS_LIST_ERROR",
};
