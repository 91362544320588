import React, { Component } from "react";
import { Avatar, Button, Layout, List, message, Modal, Select } from "antd";
import { questionSetService, questionsService } from "../../services";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import QuestionComponent from "../../components/ExamComponent/question";
import { createMarkup } from "../../helpers";
import { Link } from "react-router-dom";

const { Content } = Layout;
const { confirm } = Modal;

class QuestionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      page: 0,
      total_page: 1,
      folder_slug: this.props.match.params.slug,
      fetching: false,
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  fetchQuestions = (clear_existing = false) => {
    this.setState({
      loading: true,
    });
    if (clear_existing) {
      this.setState({
        list: [],
        page: 0,
      });
    }
    const queryParams = { page: this.state.page + 1 };
    questionSetService
      .getQuestionSetQuestions(this.state.folder_slug, queryParams)
      .then((response) => {
        this.setState((prevState) => ({
          list: [...prevState.list, ...response["results"]],
          initLoading: false,
          loading: false,
          page: response["page"],
          total_pages: response["total_pages"],
        }));
      });
  };

  handleUsersChange = (values) => {
    let selected_user_ids = values.map((user) => parseInt(user.value));

    this.setState({
      selected_users: values,
      selected_user_ids: selected_user_ids,
      users: [],
      fetching: false,
    });
  };

  deleteQuestion = (question_id) => {
    questionsService
      .deleteQuestion(question_id)
      .then(() => {
        message.success("Successfully deleted question");
        this.fetchQuestions(true);
      })
      .catch(() => {
        message.error("Error in deleting question");
      });
  };

  showDeleteConfirmation = (question_id) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this question ?",
      onOk: () => {
        this.deleteQuestion(question_id);
      },
    });
  };

  onLoadMore = () => {
    this.fetchQuestions();
  };

  render() {
    const { initLoading, loading, list, total_pages, page } = this.state;
    const loadMore =
      page < total_pages && !loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={this.onLoadMore}>Load More</Button>
        </div>
      ) : null;

    return (
      <>
        <Layout className="bg-gray-100">
          <Content className="bg-white m-10 p-5">
            <div className="flex mt-2 px-5 justify-between border-b-1 pb-2">
              <h3 className="text-center text-lg font-bold">Questions</h3>
              <div>
                <Link to="/bulk-upload">
                  <Button type="primary">Bulk Upload Question</Button>
                </Link>
                <Link to={`/questions/add`} className="ml-3">
                  <Button type="primary">Add New Question</Button>
                </Link>
              </div>
            </div>
            <div className="px-5">
              <List
                itemLayout="horizontal"
                dataSource={list}
                loading={initLoading}
                loadMore={loadMore}
                rowKey="id"
                renderItem={(question, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar style={{ backgroundColor: "#16B7D3" }}>
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <QuestionComponent
                          classes=""
                          question={question.question_html}
                        />
                      }
                      description={
                        <>
                          {question.answers.map((answer, index) => (
                            <div className="flex">
                              <Avatar
                                size="small"
                                style={
                                  answer.is_correct
                                    ? { backgroundColor: "#87d068" }
                                    : { backgroundColor: "#999" }
                                }
                              >
                                {index + 1}
                              </Avatar>
                              <p
                                className="ml-3 mb-0"
                                dangerouslySetInnerHTML={createMarkup(
                                  answer.text_html
                                )}
                              ></p>
                            </div>
                          ))}
                          {question.explanation_html && (
                            <div className="mt-4">
                              <strong>Explanation Html : </strong> <br />
                              <p
                                className="mb-0"
                                dangerouslySetInnerHTML={createMarkup(
                                  question.explanation_html
                                )}
                              ></p>
                            </div>
                          )}
                          {question.marks && (
                            <div className="mt-2">
                              <strong>Marks : </strong> {question.marks}
                            </div>
                          )}
                          <div className="mt-3">
                            <Link to={`/questions/edit/${question.id}/`}>
                              Edit question
                            </Link>
                            <Link
                              className="ml-3 text-red-500"
                              type="danger"
                              onClick={() => {
                                this.showDeleteConfirmation(question.id);
                              }}
                            >
                              Delete question
                            </Link>
                          </div>
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </Content>
        </Layout>
      </>
    );
  }
}

export default QuestionsList;
