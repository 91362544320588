import React, { Component } from "react";
import { connect } from "react-redux";
import {userActions} from "../actions";
import { Login } from "../components/LoginComponent";
import "./LoginPage.css";
import {Row, Col, Button} from "antd";
import Footer from "../components/Footer";

class LoginPage extends Component {
  componentWillMount() {
    const { user, history } = this.props;
    if (localStorage.getItem("AUTH_TOKEN")) {
      if (Object.entries(user).length) {
        history.push("/");
      } else {
        this.props.dispatch(userActions.getCurrentUser());
      }
    }
  }

  componentDidUpdate() {
    console.log("Props: ", this.props)
    const { user, history } = this.props;
    if (localStorage.getItem("AUTH_TOKEN")) {
      if (user && Object.entries(user).length) {
        history.push("/");
      } else {
        this.props.dispatch(userActions.getCurrentUser());
      }
    }
  }
  
  render() {
    return (
      <>
        <div>
          <section id="sign-in" className="bglight position-relative padding">
            <div className="container">
              <div className="row justify-center">
                <div
                  className="col-md-12 mb-16 text-center wow fadeIn"
                  data-wow-delay="300ms"
                  style={{
                    visibility: "visible",
                    animationDelay: "300ms",
                    animationName: "fadeIn",
                  }}
                >
                  <h2 className="heading bottom30 darkcolor font-light2">
                    <span className="font-normal">Sign</span> In
                    <span className="divider-center" />
                  </h2>
                </div>
              </div>
              <Row>
                <Col md={12} sm={24}>
                  <div className="image login-image h-100">
                    <img
                        style={{height:"-webkit-fill-available"}}
                      src="https://sprucedutech.s3.ap-south-1.amazonaws.com/images/login_bg.svg"
                      alt="" className="w-100 h-100" />
                  </div>
                </Col>
                <Col md={12} sm={24} className="whitebox w-full">
                  <div className="widget w-full logincontainer">
                    <h3 className="darkcolor bottom30 text-center lg:text-left">
                      Sign In
                    </h3>
                    <Login />
                  </div>
                </Col>
              </Row>
            </div>
            <section className="mt-12">
              <div className="container">
                <div className="text-center">
                  <h3>Do you have any questions for us or have feedback ?</h3>
                  <br/>
                  <Button
                    className="mt-4 custom-bottom gradient-btn"
                    htmlType="submit"
                    href="https://sprucedutech.com/#stayconnect"
                    type="primary"
                    shape="circle"
                    block={false}
                    style={{width: '100px'}}
                  >
                    Contact Us
                  </Button>
                </div>
              </div>
          </section>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, loggedIn } = state.authentication;
  const { user, loading } = state.user;
  const { type } = state.alert;
  return {
    loggingIn,
    type,
    loggedIn,
    user,
    loading,
  };
}

const connectedLoginPageComponent = connect(mapStateToProps)(LoginPage);
export default connectedLoginPageComponent;
