import { activities } from "./activity.reducer";
import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
import { classDetails } from "./class_details.reducer";
import { combineReducers } from "redux";
import { enquiry } from "./enquiry.reducer";
import { job } from "./job.reducer";
import { page } from "./page.reducer";
import { review } from "./review.reducer";
import { tutors } from "./tutor.reducer";
import { user } from "./currentUser.reducer";
import { users } from "./users.reducer";
import { dashboard } from "./dashboard.reducer";
import { exam, examQuestions, exams, attempts } from "./exam.reducer";
import { liveClasses, myLiveClasses } from "./live_class.reducer";

const rootReducer = combineReducers({
  authentication,
  users,
  tutors,
  alert,
  user,
  enquiry,
  classDetails,
  page,
  job,
  review,
  activities,
  exam,
  examQuestions,
  liveClasses,
  myLiveClasses,
  exams,
  attempts,
  dashboard,
});

export default rootReducer;
