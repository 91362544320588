import { activityConstants } from "../constants";
import { classActivityService } from "../services";

export const classActivityActions = {
  getClassActivity,
  CreateClassActivity,
  updateClassActivity
};

function getClassActivity(classDetailId) {
  return dispatch => {
    dispatch(request());

    classActivityService.getClassActivity(classDetailId).then(
      activity => {
        dispatch(success(activity));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: activityConstants.LOADING };
  }

  function success(activity) {
    return { type: activityConstants.SUCCESS, activity };
  }

  function failure(error) {
    return { type: activityConstants.ERROR, error };
  }
}

function CreateClassActivity(classDetailId, activity) {
  return dispatch => {
    dispatch(request());

    classActivityService.createActivity(classDetailId, activity).then(
      activity => {
        dispatch(success(activity));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: activityConstants.LOADING };
  }

  function success(activity) {
    return { type: activityConstants.SUCCESS, activity };
  }

  function failure(error) {
    return { type: activityConstants.ERROR, error };
  }
}

function updateClassActivity(classDetailId, activity) {
  return dispatch => {
    dispatch(request());

    classActivityService.updateActivity(classDetailId, activity).then(
      activity => {
        dispatch(success(activity));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: activityConstants.LOADING };
  }

  function success(activity) {
    return { type: activityConstants.SUCCESS, activity };
  }

  function failure(error) {
    return { type: activityConstants.ERROR, error };
  }
}
