import React, {Component} from 'react';
import {Button, Card, Col, Result, Row} from 'antd';
import {connect} from "react-redux";
import {examActions} from "../../actions";
import dayjs from 'dayjs';

const {Meta} = Card;

class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startingExam: false,
      examQuestions: [],
      attempt: null
    }
  }

  componentDidMount() {
    const {slug} = this.props.match.params;
    this.props.dispatch(examActions.getExamDetails(slug));
  }

  startExam = () => {
    const {examDetails} = this.props
    const examSlug = examDetails.slug;
    this.props.history.push(`/exam/${examSlug}/start`);
  }

  render() {
    const {loading, examDetails, error} = this.props

    return (
      <>
        {error ? <Result
            status="404"
            title="404"
            subTitle="Sorry, exam does not exist."
            extra={<Button type="primary">Back Home</Button>}
          /> :
          <Row type="flex" justify="center" align="middle" style={{height: '80%'}}>
            <Col xs={22} sm={18} md={16} lg={12} xl={10}>
              <Card
                title={examDetails ? examDetails.title : "Exam Title"}
                headStyle={{textAlign: 'center'}}
                actions={[
                  <Button type="primary" loading={this.state.startingExam} onClick={this.startExam}>
                    {examDetails && examDetails.paused_attempts ? "Resume Exam" : "Start Exam"}
                  </Button>
                ]}
                loading={loading}>

                <table className="w-full mb-8">
                  <tr>
                    <td className="font-bold">No of Questions</td>
                    <td>:</td>
                    <td> {examDetails && examDetails.number_of_questions} </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Duration</td>
                    <td>:</td>
                    <td>{examDetails && examDetails.duration}</td>
                  </tr>
                  {
                    examDetails && examDetails.end_date && <tr>
                      <td className="font-bold">End Date</td>
                      <td>:</td>
                      <td>{dayjs(examDetails.end_date).toString()}</td>
                    </tr>
                  }

                </table>

                {examDetails && examDetails.description && <Meta className="text-center" title="More Info"
                                      description={examDetails.description}/>}

              </Card>
            </Col>
          </Row>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {examDetails, error, loading, success} = state.exam;
  const {examQuestions} = state.examQuestions;

  return {
    examDetails,
    error,
    loading,
    success,
    examQuestions
  };
}

const connectedExamComponent = connect(mapStateToProps)(
  Exam
);

export default connectedExamComponent;