import React, { Component } from "react";

import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo_white.svg";
import { withRouter } from "react-router-dom";

class RightMenu extends Component {
  logout = () => {
    localStorage.clear("AUTH_TOKEN");
    this.props.history.push("/login");
  };

  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isMobileMenuOpen: false,
    };
  }

  render() {
    return (
      <nav className="bg-primary">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a href="https://sprucedutech.com">
                  <img
                    className="w-48 h-auto"
                    src={Logo}
                    alt="Sprucedutech logo"
                  />
                </a>
              </div>
            </div>
            <div className="hidden md:block ml:auto">
              <div className="ml-4 flex items-center md:ml-6">
                <Link
                  className="px-3 py-2 rounded-md text-sm font-medium hover:bg-white text-white focus:outline-none "
                  to="/login"
                >
                  Sign In
                </Link>
                <Link
                  className="px-3 py-2 rounded-md text-sm font-medium hover:bg-white text-white focus:outline-none "
                  to="/signup"
                >
                  Sign Up
                </Link>
              </div>
            </div>
            <div className="-mr-2 block md:hidden">
              <button
                onClick={() => {
                  this.setState({
                    isMobileMenuOpen: !this.state.isMobileMenuOpen,
                  });
                }}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
              >
                <svg
                  className={`${
                    this.state.isMobileMenuOpen ? "hidden" : "block"
                  } h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`${
                    this.state.isMobileMenuOpen ? "block" : "hidden"
                  } h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${
            this.state.isMobileMenuOpen ? "block" : "hidden"
          } md:hidden`}
        >
          <div className="pt-4 pb-3">
            <div className="mt-3 px-2">
              <Link
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                to="/login"
              >
                Sign In
              </Link>
              <Link
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                to="/signup"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(RightMenu);
