import { Redirect, Route, withRouter } from "react-router-dom";

import { Loading } from "../components/Loading";
import React from "react";
import { UpdateTutorDetails } from "../pages/tutors/UpdateDetailsPage";
import { connect } from "react-redux";
import { userActions } from "../actions";
import NotApproved from "../pages/common/NotApproved";

const Private = ({ component: Component, ...rest }) => {
  let getRendererComponent = (props) => {
    if (localStorage.getItem("AUTH_TOKEN") === null) {
      return <Redirect to="/login" />;
    }

    if (rest.user && Object.entries(rest.user).length === 0) {
      rest.dispatch(userActions.getCurrentUser());
      return <Loading />;
    }

    if (rest.loading) {
      return <Loading />;
    }

    let tutorPublicPaths = [
      "/exam/:slug",
      "/exam/:slug/start",
      "/exam/end",
      "/exam/completion",
    ];

    if (rest.user && Object.entries(rest.user).length !== 0) {
      if (rest.user.user) {
        if (
          rest.user.user.user_type === 2 &&
          rest.user.user.is_profile_complete === false
        ) {
          return <UpdateTutorDetails {...props} />;
        } else if (
          !rest.user.user.is_approved &&
          tutorPublicPaths.indexOf(rest.path) === -1
        ) {
          return <NotApproved />;
        }
      }
      return <Component user={rest.user} {...props} />;
    }
  };

  return <Route {...rest} render={(props) => getRendererComponent(props)} />;
};

function mapStateToProps(state) {
  const { user, loading } = state.user;
  return {
    user,
    loading,
  };
}

export const PrivateRoute = connect(mapStateToProps)(withRouter(Private));

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);
