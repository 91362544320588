import { tutorConstants } from "../constants/tutor.constants";
import { tutorService } from "../services";

export const tutorActions = {
  search
};

function search(queryParams) {
  return dispatch => {
    dispatch(request());

    tutorService.getTutors(queryParams).then(
      tutors => {
        dispatch(success(tutors));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: tutorConstants.SEARCH_REQUEST };
  }
  function success(tutors) {
    return { type: tutorConstants.SEARCH_SUCCESS, tutors };
  }
  function failure(error) {
    return { type: tutorConstants.SEARCH_FAILURE, error };
  }
}
