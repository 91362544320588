import { addQueryParam, authHeader, config } from "../helpers";

export const userService = {
  login,
  logout,
  signup,
  getAll,
  updateProfile,
  getCurrentUser,
  forgotPassword,
  validatePasswordToken,
  changePassword,
  resendActivationMail,
  validateActivationToken,
  updateUserProfile,
  getStudents,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/auth/login/`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function logout() {
  localStorage.removeItem("AUTH_TOKEN");
}

function signup(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(email),
  };

  return fetch(`${config.apiUrl}/auth/password_reset/`, requestOptions)
    .then(handleResponse)
    .then((token) => {
      return token;
    });
}

function resendActivationMail(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/auth/resend-activation/`, requestOptions)
    .then(handleResponse)
    .then((token) => {
      return token;
    });
}

function validateActivationToken(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/auth/verify-email/`, requestOptions)
    .then(handleResponse)
    .then((token) => {
      return token;
    });
}

function validatePasswordToken(token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(token),
  };

  return fetch(
    `${config.apiUrl}/auth/password_reset/validate_token/`,
    requestOptions
  )
    .then(handleResponse)
    .then((token) => {
      return token;
    });
}

function changePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/auth/password_reset/confirm/`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateProfile(profileDetails) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(profileDetails),
  };

  return fetch(`${config.apiUrl}/tutors/${profileDetails.id}/`, requestOptions)
    .then(handleResponse)
    .then((profileDetails) => {
      return profileDetails;
    });
}

function getCurrentUser() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/users/me/`, requestOptions).then(
    handleResponse
  );
}

function getAll(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.apiUrl}/users/`;
  url = addQueryParam(url, queryParams);

  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function updateUserProfile(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/users/update/`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function getStudents(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };
  let url = `${config.apiUrl}/users/students/`;
  url = addQueryParam(url, queryParams);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((students) => {
      return students;
    });
}
