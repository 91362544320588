import { Button, Col, Input, Row, Select } from "antd";
import React, { Component } from "react";

import AsyncSelect from "react-select/async";
import SearchItem from "../../../components/SearchItem/SearchItem";
import { connect } from "react-redux";
import { courseService } from "../../../services";
import { tutorActions } from "../../../actions";

const { Option } = Select;
class TutorsListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      locality: "",
      education_level: "",
    };
  }

  getOptionLabel = (option) => option.name;

  getOptionValue = (option) => option.id;

  getCourses = (query) => {
    return courseService.getCourses(query).then(
      (courses) => courses["results"],
      (error) => {
        console.err(error);
      }
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelectChange = (selectedOption, meta) => {
    this.setState({ [meta.name]: selectedOption.name });
  };

  handleSearch = () => {
    const queryParams = {
      course_preference__name: this.state.subject,
      preferred_level: this.state.education_level,
      user__city: this.state.locality,
    };
    const { dispatch } = this.props;
    dispatch(tutorActions.search(queryParams));
  };

  getTutors = (query) => {
    const { dispatch } = this.props;
    let queryParams = { search: query };
    dispatch(tutorActions.search(queryParams));
  };

  componentDidMount() {
    this.props.dispatch(tutorActions.search());
  }

  render() {
    return (
      <React.Fragment>
        <div className=" mt-2 px-5 text-left border-b-2 pb-2">
          <h3 className="text-base leading-6 font-semibold tracking-wide uppercase">
            Search For Tutors
          </h3>
        </div>
        <div className="mt-2">
          <Input.Group size="large">
            <Row gutter={8} className="justify-center items-center">
              <Col xs={24} sm={12} md={6} lg={4} className="mt-2">
                <Select
                  size={40}
                  style={{ width: 200 }}
                  placeholder="Select preffered level"
                  onChange={(value) => {
                    this.setState({ education_level: value });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">Play School</Option>
                  <Option value="2">Primary Level</Option>
                  <Option value="3">Higher Secondary</Option>
                </Select>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} className="mt-2">
                <AsyncSelect
                  cacheOptions
                  closeMenuOnSelect={true}
                  loadOptions={this.getCourses}
                  defaultOptions
                  placeholder="Select/Enter Subject"
                  onChange={this.handleSelectChange}
                  getOptionValue={this.getOptionValue}
                  getOptionLabel={this.getOptionLabel}
                  name="subject"
                  className=""
                />
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} className="mt-2">
                <Input
                  name="locality"
                  onChange={this.onChange}
                  placeholder="Enter Locality or Leave blank to search in all places"
                />
              </Col>

              <Col xs={24} sm={12} md={6} lg={4} className="mt-2">
                <Button type="primary" onClick={this.handleSearch}>
                  Search
                </Button>
              </Col>
            </Row>
          </Input.Group>
        </div>
        <div className="flex flex-wrap items-center justify-center">
          {this.props.items.results.map((tutor, index) => (
            <SearchItem key={index} tutor={tutor} />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { items } = state.tutors;
  return {
    items,
  };
}

const connectedTutorsListComponent = connect(mapStateToProps)(
  TutorsListComponent
);
export default connectedTutorsListComponent;
