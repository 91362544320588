import { enquiryConstants } from "../constants";

let initialState = {
  error: null,
  enquiry: {},
  loading: false,
  success: false,
  get_enquiries_request: false,
  enquiries: {
    results: []
  },
  get_enquiries_error: null
};

export function enquiry(state = initialState, action) {
  switch (action.type) {
    case enquiryConstants.REQUEST:
      return {
        ...state,
        loading: true
      };
    case enquiryConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        enquiry: action.message,
        success: true
      };
    case enquiryConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: action.message
      };
    case enquiryConstants.GET_ENQUIRIES_REQUEST:
      return {
        ...state,
        get_enquiries_request: true
      };
    case enquiryConstants.GET_ENQUIRIES_SUCCESS:
      return {
        ...state,
        get_enquiries_request: false,
        enquiries: action.enquiries
      };
    case enquiryConstants.GET_ENQUIRIES_FAILURE:
      return {
        ...state,
        get_enquiries_request: false,
        get_enquiries_error: action.error
      };
    default:
      return state;
  }
}
