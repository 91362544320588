import { authHeader, config } from "../helpers";

export const classActivityService = {
  getClassActivity: getAcitivity,
  createActivity,
  updateActivity
};

function getAcitivity(class_detail_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    }
  };

  return fetch(
    `${config.apiUrl}/class_details/${class_detail_id}/activity/`,
    requestOptions
  )
    .then(handleResponse)
    .then(activity => {
      return { [class_detail_id]: activity };
    });
}

function createActivity(class_detail_id, activity) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(activity)
  };

  return fetch(
    `${config.apiUrl}/class_details/${class_detail_id}/activity/`,
    requestOptions
  )
    .then(handleResponse)
    .then(activity => {
      return { [class_detail_id]: activity };
    });
}

function updateActivity(class_detail_id, activity) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(activity)
  };

  return fetch(
    `${config.apiUrl}/class_details/${class_detail_id}/activity/`,
    requestOptions
  )
    .then(handleResponse)
    .then(activity => {
      return { [class_detail_id]: activity };
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      } else if (response.status === 404) {
        return Promise.resolve({});
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function logout() {
  localStorage.removeItem("user");
}
