import { dashboardConstants } from "../constants";

let initialState = {
  error: null,
  dashboardData: {},
  loading: false,
};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.dashboardData,
      };
    case dashboardConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
