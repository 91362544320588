import { Avatar, Card, Modal, Skeleton, Tag } from "antd";

import React, { useState } from "react";

const { Meta } = Card;

function Days(props) {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return days.map((day, index) => {
    return (
      <span
        className={
          props.days.indexOf(day) >= 0
            ? "py-1 px-2 mr-2 bg-green-500 font-bold text-white inline-block"
            : "py-1 px-2 mr-2"
        }
        key={index}
      >
        {day.substr(0, 3)}
      </span>
    );
  });
}

export function SucessEnquiryCard(props) {
  const { loading, classDetail } = props;
  const [visible, setVisible] = useState(false);

  return (
    <Card className="m-4 shadow-md margin-side-less md:max-w-md max-w-full">
      <Skeleton loading={loading} avatar active>
        {loading ? (
          <Meta avatar={<Avatar size={100} />} title="" description="" />
        ) : (
          <div className=" bg-white overflow-hidden">
            <div className="header">
              <p className="text-md mb-0">{classDetail.title}</p>
              <Tag color="#55acee" className="rounded-lg">
                {classDetail.subject}
              </Tag>
            </div>
            <div className="body mt-4">
              <p className="mb-0">
                <span className="font-bold">Time: </span>
                {classDetail.timings}
              </p>
              <p>
                <span className="font-bold">Course duration:</span>{" "}
                {classDetail.duration_text}
              </p>
              <div className="mt-4 flex overflow-scroll sm:overflow-hidden">
                <Days days={classDetail.days} />
              </div>
              <p className="my-4" onClick={() => setVisible(true)}>
                <strong>Tutor: </strong>
                <Avatar src={classDetail.tutor.profile_photo_url} />
              </p>
            </div>

            <Modal
              title="About Tutor"
              centered
              cancelButtonProps={{ style: { display: "none" } }}
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              okText="Close"
            >
              <p>
                <strong>Name:</strong> {classDetail.tutor.user.display_name}
              </p>
              <p>
                <strong>Bio:</strong> {classDetail.tutor.biography}
              </p>
            </Modal>
          </div>
        )}
      </Skeleton>
    </Card>
  );
}
