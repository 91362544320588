import { Button, Card, Form, Layout, message, Select, Spin } from "antd";

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import { examService, questionSetService, utilService } from "../../services";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import queryString from "query-string";

const { Content } = Layout;
const { Option } = Select;

export default class BulkUpload extends React.Component {
  constructor(props) {
    const queryParams = queryString.parse(props.location.search);

    super(props);
    this.state = {
      excel: null,
      selected_exams: [],
      exams: [],
      saving: false,
      selectedQuestionSet: null,
      questionSet: {},
      show_exam_field: this.props.location.query?.show_exam || queryParams.exam,
    };
    this.formRef = React.createRef();
    this.s3UploadRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.user.user_type !== 0) {
      return <Redirect to="/" />;
    }
  }

  fetchExams = (value) => {
    this.setState({ fetching: true });
    examService.getExams({ search: value }).then((exams) => {
      let results = exams["results"];
      this.setState({ exams: results, fetching: false });
    });
  };

  getSignedUrl = (file, callback) => {
    const uploadDetails = {
      filename: file.name,
      public: true,
    };
    utilService.uploadFile(uploadDetails).then((data) => {
      const result = { signedUrl: data };
      callback(result);
    });
  };

  handleExamChange = (values) => {
    let selected_exam_ids = values.map((exam) => parseInt(exam.value));

    this.setState({
      selected_exams: values,
      selected_exam_ids: selected_exam_ids,
      exams: [],
      fetching: false,
    });
  };

  fetchQuestionSet = (value) => {
    return questionSetService
      .getQuestionFolders({ search: value })
      .then((response) => {
        return response["results"];
      })
      .catch(() => {
        console.error("Failed to fetch questions");
      });
  };

  onUploadFinish = (result, file) => {
    var parsedUrl = result["signedUrl"].split("?")[0];
    var pathname = new URL(parsedUrl).pathname;
    var cleanedPathName = pathname.substr(pathname.indexOf("/") + 1);
    this.setState({
      excel: cleanedPathName,
    });
  };

  onFinish = (values) => {
    values["exams"] = this.state.selected_exam_ids;
    values["excel_url"] = this.state.excel;
    values["question_set"] = this.state.selectedQuestionSet;

    this.setState({ saving: true });
    utilService
      .bulkUploadQuestions(values)
      .then((response) => {
        this.setState({ saving: false });
        this.formRef.current.resetFields();
        message.success("Bulk Upload completed sucessfully");
        this.s3UploadRef.current.clear();
      })
      .catch(() => {
        this.setState({ saving: false });
        message.error("Error occurred. Please try again");
        this.s3UploadRef.current.clear();
      });
  };

  onFinishFailed = (errorInfo) => {
    message.error("Error in parsing excel");
    console.log("Failed:", errorInfo);
  };

  render() {
    const { fetching, exams, selected_exam } = this.state;

    return (
      <React.Fragment>
        <Layout>
          <Content>
            <Card
              title="Bulk Upload Questions"
              extra={
                <a href="https://sprucedutech.s3.ap-south-1.amazonaws.com/bulkupload/bulk_upload_from_excel_example.xlsx">
                  Download Sample
                </a>
              }
              style={{ width: 500 }}
            >
              <Form
                className=" flex flex-col w-full"
                name="basic"
                ref={this.formRef}
                onFinish={this.onFinish}
                layout="vertical"
                onFinishFailed={this.onFinishFailed}
              >
                <div className="flex  flex-wrap mb-3">
                  <ReactS3Uploader
                    name="resume"
                    ref={this.s3UploadRef}
                    getSignedUrl={this.getSignedUrl}
                    onFinish={this.onUploadFinish}
                    required={true}
                  />
                </div>
                <Form.Item className="mt-3" label="Folder">
                  <AsyncSelect
                    cacheOptions
                    loadOptions={this.fetchQuestionSet}
                    defaultOptions
                    value={this.state.questionSet}
                    name="selectedQuestionSet"
                    onChange={(selectedOption) => {
                      this.setState({
                        selectedQuestionSet: selectedOption.id,
                        questionSet: {
                          id: selectedOption.id,
                          name: selectedOption.name,
                        },
                      });
                    }}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                  />
                </Form.Item>
                {this.state.show_exam_field && (
                  <Form.Item className="mt-3" label="Exam">
                    <Select
                      mode="multiple"
                      name="exams"
                      labelInValue
                      value={selected_exam}
                      placeholder="Type to search exam"
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      filterOption={false}
                      onSearch={this.fetchExams}
                      onChange={this.handleExamChange}
                      style={{ width: "100%" }}
                      size="medium"
                      showArrow={true}
                    >
                      {exams.map((exam) => (
                        <Option key={exam.id}>{exam.title}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item className="text-left">
                  <Button
                    type="primary"
                    loading={this.state.saving}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
                <div className="mt-5">
                  <p>
                    Once file is uploaded, questions will get uploaded in 2
                    minutes. You will get a mail once it is done.
                  </p>
                </div>
              </Form>
            </Card>
          </Content>
        </Layout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, loading } = state.user;
  return {
    user,
    loading,
  };
}

export const BulkUploadPage = connect(mapStateToProps)(BulkUpload);
