import React  from 'react'
import {userService} from "../services";
import { Spin, Result, Button } from 'antd';
import Loading from "./Loading";
import ErrorPage from "./ErrorPage";


class VerifyActivationToken extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			verifying: true,
			verified: false
		}
	}

	componentDidMount() {
		const {code, id} = this.props.match.params;
		userService.validateActivationToken({"code": code, "user_id": id})
				.then(() => {
					console.log("Success")
					this.setState({
						verifying: false,
						verified: true
					});
				})
				.catch(() => {
					this.setState({
						verifying: false,
						verified: false
					});
				})
	}

	renderPage() {
		if (this.state.verifying) {
			return <Loading />;
		}

		if (this.state.verified) {
			return <Result
					status="success"
					title="Your email is successfully verified. Please login to continue."
					extra={
						<Button type="primary" key="console" href="/login">
							Login
						</Button>
					}
			/>
		}

		return <Result
				status="error"
				title="This link is either expired or incorrect. Please try again."
				extra={
					<Button type="primary" key="console" href="/resend-email">
						Resend Verification Email
					</Button>
				}
		/>;
	}

	render() {
		return (
			<>
				{this.renderPage()}
			</>
		)

	}
}

export default VerifyActivationToken;