import {Button, Col, message, Radio, Row} from 'antd';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {examService} from "../../services";
import Loading from "../../components/Loading";
import QuestionComponent from "../../components/ExamComponent/question";
import PageNotFound from "../../pages/common/PageNotFound"


function createMarkup(html) {
  return {__html: html};
}


class QuestionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examQuestions: [],
      attempt: [],
      loading: true,
      currentQuestion: 0,
      buttonLabel: "Next Question",
      savingAnswer: false,
      selectedOption: [],
      error: null
    }
  }

  componentDidMount() {
    this.fetchQuestions()
  }

  onOptionSelection = (e) => {
    let options = [e.target.value];
    this.setState({selectedOption: options})
  };

  fetchQuestions = (url=null) => {
    const {slug} = this.props.match.params;
    examService.getQuestions(slug, url)
      .then(response => {
        let examQuestions = this.parseQuestions(response.results);
        this.setState({examQuestions: [...this.state.examQuestions, ...examQuestions]});
        if (response.next) {
          this.fetchQuestions(response.next)
        } else {
          this.startExam(slug)
        }
      })
        .catch((error) => {
          this.setState({error: error, loading: false})
        })
  };

  parseQuestions = (results) => {
    let examQuestions = results['exam_questions']
    let questions = results['questions'] || []
    examQuestions.forEach((examQuestion) => {
      let questionIndex = questions.findIndex(question => question.id === examQuestion.question_id);
      examQuestion.question = questions[questionIndex]
    });
    return examQuestions
  };

  startExam = (slug) => {
    examService.startExam(slug)
      .then(attempt => {
        this.setState({attempt: attempt})
        this.fetchAnswers()
      })
  }

  fetchAnswers = (url=null) => {
    examService.getAttemptedAnswers(this.state.attempt.id, url)
        .then(response => {
          if (response.next) {
            this.fetchAnswers(response.next)
          } else {
            let examQuestionId = response.results[response.count - 1]?.exam_question_id
            let currentQuestion = this.state.examQuestions.findIndex(x => x.id == examQuestionId) + 1
            this.setState({currentQuestion:currentQuestion, loading: false})
          }
        })
        .catch((error) => {
          this.setState({error: error, loading: false})
        })
  }

  showNextQuestion = () => {
    const attemptId = this.state.attempt.id
    const examQuestionId = this.state.examQuestions[this.state.currentQuestion].id

    if (this.state.currentQuestion + 2 === this.state.examQuestions.length) {
      this.setState({buttonLabel: "Submit"})
    }

    if (this.state.currentQuestion + 1 <= this.state.examQuestions.length) {
      this.setState({savingAnswer: true})
      examService.submitAnswer(attemptId, examQuestionId, this.state.selectedOption)
        .then(userSelectedAnswer => {
          this.setState({savingAnswer: false})

          if (this.state.currentQuestion + 1 < this.state.examQuestions.length) {
            this.setState({currentQuestion: this.state.currentQuestion + 1})
          } else if (this.state.currentQuestion + 1 === this.state.examQuestions.length) {
            this.endExam()
          }
        })
        .catch(error => message.error('Error in saving answer.Please try again'))
    }
  };

  endExam = () => {
    const attemptId = this.state.attempt.id;
    examService.endExam(attemptId).then(attempt => {
      let score = Number(attempt.score);
      let percentage = (score/this.state.examQuestions.length) * 100;
      this.props.history.push({pathname: `/exams/${attempt.id}/statistics`, percentage: percentage, score:score})
    }).catch(() => message.error('Error in ending exam.Please try again'))
  };

  renderPage() {
    const {currentQuestion, examQuestions} = this.state;
    const radioStyle = {
      display: 'block',
    };

    if (this.state.loading) {
      return <Loading/>
    }

    if (this.state.error) {
      return <PageNotFound />
    }

    if (this.state.examQuestions.length === 0) {
      return <PageNotFound error_message="This Exam does not have any questions"/>
    }

    return <Row type="flex" className="bg-gray-100" justify="center" align="middle" style={{height: '100%'}}>
      <Col xs={22} sm={18} md={16} lg={16} xl={14}>
        <div className="bg-white px-8 pt-8">
          <p className="my-0">Question {currentQuestion + 1} of {examQuestions.length}</p>
          <QuestionComponent question={examQuestions[currentQuestion].question.question_html}/>
          <Radio.Group onChange={this.onOptionSelection} className="w-full">
            {examQuestions[currentQuestion].question.answers.map((answer, index) => {
              return ( <div className="border-t py-4" key={answer.id}>
                <Radio style={radioStyle} value={answer.id}>
                  <span dangerouslySetInnerHTML={createMarkup(answer.text_html)}></span>
                </Radio>
              </div>)
            })}
          </Radio.Group>

        </div>
        <div className="text-center my-4">
          <Button type="primary" loading={this.state.savingAnswer} onClick={this.showNextQuestion} size="large">
            {this.state.buttonLabel}
          </Button>
        </div>
      </Col>
    </Row>
  }

  render() {
    return (
        this.renderPage()
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
)(QuestionsContainer);
