import { tutorConstants } from "../constants";

export function tutors(state = { items: { results: [] } }, action) {
  switch (action.type) {
    case tutorConstants.SEARCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutorConstants.SEARCH_SUCCESS:
      return {
        ...state,
        items: action.tutors,
        loading: false
      };
    case tutorConstants.SEARCH_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}
