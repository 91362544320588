import {addQueryParam, authHeader, config, handleResponse} from "../helpers";

export const questionsService = {
	deleteQuestion, addQuestion, getQuestion, updateQuestion, getQuestions
}

function getQuestions(queryParams) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		}
	};
	let url = `${config.apiUrl}/questions/`
	url = addQueryParam(url, queryParams);

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}


function deleteQuestion(questionId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		}
	};
	let url = `${config.apiUrl}/questions/${questionId}/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}

function addQuestion(body) {

	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization,
		},
		body: JSON.stringify(body),
	};

	return fetch(
			`${config.apiUrl}/questions/`,
			requestOptions
	)
			.then(handleResponse)
			.then((question) => {
				return question;
			});
}

function getQuestion(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		}
	};

	return fetch(
			`${config.apiUrl}/questions/${id}/`,
			requestOptions
	)
			.then(handleResponse)
			.then(question => {
				return question;
			});
}


function updateQuestion(id,body) {

	const requestOptions = {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization,
		},
		body: JSON.stringify(body),
	};

	return fetch(
			`${config.apiUrl}/questions/${id}/`,
			requestOptions
	)
			.then(handleResponse)
			.then((question) => {
				return question;
			});
}