import { liveClassConstants } from "../constants";
import { classDetailService } from "../services";

export const liveClassActions = {
  getLiveClasses,
  getMyLiveClasses,
  getLiveClass,
};

function getLiveClasses() {
  return (dispatch) => {
    dispatch(request());

    classDetailService.getLiveClasses().then(
      (liveClasses) => {
        dispatch(success(liveClasses));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: liveClassConstants.LOADING };
  }

  function success(liveClasses) {
    return { type: liveClassConstants.SUCCESS, liveClasses };
  }

  function failure(error) {
    return { type: liveClassConstants.ERROR, error };
  }
}

function getLiveClass(id) {
  return (dispatch) => {
    dispatch(request());

    classDetailService.getLiveClass(id).then(
      (liveClass) => {
        dispatch(success(liveClass));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: liveClassConstants.LIVE_CLASS_DETAILS_LOADING };
  }

  function success(liveClass) {
    return { type: liveClassConstants.LIVE_CLASS_DETAILS_SUCCESS, liveClass };
  }

  function failure(error) {
    return { type: liveClassConstants.LIVE_CLASS_DETAILS_ERROR, error };
  }
}

function getMyLiveClasses() {
  return (dispatch) => {
    dispatch(request());

    classDetailService.getMyLiveClasses().then(
      (liveClasses) => {
        dispatch(success(liveClasses));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: liveClassConstants.MY_LIVE_CLASSES_LOADING };
  }

  function success(liveClasses) {
    return { type: liveClassConstants.MY_LIVE_CLASSES_SUCCESS, liveClasses };
  }

  function failure(error) {
    return { type: liveClassConstants.MY_LIVE_CLASSES_ERROR, error };
  }
}
