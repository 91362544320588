import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { examActions } from "../../actions";
import ErrorPage from "../../components/ErrorPage";
import Loading from "../../components/Loading";
import { batchService, examService } from "../../services";
import TimePicker from "../common/TimePicker";

const { Content } = Layout;
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

class EditExamForm extends Component {
  constructor(props) {
    super(props);

    let selected_batches = this.props.exam.batches_json.map((batch) => ({
      value: batch.id,
      label: batch.name,
      key: batch.id,
    }));
    this.state = {
      start_time: dayjs().format(timeFormat),
      start_date: dayjs().format(dateFormat),
      end_time: null,
      end_date: null,
      saving: false,
      duration: this.props.exam.duration,
      selected_batches: selected_batches || [],
      selected_batch_ids: this.props.exam.batches || [],
      batches: this.props.exam.batches_json || [],
    };
  }

  onFinish = (values) => {
    const data = {
      title: values.title,
      description: values.description,
      start_date: `${this.state.start_date} ${this.state.start_time}`,
      end_date: `${this.state.end_date} ${this.state.end_time}`,
      duration: `${this.state.duration}`,
      is_public: values.is_public,
      number_of_questions: values.number_of_questions,
      batches: this.state.selected_batch_ids,
    };
    if (!this.state.end_date) {
      delete data["end_date"];
    }

    this.setState({ saving: true });
    examService.updateExam(data, this.props.exam.slug).then((response) => {
      this.setState({ saving: false });
      this.props.history.push("/exams");
    });
  };

  onFinishFailed = (errorInfo) => {
    message.error("Cannot create exam");
  };

  fetchBatches = (value) => {
    this.setState({ fetching: true });
    batchService.getBatches({ search: value }).then((response) => {
      let results = response["results"];
      this.setState({ batches: results, fetching: false });
    });
  };

  handleBatchesChange = (values) => {
    console.log("Values : ", values);
    let selected_batch_ids = values.map((user) => parseInt(user.value));

    this.setState({
      selected_batches: values,
      selected_batch_ids: selected_batch_ids,
      batches: [],
      fetching: false,
    });
  };

  getFormattedEndDate = () => {
    if (this.props.exam.end_date) {
      return dayjs(this.props.exam.start_date, dateFormat);
    }
    return;
  };

  getFormattedEndTime = () => {
    if (this.props.exam.end_date) {
      return dayjs(
        dayjs(this.props.exam.end_date).format(timeFormat),
        timeFormat
      );
    }
    return;
  };

  render() {
    const { exam } = this.props;
    const { fetching, batches, selected_batches } = this.state;

    return (
      <Layout className="bg-gray-100">
        <Content className="m-10 p-5">
          <Row>
            <Col span={12} offset={6}>
              <Card className="bg-white mx-auto">
                <h3 className="text-center">Edit Exam</h3>

                <Form
                  className=" flex flex-col w-full"
                  name="basic"
                  initialValues={{
                    title: exam.title,
                    description: exam.description,
                    start_date: dayjs(exam.start_date, dateFormat),
                    start_time: dayjs(
                      dayjs(exam.start_date).format(timeFormat),
                      timeFormat
                    ),
                    end_date: this.getFormattedEndDate(),
                    end_time: this.getFormattedEndTime(),
                    no_of_questions: exam.no_of_questions,
                    is_public: exam.is_public,
                    duration: dayjs(exam.duration, "HH:mm:ss"),
                  }}
                  onFinish={this.onFinish}
                  layout="vertical"
                  onFinishFailed={this.onFinishFailed}
                >
                  <Form.Item
                    name="title"
                    label="Exam Title"
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of exam",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="description" label="Description">
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item name="start_date" label="Start Date">
                    <DatePicker
                      format={dateFormat}
                      onChange={(date) =>
                        this.setState({ start_date: date.format(dateFormat) })
                      }
                      allowClear={false}
                    />
                  </Form.Item>
                  <Form.Item name="start_time" label="Start time">
                    <TimePicker
                      allowClear={false}
                      format={timeFormat}
                      onChange={(time) =>
                        this.setState({ start_time: time.format(timeFormat) })
                      }
                      use12Hours={true}
                    />
                  </Form.Item>
                  <Form.Item name="end_date" label="End Date">
                    <DatePicker
                      format={dateFormat}
                      onChange={(date) =>
                        this.setState({ end_date: date.format(dateFormat) })
                      }
                      allowClear={false}
                    />
                  </Form.Item>
                  <Form.Item name="end_time" label="End time">
                    <TimePicker
                      allowClear={false}
                      format={timeFormat}
                      onChange={(time) =>
                        this.setState({ end_time: time.format(timeFormat) })
                      }
                      use12Hours={true}
                    />
                  </Form.Item>
                  <Form.Item label="Duration" name="duration">
                    <TimePicker
                      allowClear={false}
                      label="Select duration"
                      format="HH:mm:ss"
                      onChange={(time) =>
                        this.setState({ duration: time.format("HH:mm:ss") })
                      }
                      use12Hours={false}
                    />
                  </Form.Item>

                  <Form.Item name="no_of_questions" label="No of questions">
                    <InputNumber
                      defaultValue={exam.no_of_questions}
                      min={1}
                      max={1000}
                    />
                  </Form.Item>
                  <Form.Item label="Select Batches (Optional)">
                    <Select
                      mode="multiple"
                      name="users"
                      labelInValue
                      value={selected_batches}
                      placeholder="Type to search Batches"
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      filterOption={false}
                      onSearch={this.fetchBatches}
                      onChange={this.handleBatchesChange}
                      style={{ width: "100%" }}
                      size="medium"
                      showArrow={true}
                    >
                      {batches.map((d) => (
                        <Option key={d.id}>{d.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="is_public">
                    <Checkbox>Is Public?</Checkbox>
                  </Form.Item>
                  <Form.Item className="text-left">
                    <Button
                      type="primary"
                      loading={this.state.saving}
                      htmlType="submit"
                    >
                      Update Exam
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

class EditExamPage extends React.Component {
  isLoading() {
    return this.props.loading;
  }

  componentDidMount() {
    const { slug } = this.props.match.params;
    this.props.dispatch(examActions.getExamDetails(slug));
  }

  renderPage() {
    if (this.isLoading()) {
      return <Loading />;
    }

    if (this.props.examDetails) {
      return (
        <EditExamForm
          exam={this.props.examDetails}
          history={this.props.history}
        />
      );
    }

    return <ErrorPage error_code={404} />;
  }

  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  const { examDetails, error, loading, success } = state.exam;

  return {
    user,
    loading,
    examDetails,
  };
}

export default connect(mapStateToProps)(EditExamPage);
