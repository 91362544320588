import { dashboardService } from "../services";
import { dashboardConstants } from "../constants";

export const dashboardActions = {
  getData,
};

function getData() {
  return (dispatch) => {
    dispatch(request());

    dashboardService.getData().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstants.REQUEST };
  }

  function success(dashboardData) {
    return { type: dashboardConstants.SUCCESS, dashboardData };
  }

  function failure(error) {
    return { type: dashboardConstants.ERROR, error };
  }
}
