import { BulkUploadPage } from "../pages/admin/BulkUpload";
import CreateExamPage from "../pages/admin/CreateExam";
import EditExamPage from "../pages/admin/EditExam";
import ExamsList from "../pages/admin/ExamsList";
import LiveClass from "../pages/tutors/LiveClass";
import { PrivateRoute, PublicRoute } from "./helpers";
import Home from "../components/Home";
import InstituteHome from "../pages/institute/Home";
import JobsComponent from "../pages/institute/components/JobsComponent";
import JobsPage from "../pages/tutors/JobsPage";
import JoinMeeting from "../pages/tutors/JoinMeeting";
import CreateLiveClass from "../pages/tutors/CreateLiveClass";
import EditLiveClass from "../pages/tutors/EditLiveClass";
import Navbar from "../components/Navbar/Navbar";
import React from "react";
import { Router } from "react-router-dom";
import SearchPage from "../pages/tutors/SearchPage";
import StudentHomePage from "../pages/students/dashboard/Dashboard";
import connectedClassReview from "../pages/students/dashboard/ClassReview";
import StudentsExamList from "../pages/students/dashboard/ExamsList";
import UpcomingExamsList from "../pages/students/dashboard/UpcomingExamsList";
import HistoryExamsList from "../pages/students/dashboard/HistoryExamsList";
import StudentLiveClass from "../pages/students/dashboard/UpcomingLiveClasses";
import LoginPage from "../pages/LoginPage";
import { Switch } from "react-router";
import TutorDetail from "../pages/tutors/TutorDetail";
import ClassDetailsComponent from "../pages/tutors/components/ClassDetailsComponent";
import { UpdateTutorDetails } from "../pages/tutors/UpdateDetailsPage";
import { history } from "../helpers/history";
import Exam from "../pages/common/Exam";
import NotApproved from "../pages/common/NotApproved";
import QuestionsContainer from "../pages/common/QuestionsContainer";
import PageNotFound from "../pages/common/PageNotFound";
import ExamCompletion from "../pages/common/ExamCompletion";
import SignupPage from "../pages/common/SignUpPage";
import ForgotPassword from "../pages/common/ForgotPassword";
import UpdateProfilePage from "../pages/common/UpdateProfile";
import RestPassword from "../pages/common/RestPassword";
import AttemptsList from "../pages/admin/AttemptsList";
import ExamQuestions from "../pages/admin/ExamQuestions";
import UserStat from "../pages/admin/UserStat";
import SendEmail from "../pages/admin/SendEmail";
import QuestionFolderList from "../pages/admin/QuestionFolderList";
import QuestionsList from "../pages/admin/QuestionsList";
import Batches from "../pages/admin/Batches";
import BatchUsers from "../pages/admin/BatchUsers";
import AccountActivationInstructions from "../components/AccountActivationInstructions";
import ResendActivationEmail from "../components/ResendActivationEmail";
import VerifyActivationToken from "../components/VerifyActivationToken";
import QuestionPage from "../pages/questions/QuestionsPage";
import ExamDashboard from "../pages/common/ExamDashboard";
import Layout from "../pages/Layout";
import { ApprovedPane } from "../pages/students/dashboard/ApprovedPane";
import { PendingPage } from "../pages/students/dashboard/PendingPane";
import { TutorTable } from "../pages/students/dashboard/TutorTable";
import RequestTutor from "../pages/students/RequestTutor";
import StudentsListTable from "../pages/admin/StudentsListTable";
import CreateBatch from "../pages/admin/CreateBatch";

const wrapWithNavbar = ({ component: Component }) => {
  return (
    <React.Fragment>
      <Navbar />
      <Component />
    </React.Fragment>
  );
};
export default (
  <Router history={history}>
    <React.Fragment>
      <Switch>
        <Router
          path={[
            "/login",
            "/signup",
            "/account-verification",
            "/verify-email/:code/:id",
            "/resend-activation-mail",
            "/update-details",
          ]}
          history={history}
        >
          <Navbar />
          <PublicRoute path="/login" component={LoginPage} />
          <PublicRoute
            path="/account-verification"
            component={AccountActivationInstructions}
          />
          <PublicRoute
            path="/resend-activation-mail"
            component={ResendActivationEmail}
          />
          <PublicRoute
            path="/verify-email/:code/:id"
            component={VerifyActivationToken}
          />
          <PrivateRoute path="/not-approved" component={NotApproved} />
          <PrivateRoute strict exact path="/" component={Home} />
          <PrivateRoute
            exact
            path="/update-details"
            component={UpdateTutorDetails}
          />
          <PrivateRoute
            strict
            exact
            path="/student/dashboard"
            component={StudentHomePage}
          />
          <PublicRoute
            strict
            exact
            path="/student/signup"
            component={SignupPage}
          />
          <PublicRoute strict exact path="/signup" component={SignupPage} />
        </Router>
        <Router history={history}>
          <Layout>
            <Switch>
              <PrivateRoute path="/pending_requests" component={PendingPage} />
              <PrivateRoute path="/ongoing_classes" component={ApprovedPane} />
              <PrivateRoute
                path="/exam/completion"
                component={ExamCompletion}
              />
              <PrivateRoute
                path="/exam/:slug/start"
                component={QuestionsContainer}
              />
              <PrivateRoute path="/exam/:slug" component={Exam} />
              <PrivateRoute strict exact path="/" component={Home} />
              <PrivateRoute
                exact
                path="/update-details"
                component={UpdateTutorDetails}
              />
              <PrivateRoute
                strict
                exact
                path="/student/dashboard"
                component={StudentHomePage}
              />
              <PublicRoute
                strict
                exact
                path="/student/signup"
                component={SignupPage}
              />
              <PublicRoute
                strict
                exact
                path="/forgot-password"
                component={ForgotPassword}
              />
              <PrivateRoute
                strict
                exact
                path="/update-profile"
                component={UpdateProfilePage}
              />
              <PublicRoute
                strict
                exact
                path="/change-password/:token"
                component={RestPassword}
              />
              <PrivateRoute
                strict
                exact
                path="/search-jobs"
                component={JobsPage}
              />
              <PrivateRoute
                strict
                exact
                path="/jobs"
                component={JobsComponent}
              />
              <PrivateRoute
                strict
                exact
                path="/request_tutor"
                component={RequestTutor}
              />
              <PrivateRoute
                strict
                exact
                path="/live-class"
                component={LiveClass}
              />
              <PrivateRoute
                strict
                exact
                path="/create-live-class"
                component={CreateLiveClass}
              />
              <PublicRoute
                strict
                exact
                path="/join-meeting/"
                component={JoinMeeting}
              />
              <PublicRoute path="/search" component={SearchPage} />
              <PrivateRoute path="/send-email" component={SendEmail} />
              <PrivateRoute path="/create-exam" component={CreateExamPage} />
              <PrivateRoute
                path="/exams/:slug/attempts"
                component={AttemptsList}
              />
              <PrivateRoute
                path="/exams/:slug/questions"
                component={ExamQuestions}
              />
              <PrivateRoute
                path="/exams/:uid/statistics"
                component={UserStat}
              />
              <PrivateRoute
                strict
                exact
                path="/live-class/:id"
                component={EditLiveClass}
              />
              <PrivateRoute path="/exams/:slug" component={EditExamPage} />
              <PrivateRoute path="/exams" component={ExamsList} />
              <PrivateRoute path="/live_classes" component={StudentLiveClass} />
              <PrivateRoute
                path="/available_exams"
                component={StudentsExamList}
              />
              <PrivateRoute
                path="/my_classes"
                component={ClassDetailsComponent}
              />
              <PrivateRoute
                path="/upcoming_exams"
                component={UpcomingExamsList}
              />
              <PrivateRoute
                path="/completed_exams"
                component={HistoryExamsList}
              />
              <PrivateRoute path="/batches/:id" component={BatchUsers} />
              <PrivateRoute path="/batches" component={Batches} />
              <PrivateRoute path="/create-batch" component={CreateBatch} />
              <PrivateRoute path="/questions/add" component={QuestionPage} />
              <PrivateRoute
                path="/questions/edit/:id?"
                component={QuestionPage}
              />
              <PrivateRoute path="/questions/:slug" component={QuestionsList} />
              <PrivateRoute path="/questions" component={QuestionFolderList} />
              <PrivateRoute path="/bulk-upload" component={BulkUploadPage} />
              <PrivateRoute path="/admin" component={ExamsList} />
              <PublicRoute path="/tutor/signup" component={SignupPage} />
              <PrivateRoute path="/tutors" component={TutorTable} />
              <PrivateRoute path="/students" component={StudentsListTable} />
              <PrivateRoute path="/tutor/:id" component={TutorDetail} />
              <PrivateRoute
                strict
                exact
                path="/institute"
                component={InstituteHome}
              />
              <PublicRoute path="/institute/signup" component={SignupPage} />
              <PrivateRoute path="/examdashboard" component={ExamDashboard} />
              <PrivateRoute path="/reviews" component={connectedClassReview} />
              <PrivateRoute component={PageNotFound} />
            </Switch>
          </Layout>
        </Router>
      </Switch>
    </React.Fragment>
  </Router>
);
