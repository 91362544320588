import React, { Component } from "react";
import { examActions, jobActions } from "../../actions";
import { connect } from "react-redux";
import { Button, Layout, List, Skeleton, Table, Space } from "antd";
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Column } = Table;

const expandable = {
  expandedRowRender: (record) => <p>{record.state}</p>,
};

class AttemptsList extends Component {
  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    this.state = {
      pagination: {
        current: 1,
        pageSize: 20,
      },
      slug: slug,
      expandable: window.screen.width <= 640 ? expandable : null,
    };
  }

  componentDidMount() {
    this.props.dispatch(examActions.getAttempts(this.state.slug));
  }

  onTableChange(pagination) {
    this.props.dispatch(
      examActions.getAttempts(this.state.slug, pagination.current)
    );
  }

  render() {
    const { attempts, error, loading } = this.props;

    return (
      <Layout>
        <Content>
          <h3 className="text-center mb-5">Exam Statistics</h3>
          <Table
            dataSource={attempts.results}
            loading={loading}
            expandable={this.state.expandable}
            onChange={this.onTableChange.bind(this)}
            pagination={{
              current: attempts.page,
              total: attempts.count,
              pageSize: attempts.page_size,
            }}
            rowKey="id"
          >
            <Column
              title="S.No"
              render={(text, record, index) => index + 1}
              key="displayName"
            />
            <Column
              title="Student Name"
              dataIndex={["user", "display_name"]}
              key="displayName"
            />
            <Column title="Status" dataIndex={"state"} />
            <Column title="UnAnswered" dataIndex={"unanswered_count"} />
            <Column title="Correct" dataIndex={"correct_answers_count"} />
            <Column title="InCorrect" dataIndex={"incorrect_answers_count"} />
            <Column
              title="Score"
              sorter={(a, b) => {
                return Number(a.score) - Number(b.score);
              }}
              dataIndex={"score"}
              key="score"
            />
            <Column
              title="Start Time"
              sorter={(a, b) => {
                return dayjs(a.date) - dayjs(b.date);
              }}
              dataIndex={"date"}
              key="date"
              render={(date) => dayjs(date).format("DD/MM/YYYY hh:mm A")}
            />
            <Column
              title="Action"
              key="action"
              render={(attempt) => (
                <Space size="middle">
                  <Link to={"/exams/" + attempt.id + "/statistics"}>
                    <EyeOutlined /> View Stats
                  </Link>
                </Space>
              )}
            />
          </Table>
        </Content>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { attempts, error, loading } = state.attempts;
  return {
    attempts,
    error,
    loading,
  };
}

const connectedAttemptsListComponent = connect(mapStateToProps)(AttemptsList);
export default connectedAttemptsListComponent;
