import { Empty, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import React from "react";
import { SucessEnquiryCard } from "../../../components/EnquiryComponent";
import { classDetailActions } from "../../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function ApprovedList(props) {
  const classDetailsList = props.classDetails.results.map((classDetail) => (
    <SucessEnquiryCard
      loading={props.loading}
      classDetail={classDetail}
      key={classDetail.id}
    />
  ));
  return classDetailsList;
}

function ApprovedListLoading() {
  let array = new Array(3);
  const approvedPendingList = [...array.keys()].map((data, index) => (
    <SucessEnquiryCard loading={true} key={index} />
  ));
  return approvedPendingList;
}

class Approved extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(classDetailActions.getClassDetails());
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold">Ongoing Classes</h1>
          <Link to="/request_tutor">
            <Button
              icon={<PlusOutlined style={{ verticalAlign: "baseline" }} />}
              type="primary"
            >
              Request a class
            </Button>
          </Link>
        </div>
        <div>
          {!this.props.loading &&
          this.props.classDetails &&
          this.props.classDetails.results.length === 0 ? (
            <Empty description="You do not have any classes yet" />
          ) : null}

          <div className="flex flex-wrap">
            {this.props.loading ? (
              <ApprovedListLoading />
            ) : (
              <ApprovedList
                classDetails={this.props.classDetails}
                loading={this.props.loading}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loading, classDetails } = state.classDetails;
  return {
    loading,
    classDetails,
  };
}

export const ApprovedPane = connect(mapStateToProps)(Approved);
