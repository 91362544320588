import { addQueryParam, authHeader, config } from "../helpers";

export const tutorService = {
  getTutors,
  getTutor
};

function getTutors(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    }
  };
  let url = `${config.apiUrl}/tutors/`;
  url = addQueryParam(url, queryParams);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(tutors => {
      return tutors;
    });
}

function getTutor(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    }
  };

  return fetch(`${config.apiUrl}/tutors/${id}/`, requestOptions)
    .then(handleResponse)
    .then(tutor => {
      return tutor;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
