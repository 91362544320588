import React from 'react';


function createMarkup(html) {
  return {__html: html};
}

const QuestionComponent = ({question, classes="text-xl font-bold mb-5"}) => {
  return (
    <div className={classes} dangerouslySetInnerHTML={createMarkup(question)}>
    </div>
  );
};

export default QuestionComponent;
