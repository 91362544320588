import React from 'react';
import { Result } from 'antd';
import { ClockCircleTwoTone } from '@ant-design/icons';

const NotApproved = () => {
  return (
    <div>
   <Result
      icon={<ClockCircleTwoTone twoToneColor="#52c41a" />}
      title="Thanks for signing up. We will verify your profile and get back to you as soon as possible."
    />
    </div>
  );
};

export default NotApproved;
