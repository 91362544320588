import React from 'react'
import { Result, Button } from 'antd';


const AccountActivationInstructions = () => {
	return <Result
			title="Your account is not yet verified. Please click the activation link that is sent to your mail"
			extra={
				<Button type="primary" key="console" href="/resend-activation-mail">
					Resend
				</Button>
			}
	/>
};

export default AccountActivationInstructions;