import React, { Component } from "react";

import SearchItem from "../../components/SearchItem/SearchItem";
import { connect } from "react-redux";
import { tutorActions } from "../../actions";

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }

  componentDidMount() {
    this.props.dispatch(tutorActions.search());
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.getTutors(this.state.search);
  };

  getTutors = query => {
    const { dispatch } = this.props;
    let queryParams = { search: query };
    dispatch(tutorActions.search(queryParams));
  };

  render() {
    return (
      <React.Fragment>
        <div className="w-screen py-8">
          <div className="bg-blue-500">
            <div className="container py-8 mx-auto">
              <input
                className="w-full h-12 px-3 rounded mb-8 focus:outline-none focus:shadow-outline text-xl px-8 shadow-lg"
                type="search"
                name="search"
                onChange={this.onChange}
                value={this.state.search}
                placeholder="Search..."
              />
            </div>
          </div>
          <div className="bg-gray-200">
            <div className="container py-8 mx-auto">
              <div className="flex flex-wrap items-center justify-center">
                {this.props.items.results.map((tutor, index) => (
                  <SearchItem key={index} tutor={tutor} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { items } = state.tutors;
  return {
    items
  };
}

const connectedSearchComponent = connect(mapStateToProps)(SearchPage);
export default connectedSearchComponent;
