import React from "react";
import { AddQuestion } from "./AddQuestion";

import queryString from "query-string";

export default class QuestionPage extends React.Component {
  render() {
    const queryParams = queryString.parse(this.props.location.search);
    const { id } = this.props.match.params;

    return (
      <div>
        <AddQuestion
          key={id ? id : "create"}
          id={id}
          exam_slug={
            this.props.location.query?.exam_slug || queryParams?.exam_slug
          }
        />
      </div>
    );
  }
}
