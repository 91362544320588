import {Button, Col, Form, Result, Input, notification, Row} from "antd";
import React, {Component} from 'react';

import {userService} from "../../services"
import Loading from "../../components/Loading";

class RestPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null
    }
  }

  onSubmit = (values) => {
    const {token} = this.props.match.params;
    const data = {token: token, password: values.password}
    this.setState({loading: true})
    userService.changePassword(data)
      .then(() => {
        this.setState({loading: false})
        notification["success"]({
          message: 'Password Changed',
          description:
            'Your Password is changed successfully',
        });
      })
      .catch(() => {
        this.setState({loading: false})
        notification["error"]({
          message: 'Error',
          description:
            'This password is common, Try a different password.',
        });
      })
  }

  onFinishFailed = () => {
  }


  componentDidMount() {
    const {token} = this.props.match.params;
    const data = {token: token}
    userService.validatePasswordToken(data)
      .then(() => this.setState({loading: false}))
      .catch(() => {
        this.setState({
          loading: false,
          error: "This link is expired or invalid"
        })
      })
  }

  renderForm() {
    if (!this.state.error) {
      return (
        <section id="sign-in" className="bglight h-full position-relative py-10">
            <div className="container" style={{margin: "0 auto"}}>
              <div className="row">
                <div className="col-md-12 mb-8 text-center wow fadeIn" data-wow-delay="300ms">
                  <h2 className="heading bottom40 darkcolor font-light2">Reset<span
                    className="font-normal"> Password</span>
                    <span className="divider-center"/>
                  </h2>
                </div>

                <Row className="w-full">
                  <Col span={12} offset={6} className="whitebox">
                    <div className="widget logincontainer">
                      <h3 className="darkcolor mb-3 text-center lg:text-left">
                        Change Password
                      </h3>

                      <Form
                        name="basic"
                        onFinish={this.onSubmit}
                        onFinishFailed={this.onFinishFailed()}
                      >
                        <p>New Password</p>
                        <Form.Item
                          name="password"
                          rules={[{required: true},{min: 8, message: 'Password must be minimum 8 characters.'}]}
                        >
                          <Input.Password size="large" placeholder="New Password"/>
                        </Form.Item>
                        <p>Confirm Password</p>
                        <Form.Item
                            name="confirm"
                          dependencies={['password']}
                          rules={[
                            {
                              required: true,
                              message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                              validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                              },
                            }),
                          ]}>
                          <Input.Password size="large" placeholder="Confirm Password"/>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
      )
    }
  }

  render() {
    return (
      <div className="h-full">
        {this.state.error ? <Result
          status="warning"
          title="This link is expired or invalid"
        />: null}
        {this.state.loading ? <Loading/> :
          this.renderForm()
        }
      </div>
    );
  }
}

export default RestPassword;