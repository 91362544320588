import { Button, List, Modal, Pagination, Skeleton } from "antd";
import React, { Component } from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import JobModal from "./JobModal";
import { connect } from "react-redux";
import { jobActions } from "../../../actions";
import moment from "moment";

const { confirm } = Modal;

class JobsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalTitle: "Post a Job",
      clickedJob: {},
    };
  }
  componentDidMount() {
    this.props.dispatch(jobActions.getJobs());
  }

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  showConfirm = (job) => {
    const { dispatch } = this.props;
    confirm({
      title: "Do you want to delete this Job?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(jobActions.deleteJob({ id: job.id }));
      },
      onCancel() {},
    });
    return;
  };

  handleFormSubmit = (values) => {
    values["last_apply_date"] = values["last_apply_date"].format("YYYY-MM-DD");
    if (values["action"] === "create") {
      this.props.dispatch(jobActions.createJob(values));
    } else {
      values["id"] = this.state.clickedJob.id;
      this.props.dispatch(jobActions.updateJob(values));
    }
  };

  render() {
    const {
      loading,
      jobs,
      create_job_loading,
      create_job_success,
      update_job_success,
      update_job_loading,
      delete_job_success,
    } = this.props;

    if (create_job_success || update_job_success || delete_job_success) {
      this.setState({ clickedJob: {} });
      this.props.dispatch(jobActions.getJobs());
      this.setState({ isModalVisible: false });
    }

    return (
      <React.Fragment>
        <JobModal
          title={this.state.modalTitle}
          confirmLoading={create_job_loading || update_job_loading}
          visibilility={this.state.isModalVisible}
          onCancel={this.handleCancel}
          onFormSubmit={this.handleFormSubmit}
          job={this.state.clickedJob}
        />

        <div className="flex mt-2 justify-between border-b-1 pb-2">
          <h3 className="text-center  text-lg font-bold">Jobs Posted by you</h3>
          <Button
            type="primary"
            onClick={(e) => {
              this.setState({ isModalVisible: true, clickedJob: {} });
            }}
          >
            Post a Job
          </Button>
        </div>
        <List
          className="demo-loadmore-list px-5 bg-white"
          loading={loading}
          itemLayout="horizontal"
          dataSource={jobs["results"]}
          renderItem={(item) => (
            <List.Item
              actions={[
                <React.Fragment>
                  <Button
                    type="link"
                    key="list-loadmore-edit"
                    onClick={(e) => {
                      let job = item;
                      job["last_apply_date"] = moment(
                        new Date(job["last_apply_date"])
                      );
                      this.setState({
                        visible: true,
                        clickedJob: job,
                        isModalVisible: true,
                      });
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="primary"
                    danger
                    onClick={() => this.showConfirm(item)}
                  >
                    Delete
                  </Button>
                </React.Fragment>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  className="px-5"
                  title={item.role}
                  description={item.description}
                />
              </Skeleton>
            </List.Item>
          )}
        />
        <br />
        {jobs.results.length > 0 && (
          <Pagination
            className="text-center mt-5"
            defaultCurrent={jobs.page}
            total={jobs.count}
            pageSize={jobs.page_size}
            onChange={this.onPageChange}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    jobs,
    error,
    success,
    loading,
    create_job_loading,
    update_job_loading,
    delete_job_loading,
    create_job_success,
    create_job_error,
    update_job_success,
    delete_job_success,
  } = state.job;
  return {
    jobs,
    success,
    error,
    loading,
    create_job_loading,
    update_job_loading,
    delete_job_loading,
    create_job_success,
    create_job_error,
    update_job_success,
    delete_job_success,
  };
}

const connectedJobsComponent = connect(mapStateToProps)(JobsComponent);
export default connectedJobsComponent;
