import { Alert, Button, Form, Checkbox } from "antd";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Input from "../../components/Input";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import ReCAPTCHA from 'react-google-recaptcha'

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      password1: "",
      state: "",
      city: "",
      address: "",
      errorMessage: "",
      phone: "",
      school_name: "",
      class_detail: "",
      contact_person_name: "",
      captcha: false,
      g_recaptcha_response: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.recaptchaRef = React.createRef()

    this.fieldMapper = {
      first_name: {
        name: "firstName",
        type: "text",
        placeholder: this.props.labels ? this.props.labels[0] : "First Name",
        onInputChange: this.handleChange,
        required: true,
      },
      last_name: {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        onInputChange: this.handleChange,
        required: true,
      },
      name: {
        name: "name",
        type: "text",
        placeholder: "Name",
        onInputChange: this.handleChange,
        required: true,
      },
      email: {
        name: "email",
        type: "email",
        placeholder: "Email",
        onInputChange: this.handleChange,
        required: true,
      },
      username: {
        name: "username",
        type: "text",
        placeholder: "Username",
        onInputChange: this.handleChange,
        required: true,
      },
      password: {
        name: "password",
        type: "password",
        placeholder: "Password",
        onInputChange: this.handleChange,
        required: true,
      },
      password1: {
        name: "password1",
        type: "password",
        placeholder: "Confirm Password",
        onInputChange: this.handleChange,
        required: true,
      },
      photo: {
        name: "photo",
        type: "file",
        placeholder: "Profile Photo",
        onInputChange: this.handleChange,
        required: false,
      },
      state: {
        name: "state",
        type: "text",
        placeholder: "State",
        onInputChange: this.handleChange,
        required: true,
      },
      city: {
        name: "city",
        type: "text",
        placeholder: "City",
        onInputChange: this.handleChange,
        required: true,
      },
      address: {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        onInputChange: this.handleChange,
        required: true,
      },
      phone: {
        name: "phone",
        type: "number",
        placeholder: "Mobile Number",
        onInputChange: this.handleChange,
        required: true,
      },
      school_name: {
        name: "school_name",
        type: "text",
        placeholder: "Name of the School",
        onInputChange: this.handleChange,
        required: true,
      },
      class_detail: {
        name: "class_detail",
        type: "text",
        placeholder: "Class you study",
        onInputChange: this.handleChange,
        required: true,
      },
      contact_person_name: {
        name: "contact_person_name",
        type: "text",
        placeholder: "Contact Person Name",
        onInputChange: this.handleChange,
        required: true,
      }
    };
  }

  componentDidUpdate() {
    const { user, history } = this.props;
    if (localStorage.getItem("AUTH_TOKEN")) {
      if (Object.entries(user).length) {
        history.push("/");
      }
    }
  }

  parseError(error) {
    if(error) {
      for (const [name, error_message] of Object.entries(error)) {
        if (name in this.fieldMapper) {
          this.fieldMapper[name].validateStatus = "error";
          this.fieldMapper[name].errorMessage = error_message[0]
        } else {
          if (this.state.errorMessage === "") {
            this.setState({ errorMessage: error_message });
          }
        }
      }
    }
  }

  handleChange(evt) {
    const value = evt.target.value;
    this.setState({
      [evt.target.name]: value,
    });
  }
  validatePassword() {
    return this.state.password === this.state.password1;
  }

  onFinishFailed = (errorInfo) => {
    console.log("Error Info ", errorInfo)
  }

  handleCaptcha = (key) => {
    this.setState({
      captcha: true,
      g_recaptcha_response: key
    })
  };

  submit(values) {
    this.setState({ errorMessage: "" });
    if (!this.validatePassword()) {
      this.setState({ errorMessage: "Passwords does not match." });
      return;
    }

    if (!this.state.captcha) {
      this.setState({errorMessage: "Please enter the captcha."})
      return;
    }

    const { dispatch } = this.props;

    let user = {
      email: this.state.email,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      username: this.state.username,
      password: this.state.password,
      photo: this.state.photo,
      state: this.state.state,
      city: this.state.city,
      address: this.state.address,
      user_type: this.props.userType || 1,
      phone: this.state.phone,
      school_name: this.state.school_name,
      class_detail: this.state.class_detail,
      contact_person_name: this.state.contact_person_name,
      g_recaptcha_response: this.state.g_recaptcha_response
    };
    dispatch(userActions.signup(user));
    this.recaptchaRef.current.reset();
    this.setState({captcha: false})
  }

  render() {
    if (this.props.error) {
      this.parseError(this.props.error)
    }
    if (this.props.signedUp) {
      return <Redirect to="/account-verification" />;
    }

    let signingUp = this.props.signingUp;
    return (
      <div className="flex justify-left">
        <Form scrollToFirstError={true}
              onFinishFailed={this.onFinishFailed}
              onFinish={this.submit} className="flex flex-col w-full" style={{'margin': '0'}}>
          {this.props.requiredFields.map((value, index) => {
            return (
              <Form.Item className="mb-2"
                         key={index}
                         validateStatus={this.fieldMapper[value].validateStatus}
                         help={this.fieldMapper[value].errorMessage}
              rules={[{required: this.fieldMapper[value].required}]}>
                <Input value={this.state[value]} {...this.fieldMapper[value]} />
              </Form.Item>
            );
          })}
          <ReCAPTCHA
              onChange={this.handleCaptcha}
              className="mb-2"
              ref={this.recaptchaRef}
              sitekey="6LdlAs8ZAAAAAEnyb0NupvJQ9xQSfyF8hDiOGUcs"
          />
          {this.state.errorMessage ? (
            <Alert
              className="mb-2"
              message={this.state.errorMessage}
              type="error"
              showIcon
            />
          ) : null}

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                { validator:(_, value) => value ? Promise.resolve() : Promise.reject('Please accept privacy policy and terms of service to continue') },
              ]}
            >
              <Checkbox>
                I accept the
                <a className="text-primary" href="https://sprucedutech.com/terms_of_service.html" target="_blank"> Terms of Use
                </a> and
                <a className="text-primary" href="https://sprucedutech.com/privacy_policy.html" target="_blank"> Privacy Policy
                </a>
              </Checkbox>
            </Form.Item>


          <Button
            htmlType="submit"
            className="custom-bottom gradient-btn"
            loading={signingUp}
            type="primary"
            shape="circle"
            block={false}
            style={{ width: "100px" }}
          >
            Sign Up
          </Button>
          <div className="text-sm mt-3">
            Already have an account?
            <Link className="text-primary" to={this.props.loginLink}> Log in
            </Link>
          </div>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { signingUp } = state.users;
  const { loggedIn, error, signedUp } = state.authentication;
  const { user } = state.user;

  return { signingUp, error, loggedIn, user, signedUp };
}

const connectedSignupComponent = connect(mapStateToProps)(Signup);
export { connectedSignupComponent as Signup };
