import { Avatar as AvatarIcon } from "antd";
import React from "react";
import { UserOutlined } from "@ant-design/icons";

export default function Avatar({ user, size = "default" }) {
  function getAvatar(user) {
    if (!user || !user.display_name) {
      return <AvatarIcon icon={<UserOutlined />} size={size} />;
    }
    if (user.profile_photo_url) {
      return <AvatarIcon src={user.profile_photo_url} size={size} />;
    }

    return <AvatarIcon size={size}>{user.display_name.charAt(0)}</AvatarIcon>;
  }

  return <React.Fragment>{getAvatar(user)}</React.Fragment>;
}
