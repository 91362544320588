export function randomColor() {
  var lum = -0.25;
  var hex = String(
    "#" + Math.random().toString(16).slice(2, 8).toUpperCase()
  ).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }
  return rgb;
}


export function strFormat() {
  if (arguments.length === 0) {
    throw "No arguments";
  }
  const string = arguments[0];
  const lst = string.split("{}");
  if (lst.length !== arguments.length) {
    throw "Placeholder format mismatched";
  }
  let string2 = "";
  let off = 1;
  for (let i = 0; i < lst.length; i++) {
    if (off < arguments.length) {
      string2 += lst[i] + arguments[off++]
    } else {
      string2 += lst[i]
    }
  }
  return string2;
}


export function createMarkup(html) {
  return {__html: html};
}