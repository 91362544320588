import { addQueryParam, authHeader, config, handleResponse } from "../helpers";

export const enquiryService = {
  sendEnquiry,
  getEnquiries
};

function sendEnquiry(enquiry) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(enquiry)
  };

  return fetch(`${config.apiUrl}/enquiries/`, requestOptions)
    .then(handleResponse)
    .then(enquiry => {
      return enquiry;
    });
}

function getEnquiries(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    }
  };
  let url = `${config.apiUrl}/enquiries/`;
  url = addQueryParam(url, queryParams);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(enquiry => {
      return enquiry;
    });
}
