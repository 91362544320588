import React from "react";
import "./Footer.css";
import Logo from "../assets/img/logo_white.svg";
import { Row, Col } from "antd";

export default function Footer() {
  return (
    <React.Fragment>
      <footer id="site-footer" className=" bgdark padding_top">
        <div className="b-container">
          <Row gutter={32}>
            <Col lg={6} md={12} sm={24}>
              <div className="footer_panel padding_bottom_half bottom20">
                <a
                  className="footer_logo bottom25"
                  style={{ width: "200px" }}
                  href="/"
                >
                  <img src={Logo} alt="logo" />
                </a>
                <p className="whitecolor bottom25 text-justify">
                  SprucEdutech makes learning interactive and fun by offering online learning solutions to both students and teachers
                </p>
                <div className="d-table w-100 address-item whitecolor bottom25">
                  <span className="d-table-cell align-middle">
                    <i className="fas fa-mobile-alt" />
                  </span>
                  <p className="d-table-cell align-middle bottom0">
                    +91-81799 37641{" "}
                    <a className="d-block" href="mailto:admin@sprucedutech.com">
                      admin@sprucedutech.com
                    </a>
                  </p>
                </div>
                <ul
                  className="social-icons white wow fadeInUp"
                  data-wow-delay="300ms"
                  style={{
                    visibility: "visible",
                    animationDelay: "300ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <li>
                    <a
                      className="facebook"
                      href="https://www.facebook.com/SprucEdutech-110260480601089/"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter"
                      href="https://twitter.com/SprucEdutech"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="linkedin"
                      href="https://www.linkedin.com/company/sprucedutech/about/"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="insta"
                      href="https://www.instagram.com/spruce_edutech/"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                    <li><a className="whatsapp" href="https://wa.me/8179937641"><i className="fab fa-whatsapp" /> </a>
  </li>
  <li><a href="mailto:info@sprucedutech.com"><i className="far fa-envelope" /> </a></li>
                </ul>
              </div>
            </Col>
            <Col lg={6} md={12} sm={24}>
              <h3 className="whitecolor bottom25">Latest News</h3>
              <ul className="latest_news whitecolor">
                <li>
                  <a className="whitecolor"
                    href="https://blog.sprucedutech.com/2020/06/08/handpicked-e-learning-tools-for-students-and-teachers-in-2020/">
                    Handpicked E-Learning Tools for...
                  </a>
                  <span className="date defaultcolor">8 June 2020</span>
                </li>
                <li>
                  <a className="whitecolor" href="https://blog.sprucedutech.com/2020/06/01/technology-in-the-education-sector-an-overview/">
                    Technology in the Education Sector...
                  </a>
                  <span className="date defaultcolor">1 June 2020</span>
                </li>
                <li>
                  <a className="whitecolor" href="https://blog.sprucedutech.com/2020/05/25/transformation-of-education-methods/">
                    Transformation of Education Methods...
                  </a>
                  <span className="date defaultcolor">25 May 2020</span>
                </li>
              </ul>
            </Col>
            <Col lg={6} md={12} sm={24}>
              <div className="footer_panel padding_bottom_half bottom20">
                <h3 className="whitecolor bottom25">Our Services</h3>
                <ul className="links">
                  <li><i className="fas fa-caret-right"></i><a href="https://sprucedutech.com/">Home</a></li>
                  <li><i className="fas fa-caret-right"></i><a href="https://sprucedutech.com/about.html">About Us</a></li>
                  <li><i className="fas fa-caret-right"></i><a href="https://sprucedutech.com/#services">Services</a></li>
                  <li><i className="fas fa-caret-right"></i><a href="https://blog.sprucedutech.com" target="_blank">Blog</a></li>
                  <li><i className="fas fa-caret-right"></i><a href="https://sprucedutech.com/#stayconnect">Contact Us</a></li>
                  <li><i className="fas fa-caret-right"></i><a href="https://sprucedutech.com/privacy_policy.html">Privacy Policy</a></li>
                  <li><i className="fas fa-caret-right"></i><a href="https://sprucedutech.com/terms_of_service.html">Terms of Use</a></li>
                </ul>              </div>
            </Col>

            <Col lg={6} md={12} sm={24}>
              <div className="footer_panel padding_bottom_half bottom20">
                <h3 className="whitecolor bottom25">Business hours</h3>
                <p className="whitecolor bottom25">Our support available to help you 24 hours a day, seven days week</p>
                <table className="buisness-hours">
                  <tbody>
                  <tr className="">
                    <td>Monday-Saturday:</td>
                    <td className="text-right">8.00-18.00</td>
                  </tr>
                  <tr className="spacer"></tr>
                  <tr>
                    <td>Friday:</td>
                    <td className="text-right">09:00-21:00</td>
                  </tr>
                  <tr className="spacer"></tr>
                  <tr>
                    <td>Sunday:</td>
                    <td className="text-right">09:00-20:00</td>
                  </tr>
                  <tr className="spacer"></tr>
                  <tr>
                    <td>Calendar Events:</td>
                    <td className="text-right">24-Hour Shift</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </Col>

          </Row>
        </div>
      </footer>
      <div className="copyright">
        <div className="container">
          <Row className="row" style={{marginRight: 0}}>
            <Col
              span={12}
              className="text-left wow fadeIn  animated"
              data-wow-delay="300ms"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeIn",
              }}
            >
              <p className="m-0 py-3">
                Copyright © <span id="year1">2019-2020</span>{" "}
                <a href="https://sprucedutech.com" className="hover-default">
                  SprucEdutech
                </a>
                . All Rights Reserved.
              </p>
            </Col>
            <Col span={12} className="text-right">
              <p className="m-0 py-3">Hyderabad | Vizag | Vijayawada | Guntur | Warangal</p>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
