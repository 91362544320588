import {authHeader, config} from "../helpers";

export const utilService = {
  uploadFile, sendEmail, bulkUploadQuestions
};

function uploadFile(uploadDetails) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(uploadDetails),
  };

  return fetch(`${config.apiUrl}/presigned_url/`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function sendEmail(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/emails/`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
}

function bulkUploadQuestions(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(data),
  };
  return fetch(`${config.apiUrl}/exams/bulk-upload-questions/`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function logout() {
  localStorage.removeItem("user");
}
