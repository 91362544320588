import React, { Component } from "react";
import { examActions, liveClassActions } from "../../actions";
import Loading from "../../components/Loading";
import ErrorPage from "../../components/ErrorPage";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { classDetailService } from "../../services";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Result,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import TimePicker from "../common/TimePicker";

const { Content } = Layout;
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

class EditLiveClassForm extends Component {
  constructor(props) {
    super(props);
    let selected_users = this.props.liveClass.students.map((student) => ({
      value: student.id,
      label: student.display_name,
      key: student.id,
    }));
    let selected_user_ids = this.props.liveClass.students.map((a) => a.id);

    this.state = {
      meeting_url: "",
      key: "",
      meeting_id: "",
      password: "",
      selected_users: selected_users || [],
      selected_user_ids: selected_user_ids || [],
      users: [],
      fetching: false,
      start_time: dayjs().format(timeFormat),
      start_date: dayjs().format(dateFormat),
      saving: false,
    };
  }

  onFinish = (values) => {
    const data = {
      start_time: `${this.state.start_date} ${this.state.start_time}`,
      users: this.state.selected_user_ids,
      name: values.class_name,
      duration: values.duration,
      description: values.description,
    };
    this.setState({ saving: true });
    classDetailService
      .updateLiveClass(data, this.props.liveClass.id)
      .then((response) => {
        this.setState({ saving: false });
        this.props.history.push("/live-class");
      });
  };

  copyCodeToClipboard = () => {
    const el = this.input;
    window.input = this.input;
    el.select();
    document.execCommand("copy");
  };

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = (value) => {
    this.setState({ fetching: true });
    classDetailService.getClassDetails().then((classDetails) => {
      let results = classDetails["results"];
      let users = results.map((classDetail) => classDetail.student);
      this.setState({ users, fetching: false });
    });
  };

  handleUsersChange = (values) => {
    let selected_user_ids = values.map((user) => parseInt(user.value));

    this.setState({
      selected_users: values,
      selected_user_ids: selected_user_ids,
      users: [],
      fetching: false,
    });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const { liveClass } = this.props;
    const { fetching, users, selected_users } = this.state;

    return (
      <Layout className="h-full bg-gray-100">
        <Content className="m-10 p-5">
          {this.state.meeting_url ? (
            <Result
              status="success"
              title="Successfully Created class"
              subTitle=""
              extra={[
                <div className="output">
                  <Button
                    dayjs
                    type="primary"
                    href={`${this.state.meeting_url}`}
                    target="_BLANK"
                  >
                    Start class
                  </Button>

                  <Input
                    ref={(input) => (this.input = input)}
                    className="mt-6"
                    addonAfter={
                      <CopyToClipboard
                        text={`https://app.sprucedutech.com/join-meeting?id=${this.state.meeting_id}&key=${this.state.key}&password=${this.state.password}`}
                        onCopy={() => {
                          message.info("Link copied to clipboard.");
                        }}
                      >
                        <Tooltip title="Click to copy">
                          <CopyOutlined />
                        </Tooltip>
                      </CopyToClipboard>
                    }
                    disabled
                    defaultValue={`https://app.sprucedutech.com/join-meeting?id=${this.state.meeting_id}&key=${this.state.key}&password=${this.state.password}`}
                  />

                  <p>Share the above url to users to join in your meet.</p>
                </div>,
              ]}
            />
          ) : null}

          {this.state.meeting_url ? null : (
            <Row>
              <Col span={12} offset={6}>
                <Card className="bg-white mx-auto">
                  <h3 className="text-center">Live Class</h3>

                  <Form
                    className=" flex flex-col w-full"
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    layout="vertical"
                    onFinishFailed={this.onFinishFailed}
                    initialValues={{
                      class_name: liveClass.name,
                      description: liveClass.description,
                      start_date: dayjs(liveClass.start_date, dateFormat),
                      start_time: dayjs(
                        dayjs(liveClass.start_date).format(timeFormat),
                        timeFormat
                      ),
                      duration: liveClass.duration,
                    }}
                  >
                    <Form.Item
                      label="Class name"
                      name="class_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the name of class",
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                      <Input.TextArea
                        style={{ width: "100%" }}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                    <Form.Item name="start_time" label="Start Date and time">
                      <DatePicker
                        format={dateFormat}
                        onChange={(date) =>
                          this.setState({ start_date: date.format(dateFormat) })
                        }
                        defaultValue={dayjs(
                          dayjs().format(dateFormat),
                          dateFormat
                        )}
                        allowClear={false}
                      />
                      &nbsp;
                      <TimePicker
                        defaultValue={dayjs(
                          dayjs().format(timeFormat),
                          timeFormat
                        )}
                        allowClear={false}
                        format={timeFormat}
                        onChange={(time) =>
                          this.setState({ start_time: time.format(timeFormat) })
                        }
                        use12Hours={true}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Duration (In minutes)"
                      name="duration"
                      rules={[
                        {
                          required: true,
                          message: "Please input the duration of class",
                        },
                      ]}
                    >
                      <InputNumber
                        name="duration"
                        min={1}
                        max={300}
                        defaultValue={60}
                      />
                    </Form.Item>
                    <Form.Item label="Select Users">
                      <Select
                        mode="multiple"
                        name="users"
                        labelInValue
                        value={selected_users}
                        placeholder="Type to search users"
                        notFoundContent={
                          fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={this.fetchUsers}
                        onChange={this.handleUsersChange}
                        style={{ width: "100%" }}
                        size="medium"
                        showArrow={true}
                      >
                        {users.map((d) => (
                          <Option key={d.id}>{d.display_name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div className="mb-2">
                      <Alert
                        message="Class can only be cancelled 24 hrs ahead of scheduled time"
                        type="warning"
                      />
                    </div>

                    <Form.Item className="text-left">
                      <Button
                        type="primary"
                        loading={this.state.saving}
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
            </Row>
          )}
        </Content>
      </Layout>
    );
  }
}

class EditLiveClass extends React.Component {
  isLoading() {
    return this.props.loading;
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.dispatch(liveClassActions.getLiveClass(id));
  }

  renderPage() {
    if (this.isLoading()) {
      return <Loading />;
    }

    if (this.props.liveClass) {
      return (
        <EditLiveClassForm
          liveClass={this.props.liveClass}
          history={this.props.history}
        />
      );
    }

    return <Empty description="Page you are looking for does not exist" />;
  }

  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { detailsLoading, liveClass, detailsError } = state.liveClasses;

  return {
    detailsError,
    detailsLoading,
    liveClass,
  };
}

export default connect(mapStateToProps)(EditLiveClass);
