import React, { Component } from 'react';
import { Avatar, Button, Layout, List, message, Modal, Form, Tabs, Select, Input } from "antd";
import { examService, questionSetService, questionsService } from "../../services";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import QuestionComponent from "../../components/ExamComponent/question";
import { createMarkup } from "../../helpers";
import { Link } from "react-router-dom";
import QuestionSearch from "./questionSearch";


const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;

class ExamQuestions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initLoading: true,
			loading: false,
			data: [],
			list: [],
			next_page_url: null,
			exam_slug: this.props.match.params.slug,
			fetching: false,
			addQuestionVisible: false,
			questionSet:[]
		};
		
	}	



	componentDidMount() {
		this.fetchQuestions()			
	}

	fetchQuestions = (clear_existing = false) => {
		this.setState({
			loading: true,
		});
		if (clear_existing) {
			this.setState({
				list: [],
				next_page_url: null
			})
		}
		examService.getQuestions(this.state.exam_slug, this.state.next_page_url)
				.then((response) => {
					let examQuestions = this.parseQuestions(response.results)
					this.setState(prevState => ({
						list: [...prevState.list, ...examQuestions],
						initLoading: false,
						loading: false,
						next_page_url: response["next"]
					}));
				})
	};

	parseQuestions = (results) => {
		let examQuestions = results['exam_questions']
		let questions = results['questions'] || []
		examQuestions.forEach((examQuestion) => {
			let questionIndex = questions.findIndex(question => question.id === examQuestion.question_id);
			examQuestion.question = questions[questionIndex]
		});
		return questions
	};


	handleUsersChange = values => {
		let selected_user_ids = values.map(user => parseInt(user.value));

		this.setState({
			selected_users: values,
			selected_user_ids: selected_user_ids,
			users: [],
			fetching: false,
		});
	};

	deleteQuestion = (question_id) => {
		questionsService.deleteQuestion(question_id)
			.then(() => {
				message.success("Successfully deleted question");
				this.fetchQuestions(true)
			})
			.catch(() => {
				message.error("Error in deleting question")
			})
	};

	showDeleteConfirmation = (question_id) => {
		confirm({
			icon: <ExclamationCircleOutlined />,
			content: "Are you sure you want to delete this question ?",
			onOk: () => {
				this.deleteQuestion(question_id)
			},
		});
	};


	onLoadMore = () => {
		this.fetchQuestions()
	};
	toggleModal = () => {
		this.setState({
			addQuestionVisible: !this.state.addQuestionVisible,
		});
	};


	render() {
		
		const { initLoading, loading, list, next_page_url } = this.state;
		const loadMore =
			next_page_url && !loading ? (
				<div
					style={{
						textAlign: 'center',
						marginTop: 12,
						height: 32,
						lineHeight: '32px',
					}}
				>
					<Button onClick={this.onLoadMore}>Load More</Button>
				</div>
			) : null;

		return (
				<>
					<Layout className="bg-gray-100">
						<Content className="bg-white m-10 p-5">
							<div className="flex mt-2 px-5 justify-between border-b-1 pb-2">
								<h3 className="text-center text-lg font-bold">Questions</h3>
								<div>
									<Link to={{
										pathname: "/bulk-upload/?exam=true",
										query:{show_exam: true}
									}}>
										<Button type="primary">Bulk Upload Questions</Button>
									</Link>
									<Link className="ml-3" to={{
										pathname: `/questions/add/?exam_slug=${this.state.exam_slug}`,
										query:{exam_slug: this.state.exam_slug}
									}}>
										<Button type="primary">Add New Question</Button>
									</Link>
									<Button className="ml-3" type="primary" onClick={this.toggleModal}>
										Add Existing Questions
        							</Button>	
									{ this.state.addQuestionVisible? <QuestionSearch toggleAddQuestionModal={this.toggleModal} examSlug={this.state.exam_slug} /> : null}
								</div>
							</div>
							<div className="px-5">
								<List
										itemLayout="horizontal"
										dataSource={list}
										loading={initLoading}
										loadMore={loadMore}
										renderItem={(question, index) => (
												<List.Item>
													<List.Item.Meta
															avatar={<Avatar style={{backgroundColor: '#16B7D3'}}>{index + 1}</Avatar>}
															title={<QuestionComponent classes="" question={question.question_html}/>}
															description={
																<>
																	{
																		question.answers.map((answer, index) => (
																				<div className="flex" key={answer.id}>
																					<Avatar
																							size="small"
																							style={answer.is_correct ? {backgroundColor: "#87d068"} : {backgroundColor: "#999"}}>
																						{index + 1}
																					</Avatar>
																					<p
																							className="ml-3 mb-0"
																							dangerouslySetInnerHTML={createMarkup(answer.text_html)}
																					>

																					</p>
																				</div>
																		))
																	}
																	{
																		question.explanation_html && <div className="mt-4">
																			<strong>Explanation Html : </strong> <br/>
																			<p
																					className="mb-0"
																					dangerouslySetInnerHTML={createMarkup(question.explanation_html)}
																			>

																			</p>
																		</div>
																	}
																	{
																		question.marks && <div className="mt-2">
																			<strong>Marks : </strong> {question.marks}
																		</div>
																	}
																	<div className="mt-3">
																		<Link to={`/questions/edit/${question.id}/`}>Edit question</Link>
																		<Button className="ml-3 text-red-500" type="text" danger onClick={() => {
																			this.showDeleteConfirmation(question.id)
																		}}>Delete question</Button>
																	</div>
																</>
															}
													/>
												</List.Item>
										)}
								/>
							</div>
						</Content>
					</Layout>
				</>
		);
	}
}

export default ExamQuestions;