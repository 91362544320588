import React from "react";
import {Spin} from "antd"

export function Loading() {
  return (
    <React.Fragment>
      <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
        <span
          className="text-blue-500 top-1/2 my-0 mx-auto block relative w-0 h-0"
          style={{ top: "50%" }}
        >
          <Spin size="large" />
        </span>
      </div>
      ;
    </React.Fragment>
  );
}

export default Loading;
