import { config, handleResponse } from "../helpers";

export const courseService = {
  getCourses
};

function getCourses(query) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };

  return fetch(`${config.apiUrl}/courses/?search=${query}`, requestOptions)
    .then(handleResponse)
    .then(courses => {
      return courses;
    });
}
