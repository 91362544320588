export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("AUTH_TOKEN");

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

export const config = {
  apiUrl:
    process.env.NODE_ENV === "development"
      ? "http://37.27.185.23:8000/api/v1"
      : "https://api.creativestack.dev/api/v1",
};

export function handleResponse(response) {
  return response.text().then((text) => {
    let data;
    try {
      data = text && JSON.parse(text);
    } catch (e) {
    }
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
