import React from "react";
import { withRouter } from "react-router-dom";

function SearchItem(props) {
  function getRoles() {
    const preferred_role = [];
    if (props.tutor.preferred_role === 1) {
      preferred_role.push("Tutor");
    }

    if (props.tutor.preferred_role === 2) {
      preferred_role.push("Teacher");
    }

    if (props.tutor.preferred_role === 3) {
      preferred_role.push("Tutor");
      preferred_role.push(", Teacher");
    }
    return preferred_role;
  }

  function onClick(evt) {
    props.history.push(`/tutor/${props.tutor.id}`);
  }

  const profile_photo_url = props.tutor.user.profile_photo_url
    ? props.tutor.user.profile_photo_url
    : "https://via.placeholder.com/150";
  return (
    <div onClick={onClick}>
      <div className="flex-shrink-0 m-6 relative overflow-hidden bg-white text-gray-600 rounded-lg max-w-xs shadow-lg">
        <img
          className="relative w-40"
          alt={props.tutor.user.display_name}
          src={profile_photo_url}
        />

        <div className="relative text-gray-600 px-6 pb-6 mt-6">
          <span className="block opacity-75 -mb-1">
            {props.tutor.subjects.map((subject) => subject.name)}
          </span>
          <div className="flex justify-between">
            <span className="block font-semibold text-xl">
              {props.tutor.user.username}
            </span>
          </div>
          {getRoles().map((role, index) => {
            return <span>{role}</span>;
          })}
        </div>
      </div>
    </div>
  );
}

export default withRouter(SearchItem);
