import React, {Component} from "react";
import queryString from 'query-string';

import {Button} from 'antd';

import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../components/Footer";
import Image from "../../assets/img/login_background.jpg";
import StudentSignUp from "../students/SignupPage"
import TutorSignUp from "../tutors/SignUpPage"
import InstituteSignUp from "../institute/SignUpPage"

class SignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'student'
    }
  }

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search)
    if (queryParams.type) {
      this.setState({type: queryParams.type})
    } else {
      this.setState({type: 'student'})
    }
  }

  renderSignupComponent = () => {
    switch (this.state.type) {
      case "tutor":
        return <TutorSignUp/>;
      case "institute":
        return <InstituteSignUp/>;
      default:
        return <StudentSignUp/>
    }
  }

  render() {
    if (this.props.loggedIn) return <Redirect to="/"/>;


    return (
      <>
        <div>
          <section id="sign-in" className="bglight position-relative padding">
            <div className="container">
              <div className="row justify-center" style={{marginRight: 0}}>
                <div
                  className="col-md-12 mb-16 text-center wow fadeIn"
                  data-wow-delay="300ms"
                  style={{
                    visibility: "visible",
                    animationDelay: "300ms",
                    animationName: "fadeIn",
                  }}
                >
                  <h2 className="heading bottom30 darkcolor font-light2">
                    <span className="font-normal">Sign</span> Up
                    <span className="divider-center"/>
                  </h2>
                  <Button type={this.state.type === "student" ? "primary" : "default"} onClick={() => {this.setState({type: 'student'}); this.renderSignupComponent()}} className="mr-3">
                    Student
                  </Button>
                  <Button type={this.state.type === "tutor" ? "primary" : "default"} onClick={() => {
                    this.setState({type: 'tutor'})
                    this.renderSignupComponent()
                  }} className="mr-3">
                    Tutor
                  </Button>
                  <Button type={this.state.type === "institute" ? "primary" : "default"} onClick={() => {
                    this.setState({type: 'institute'})
                    this.renderSignupComponent()
                  }} className="mr-3">
                    Institute
                  </Button>
                </div>
              </div>
              {this.renderSignupComponent()}
            </div>
          </section>
          <Footer/>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {loggedIn} = state.authentication;
  return {
    loggedIn,
  };
}

export default connect(mapStateToProps)(SignupPage);
