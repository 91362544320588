import React from "react";
import { Layout, Table } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { userService } from "../../services";

const { Column } = Table;
const { Content } = Layout;

export class StudentsListTable extends React.Component {
  constructor(props) {
    super();
    this.state = {
      students: {},
      visible: false,
      confirmLoading: false,
    };
  }

  componentDidMount() {
    this.getStudents();
  }

  getStudents = () => {
    userService.getStudents().then((res) => {
      this.setState({ students: res });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <h3>Students</h3>
          <Table
            dataSource={this.state.students.results}
            showSorterTooltip="true"
            pagination={{
              current: this.state.students.page,
              total: this.state.students.count,
              pageSize: 10,
            }}
            rowKey="id"
          >
            <Column
              title="Index"
              key="index"
              render={(value, item, index) =>
                (this.state.students.page - 1) * 10 + index + 1
              }
            />
            <Column title="Name" dataIndex="display_name" key="displayName" />
            <Column title="Class" dataIndex="class_detail" key="class_detail" />
            <Column title="City" dataIndex="city" key="city" />
            <Column title="State" dataIndex="state" key="state" />
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default StudentsListTable;
