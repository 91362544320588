export function addQueryParam(uri, queryParams) {
  if (!queryParams) return uri;
  let newUrl = uri;

  Object.keys(queryParams).forEach(function(key) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    console.log(key, queryParams[key]);
    var separator = newUrl.indexOf("?") !== -1 ? "&" : "?";

    if (newUrl.match(re)) {
      newUrl = newUrl.replace(re, "$1" + key + "=" + queryParams[key] + "$2");
    } else {
      newUrl = newUrl + separator + key + "=" + queryParams[key];
    }
  });
  return newUrl;
}
