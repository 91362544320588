import { Alert, Button, Input, List, Modal, Pagination, Skeleton } from "antd";
import React, { Component } from "react";
import { enquiryActions, jobActions } from "../../actions";

import { connect } from "react-redux";

class JobsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, description: "", job: {} };
  }

  submitEnquiry = e => {
    e.preventDefault();
    if (!this.state.description) {
      return;
    }

    const enquiry = {
      object_id: this.state.job.id,
      description: this.state.description,
      content_type: "jobs.Job"
    };
    this.props.dispatch(enquiryActions.sendEnquiry(enquiry));
  };

  componentDidMount() {
    this.props.dispatch(jobActions.getJobs());
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onPageChange = pageNumber => {
    this.props.dispatch(jobActions.getJobs(pageNumber));
  };

  render() {
    const { loading, jobs } = this.props;
    return (
      <div className="bg-gray-300 py-10">
        <div className="mx-10">
          <Modal
            title={`Apply for job`}
            visible={this.state.visible}
            confirmLoading={this.props.loading}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            onOk={this.submitEnquiry}
            okText="Submit"
          >
            {this.props.error ? (
              <Alert message={this.props.error} className="mb-2" type="error" />
            ) : null}
            {this.props.success ? (
              <h3>
                We have received your request. We will get back to you in 2 days
              </h3>
            ) : (
              <Input.TextArea
                rows={4}
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
                description="Enter brief description"
              />
            )}
          </Modal>
          <List
            className="demo-loadmore-list px-10 bg-white mx-10 my-10"
            loading={loading}
            itemLayout="horizontal"
            dataSource={jobs["results"]}
            renderItem={item => (
              <List.Item
                className="px-10"
                style={{ padding: "1rem" }}
                actions={[
                  <Button
                    key="list-loadmore-edit"
                    onClick={e => {
                      this.setState({ visible: true, job: item });
                    }}
                  >
                    Apply
                  </Button>
                ]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={<p>{item.role}</p>}
                    description={item.description}
                  />
                </Skeleton>
              </List.Item>
            )}
          />

          {jobs.results.length > 0 && (
            <Pagination
              style={{ marginTop: "2rem" }}
              className="text-center mt-5"
              defaultCurrent={jobs.page}
              total={jobs.count}
              pageSize={jobs.page_size}
              onChange={this.onPageChange}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { jobs, error, loading } = state.job;
  return {
    jobs,
    error,
    loading
  };
}

const connectedJobsPageComponent = connect(mapStateToProps)(JobsPage);
export default connectedJobsPageComponent;
