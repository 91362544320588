import { authHeader, config, handleResponse, addQueryParam } from "../helpers";

export const examService = {
  getExamDetails,
  getQuestions,
  startExam,
  submitAnswer,
  endExam,
  createExam,
  updateExam,
  deleteExam,
  getExams,
  getAttempts,
  getAttemptReview,
  getAttemptedAnswers,
  updateExamQuestions,
  getUpcomingExams,
  getCompletedExams,
};

function getExamDetails(slug) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/exams/${slug}/`, requestOptions)
    .then(handleResponse)
    .then((examDetails) => {
      return examDetails;
    });
}

function getQuestions(slug, url = null, queryParams = {}) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  let questionUrl = url || `${config.apiUrl}/exams/${slug}/questions/`;
  questionUrl = addQueryParam(questionUrl, queryParams);

  return fetch(questionUrl, requestOptions)
    .then(handleResponse)
    .then((examQuestions) => {
      return examQuestions;
    });
}

function startExam(slug) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/exams/${slug}/start/`, requestOptions)
    .then(handleResponse)
    .then((attempt) => {
      return attempt;
    });
}

function submitAnswer(attemptId, examQuestionid, selectedAnswers) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify({ selected_answers: selectedAnswers }),
  };

  return fetch(
    `${config.apiUrl}/attempts/${attemptId}/questions/${examQuestionid}/`,
    requestOptions
  )
    .then(handleResponse)
    .then((userSelectedAnswer) => {
      return userSelectedAnswer;
    });
}

function endExam(attemptId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/attempts/${attemptId}/end/`, requestOptions)
    .then(handleResponse)
    .then((attempt) => {
      return attempt;
    });
}

function createExam(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/exams/`, requestOptions)
    .then(handleResponse)
    .then((exam) => {
      return exam;
    });
}

function getExams(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };
  let url = `${config.apiUrl}/exams/`;
  url = addQueryParam(url, queryParams);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((exams) => {
      return exams;
    });
}

function getUpcomingExams(queryParams, url = null) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };
  let examsUrl = url || `${config.apiUrl}/exams/upcoming/`;

  return fetch(examsUrl, requestOptions)
    .then(handleResponse)
    .then((exams) => {
      return exams;
    });
}

function getCompletedExams(queryParams, url = null) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };
  let examsUrl = url || `${config.apiUrl}/exams/history/`;

  return fetch(examsUrl, requestOptions)
    .then(handleResponse)
    .then((exams) => {
      return exams;
    });
}

function deleteExam(slug) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  return fetch(`${config.apiUrl}/exams/${slug}/`, requestOptions)
    .then(handleResponse)
    .then((exams) => {
      return exams;
    });
}

function updateExam(body, slug) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/exams/${slug}/`, requestOptions)
    .then(handleResponse)
    .then((exam) => {
      return exam;
    });
}

function getAttempts(examSlug, pageNumber = 1) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  let url = `${config.apiUrl}/exams/${examSlug}/attempts/`;
  url = addQueryParam(url, pageNumber);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((attempts) => {
      return attempts;
    });
}

function getAttemptReview(attemptId, url = null) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  let questionUrl = url || `${config.apiUrl}/attempts/${attemptId}/review`;

  return fetch(questionUrl, requestOptions)
    .then(handleResponse)
    .then((attemptStat) => {
      return attemptStat;
    });
}

function getAttemptedAnswers(attemptId, url = null) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };

  let questionUrl =
    url || `${config.apiUrl}/attempts/${attemptId}/attempted_answers`;

  return fetch(questionUrl, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateExamQuestions(body, slug) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/exams/${slug}/questions/add/`, requestOptions)
    .then(handleResponse)
    .then((exam) => {
      return exam;
    });
}
