import { enquiryConstants } from "../constants";
import { enquiryService } from "../services";

export const enquiryActions = {
  sendEnquiry,
  getEnquiries
};

function sendEnquiry(enquiry) {
  return dispatch => {
    dispatch(request());

    enquiryService.sendEnquiry(enquiry).then(
      enquiry => {
        dispatch(success(enquiry));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.REQUEST };
  }

  function success(message) {
    return { type: enquiryConstants.SUCCESS, message };
  }

  function failure(message) {
    return { type: enquiryConstants.ERROR, message };
  }
}

function getEnquiries(queryParams) {
  return dispatch => {
    dispatch(request());

    enquiryService.getEnquiries(queryParams).then(
      enquiries => {
        dispatch(success(enquiries));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.GET_ENQUIRIES_REQUEST };
  }

  function success(enquiries) {
    return { type: enquiryConstants.GET_ENQUIRIES_SUCCESS, enquiries };
  }

  function failure(error) {
    return { type: enquiryConstants.GET_ENQUIRIES_FAILURE, error };
  }
}
