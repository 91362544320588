import useDebounce from "./useDebouncedSearch";
import React, { useState, useEffect } from 'react';
import { Input, Checkbox, List, Form, Select, Modal, message, Button } from 'antd';
import { examService, questionSetService, questionsService } from "../../services";
import { createMarkup } from "../../helpers";

const { Search } = Input;
const { Option } = Select;


const QuestionSearch = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [visible, setVisible] = useState(true);
  const [questionSet, setQuestionSet] = useState([]);
  const [question_ids, setQuestions] = useState([]);
  const [questionFolder, setQuestionFolder] = useState("All");
  // State for search status (whether there is a pending API request)
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm) {
        // Set isSearching state
        setIsSearching(true);
        //question_set
        let reqObj = {};
        if(questionFolder==="All")
        {
          reqObj = { "search": debouncedSearchTerm };
        }
        else
        {
          reqObj = { "search": debouncedSearchTerm, "question_set": questionFolder}
        }  
        questionsService.getQuestions(reqObj).then(res => {
          // Set back to false since request finished
          setIsSearching(false);
          // Set results state
          setResults(res.results);
        });
      } else {
        setResults([]);
      }
    },
    [debouncedSearchTerm,questionFolder]
  );

  useEffect(
    () => {            
      questionSetService.getQuestionFolders({"page": 1})
			.then((response) => {				
        setQuestionSet(response.results);        
			})
			.catch(() => {console.error("Failed to fetch questions")})
    },[]
  )

	const handleOk = e => {  
    
    examService.updateExamQuestions({question_ids},props.examSlug)
    .then(res => message.success("New questions added."))
    .catch(err => message.error("Can't add questions to the exam"));
    
	}

  const handleCancel = e => {
    props.toggleAddQuestionModal();
    setVisible(false);
  }
  const handleSelect = e => {
    let tmpAry = question_ids;
   if(e.target.checked)
   {  
    tmpAry.push(e.target.value);
    setQuestionSet(tmpAry);    
   }
   else
   {
     tmpAry.pop(tmpAry.indexOf(e.target.value));
     setQuestionSet(tmpAry);
   }
   console.log(question_ids);
  }

  return (
   
    <div>
      	<Modal		
						title="Add Questions"	
						okText="Add"				
							visible={visible}							
              onCancel={handleCancel}
              width={1200}   
              footer={null}       
						>	
      <Form className="flex">
      <Form.Item>
      <label className="top-label">KEYWORD</label>
      <Search placeholder="type search text" size="middle" onChange={e => setSearchTerm(e.target.value)} />
      </Form.Item>
        <Form.Item style={{paddingLeft:"10px"}}>
          <label className="top-label">QUESTION FOLDER</label>
          <Select defaultValue="All" onChange={val => setQuestionFolder(val)}>
          <Option value="All">All</Option>
            {              
              questionSet.map(qs => <Option value={qs.slug}>{qs.name}</Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item style={{padding:"20px"}}>
        <Button type="primary" onClick={handleOk}>Add Question</Button>
          </Form.Item>
      </Form>
      {isSearching && <div>Searching ...</div>}
      <List calssName="mt-3 pl-3">
        {
          results.map(q =>  <List.Item className="pl-3" style={{backgroundColor:"#f8f8f8", paddingLeft: '20px'}}>
            <List.Item.Meta
              avatar={<Checkbox onChange={handleSelect} value={q.id}></Checkbox>}
              title={<span dangerouslySetInnerHTML={createMarkup(q.question_html)}></span>}
              //description="Ant Design, a design language for background applications, is refined by Ant UED Team"
            />
          </List.Item>)
        }
      </List>
      </Modal>
    </div>
  );
};

export default QuestionSearch;