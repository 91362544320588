import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  List,
  Layout,
  Input,
  Card,
  Modal,
  Rate,
  Button,
  Row,
  Col,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { reviewService } from "../../../services";
import { classDetailService } from "../../../services";
import Avatar from "../../../components/Avatar";

const { Content } = Layout;
const { TextArea } = Input;
const desc = ["Terrible", "Bad", "Normal", "Good", "Excellent"];

class ClassReview extends Component {
  constructor(props) {
    super();
    this.state = {
      reviews: {},
      classes: {},
      visible: false,
      reviewContent: null,
      currRating: 0,
    };
  }

  componentDidMount() {
    this.fetchReviews();
    classDetailService
      .getClassDetails()
      .then((res) => this.setState({ classes: res }));
  }

  fetchReviews = () => {
    reviewService.getReviews(1).then((res) => this.setState({ reviews: res }));
  };

  showReviewModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    var review = {
      content: this.state.reviewContent,
      object_id: 2,
      average_rating: 5,
      content_type: 7,
      parent_id: null,
    };
    reviewService.addReview(review).then((res) => {
      this.fetchReviews();
      this.setState({
        visible: false,
        reviewContent: "",
        currRating: 0,
      });
      message.success("Review Added");
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      reviewContent: "",
      currRating: 0,
    });
  };

  handleChange = (value) => {
    this.setState({ currRating: value });
  };

  render() {
    return (
      <Layout>
        <div className="flex flex-wrap -mx-px overflow-hidden lg:-mx-3 xl:-mx-3">
          <div className="my-px px-px w-full md:w-1/3 lg:my-3 lg:px-3 lg:w-1/3 xl:my-3 xl:px-3">
            <div>
              <Card style={{ textAlign: "center" }}>
                {
                  <div>
                    <Avatar
                      user={this.props.user}
                      size="large"
                      className="mr-2"
                    />
                    <h4>{this.props.user.display_name}</h4>
                    <h6>{this.props.user.class_detail}</h6>
                  </div>
                }
              </Card>
            </div>
            <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <Card title="Completed Classes">
                {this.state.classes.length === 0 ? (
                  "No Classes Found"
                ) : (
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.classes.results}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              user={item.tutor.user}
                              size="large"
                              className="mr-2"
                            />
                          }
                          title={item.title}
                          description={
                            <Button
                              class="primary"
                              onClick={() => this.showReviewModal()}
                            >
                              Review
                            </Button>
                          }
                        />
                      </List.Item>
                    )}
                  />
                )}
              </Card>
            </div>
          </div>
          <div className="my-px px-px w-full md:w-2/3 lg:my-3 lg:px-3 lg:w-2/3 xl:my-3 xl:px-3">
            <div className="h-full">
              <Card
                className="h-full"
                style={{ maxHeight: "550px", overflowY: "scroll" }}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.reviews.results}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            user={item.user}
                            size="large"
                            className="mr-2"
                          />
                        }
                        title={item.user.display_name}
                        description={item.content}
                      />
                      <Rate
                        tooltips={desc}
                        disabled
                        defaultValue={0}
                        value={item.average_rating}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </div>
          </div>
        </div>

        <Row>
          <Modal
            title="Write Review"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okText="Save"
            cancelText="Close"
          >
            <TextArea
              rows={3}
              placeholder="Write your reviews here"
              value={this.state.reviewContent}
              onChange={(e) => this.setState({ reviewContent: e.target.value })}
            />
            Rating{" "}
            <Rate
              tooltips={desc}
              onChange={this.handleChange}
              value={this.state.currRating}
            />
          </Modal>
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

const connectedClassReview = connect(mapStateToProps)(withRouter(ClassReview));
export default connectedClassReview;
