import React, {Component} from 'react';
import {Avatar, Button, Layout, List, message, Modal, Select, Spin} from "antd";
import {batchService, userService} from "../../services";
import {ExclamationCircleOutlined} from '@ant-design/icons';

const {Content} = Layout;
const {Option} = Select;
const { confirm } = Modal;

class BatchUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initLoading: true,
			loading: false,
			data: [],
			list: [],
			page: 0,
			total_page: 1,
			batch_id: this.props.match.params.id,
			users: [],
			selected_users: [],
			selected_user_ids: [],
			fetching: false
		};
	}


	componentDidMount() {
		this.fetchBatchUsers()
	}

	fetchBatchUsers = (clear_existing=false) => {
		this.setState({
			loading: true,
		});
		if (clear_existing) {
			this.setState({
				list: [],
				page: 0
			})
		}
		const queryParams = {page: this.state.page + 1}
		batchService.getBatchUsers(this.state.batch_id, queryParams)
				.then((response) => {
					this.setState(prevState => ({
						list: [...prevState.list, ...response["results"]],
						initLoading: false,
						loading: false,
						page: response["page"],
						total_pages: response["total_pages"]
					}));
				})
	};

	fetchUsers = (value) => {
		this.setState({fetching: true});
		userService.getAll({search: value})
				.then(users => {
					let results = users["results"]
					this.setState({users: results, fetching: false});
				})
	};


	handleUsersChange = values => {
		let selected_user_ids = values.map(user => parseInt(user.value))

		this.setState({
			selected_users: values,
			selected_user_ids: selected_user_ids,
			users: [],
			fetching: false,
		});
	};

	addUsersToBatch = () => {
		console.log("Selexted users : ", this.state.selected_user_ids)
		batchService.addUsersToBatch(this.state.batch_id, this.state.selected_user_ids)
				.then(response => {
					this.setState({visible: false, page: 0}, () => {
						this.fetchBatchUsers(true)
					});
					message.success("Successfully added users to batch")
				})
				.catch((error) => {
					message.error("Error added users to batch. Please try again.")
				})
	};

	removeUsersToBatch = (user_id) => {
		batchService.removeUsersFromBatch(this.state.batch_id, [user_id])
				.then(() => {
					message.success("Successfully deleted batch")
					this.fetchBatchUsers(true)
				})
				.catch(() => {
					message.error("Error in deleting batch")
				})
	}

	showUserRemovalConfirmation = (user_id) => {
		confirm({
			icon: <ExclamationCircleOutlined />,
			content: "Are you sure you want to delete this batch ?",
			onOk: () => {
				this.removeUsersToBatch(user_id)
			},
		});
	}

	renderModal = () => {
		return <Modal
				title={"Add Users"}
				visible={this.state.visible}
				confirmLoading={this.props.loading}
				onCancel={() => {
					this.setState({ visible: false });
				}}
				onOk={this.addUsersToBatch}
				okButtonProps={{ disabled: this.props.success === true }}
				okText="Submit"
		>
			<Select
					mode="multiple"
					name="users"
					labelInValue
					value={this.state.selected_users}
					placeholder="Type to search users"
					notFoundContent={this.state.fetching ? <Spin size="small"/> : null}
					filterOption={false}
					onSearch={this.fetchUsers}
					onChange={this.handleUsersChange}
					style={{width: '100%'}}
					size="medium"
					showArrow={true}
			>
				{this.state.users.map(d => (
						<Option key={d.id}>{d.display_name}</Option>
				))}
			</Select>
		</Modal>
	}

	openAddUserModal = () => {
		this.setState({selected_users: [], visible: true})
	}

	onLoadMore = () => {
		this.fetchBatchUsers()
	};

	render() {
		const {initLoading, loading, list, total_pages, page} = this.state;
		const loadMore =
				(page < total_pages) && !loading ? (
						<div
								style={{
									textAlign: 'center',
									marginTop: 12,
									height: 32,
									lineHeight: '32px',
								}}
						>
							<Button onClick={this.onLoadMore}>Load More</Button>
						</div>
				) : null;

		return (
				<>
					<Layout className="bg-gray-100">
						<Content className="bg-white m-10 p-5">
							{this.renderModal()}

							<div className="flex mt-2 px-5 justify-between border-b-1 pb-2">
								<h3 className="text-center text-lg font-bold">Batches</h3>
								<Button type="primary" onClick={this.openAddUserModal}>Add User</Button>
							</div>
							<div className="px-5">
					<List
							itemLayout="horizontal"
							dataSource={list}
							loading={initLoading}
							loadMore={loadMore}
							renderItem={(item, index) => (
									<List.Item actions={[
										<Button type="danger" onClick={() => {this.showUserRemovalConfirmation(item.id)}}>Remove</Button>
									]}>
										<List.Item.Meta
												avatar={<Avatar style={{backgroundColor: '#16B7D3'}}>{index + 1}</Avatar>}
												title={<span>{item.display_name}</span>}
												description={<span>{item.email}</span>}
										/>
									</List.Item>
							)}
					/>
							</div>
						</Content>
					</Layout>
				</>
		);
	}
}

export default BatchUsers;