import React from "react";
import dayjs from "dayjs";
import { Button, List } from "antd";
import { Link } from "react-router-dom";
import Avatar from "../../../components/Avatar";

var relativeTime = require("dayjs/plugin/relativeTime");
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const MiniCommentsComponent = ({ comments, is_tutor = false }) => {
  return (
    <div className="bg-white p-3">
      {is_tutor && (
        <List
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar user={item.user} size="large" className="mr-2" />
                }
                title={
                  <div className="flex justify-between">
                    <h4>
                      <strong>{item.user.display_name}</strong> posted a comment
                    </h4>
                    <div className="text-xs">
                      {dayjs(item.created).fromNow()}
                    </div>
                  </div>
                }
                description={
                  <div>
                    <div className="text-xs">
                      {dayjs(item.created).format("lll")}
                    </div>
                    <div className="border border-gray-300 px-2 py-2 mt-2">
                      {item.content}
                    </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )}
      {!is_tutor && (
        <List
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title={
                  <div className="flex justify-between">
                    <h4>
                      Your comment about{" "}
                      <strong>{item.user.display_name}</strong>
                    </h4>
                    <div className="text-xs">
                      {dayjs(item.created).fromNow()}
                    </div>
                  </div>
                }
                description={
                  <div>
                    <div className="text-xs">
                      {dayjs(item.created).format("lll")}
                    </div>
                    <div className="border border-gray-300 px-2 py-2 mt-2">
                      {item.content}
                    </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )}
      <Link to="/reviews">
        <Button type="primary" block>
          Load More
        </Button>
      </Link>
    </div>
  );
};

export default MiniCommentsComponent;
