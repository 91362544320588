import {addQueryParam, authHeader, config, handleResponse} from "../helpers";


export const batchService = {
	getBatches, createBatch, deleteBatch, updateBatch, getBatchUsers,
	addUsersToBatch, removeUsersFromBatch
};

function getBatches(queryParams) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		}
	};
	let url = `${config.apiUrl}/batches/`
	url = addQueryParam(url, queryParams);

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(batches => {
				return batches;
			});
}


function createBatch(batchName) {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify({"name": batchName})
	};
	let url = `${config.apiUrl}/batches/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(batch => {
				return batch;
			});
}

function deleteBatch(batchId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
	};
	let url = `${config.apiUrl}/batches/${batchId}`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(batch => {
				return batch;
			});
}


function updateBatch(batchId, batchName) {
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify({"name": batchName})
	};
	let url = `${config.apiUrl}/batches/${batchId}/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(batch => {
				return batch;
			});
}

function getBatchUsers(batchId, queryParams) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
	};
	let url = `${config.apiUrl}/batches/${batchId}/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(users => {
				return users;
			});
}

function addUsersToBatch(batchId, user_ids) {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify({"user_ids": user_ids})
	};
	let url = `${config.apiUrl}/batches/${batchId}/add_users/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(batch => {
				return batch;
			});
}

function removeUsersFromBatch(batchId, user_ids) {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify({"user_ids": user_ids})
	};
	let url = `${config.apiUrl}/batches/${batchId}/remove_users/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(batch => {
				return batch;
			});
}