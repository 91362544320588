import { activityConstants } from "../constants";

let initialState = {
  error: null,
  loading: false,
  success: false
};

export function activities(state = initialState, action) {
  switch (action.type) {
    case activityConstants.REQUEST:
      return {
        ...state,
        loading: true
      };
    case activityConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.activity,
        success: true
      };
    case activityConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: action.message
      };
    default:
      return state;
  }
}
