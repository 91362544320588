import {Button, Card, Layout, List, Table} from 'antd';
import React, {Component} from "react";
import {examService} from "../../services/exam.service";

const { Content } = Layout;
const { Column, ColumnGroup } = Table;
export default class UserStat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            totalCorrect: 0,
            totalScore: 0,
            next_page_url: null,
            initLoading: false
        };
    }

    componentDidMount() {
        const { uid } = this.props.match.params;
        this.setState({loading: true, initLoading: true})
        examService.getAttemptReview(uid).then(res => {
            this.combineResult(res);
            this.setState({loading: false, initLoading: false, next_page_url: res["next"]})
        });
    }

    combineResult(res) {
        var tmpQuestions = res.results.questions;
        tmpQuestions.forEach(question => {
            res.results.user_selected_answers.forEach(ans => {
                if (question.id === ans.question_id) {
                    question.result = ans.result;
                    question.answers.forEach(opt => {
                        if (ans.selected_answers.includes(opt.id)) {
                            opt.is_selected = true;
                        }
                    }
                    )
                }
            })
        });
        this.setState(prevState => ({
            questions: [...prevState.questions, ...tmpQuestions],
        }));
        res.results.user_selected_answers.forEach(ans => {
            if (ans.result === "Correct")
                this.setState({ totalCorrect: this.state.totalCorrect + 1 });
            this.setState({ totalScore: this.state.totalScore + parseFloat(ans.marks) });
        })

    }

    onLoadMore = () => {
        this.setState({loading: true})
        examService.getAttemptReview(null, this.state.next_page_url).then(res => {
            this.combineResult(res);
            this.setState({loading: false})
            this.setState({next_page_url: res["next"]});
        });
    }

    render() {
        const {loading, initLoading, next_page_url} = this.state;
        const loadMore =
            next_page_url && !loading ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button onClick={this.onLoadMore}>Load More</Button>
                </div>
            ) : null;


        const data = [
            {
                Stats: "Total Questions",
                Numbers: this.state.questions.length
            },
            {
                Stats: "Correct Answers",
                Numbers: this.state.totalCorrect
            },
            {
                Stats: "Wrong Answers",
                Numbers: this.state.questions.length - this.state.totalCorrect
            },
            {
                Stats: "Score",
                Numbers: this.state.totalScore
            },
            {
                Stats: "Percentage",
                Numbers: ((this.state.totalCorrect / this.state.questions.length) * 100) + "%"
            },
        ]
        return (
            <Layout className="bg-gray-100">

                <Card title="Statistics" headStyle={{ fontSize: "20px" }} style={{ width: 1100 }}>
                    <Table dataSource={data} pagination={{ hideOnSinglePage: true }} bordered style={{ width: 300, marginLeft: 350 }}>
                        <Column title="Stats" dataIndex="Stats" />
                        <Column title="" align="center" dataIndex="Numbers" />
                    </Table>
                    <br />
                    <h4><b>Your Answers</b></h4>
                    <List
                        loadMore={loadMore}
                        loading={initLoading}
                        itemLayout="horizontal">
                        {
                            this.state.questions.map((question, index) =>
                                <React.Fragment> <List.Item className="mt-3 ml-1" >
                                    <List
                                    >
                                        <div className="flex" dangerouslySetInnerHTML={{__html: (++index) + ") " + question.question_html}}></div>
                                        {
                                            question.answers.map((ans, index) =>
                                                <List.Item className="ml-1" style={{borderBottom:"none"}}>
                                                    <span className="option-circle" style={ans.is_selected ? { backgroundColor: "#bd362f", color: "white" } : {}}>{++index}</span> <span className="ml-1">{ans.text_html.replace("<p>", "").replace("</p>", "")}</span>
                                                </List.Item>
                                            )
                                        }
                                        <div className="ml-1 flex">
                                            <strong>Solution:</strong>
                                            <span className="ml-1 flex" dangerouslySetInnerHTML={{__html:question.answers.filter(ans => ans.is_correct).map(ans => ans.text_html).join(",")}}>
                                            </span>
                                        </div>                                        
                                        <div className="ml-1 " dangerouslySetInnerHTML={{__html:question.explanation_html}}></div>
                                    </List>
                                </List.Item>
                                </React.Fragment>)
                        }

                    </List>
                </Card>

            </Layout>

        );
    }
}