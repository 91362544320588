import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, Carousel, Empty, List } from "antd";
import { liveClassActions } from "../../../actions";
import dayjs from "dayjs";
import { classDetailService } from "../../../services";

const contentStyle = {
  background: "#364d79",
};

const startLiveClass = (id) => {
  classDetailService.getStartUrl(id).then((live_class) => {
    window.open(live_class.start_url);
  });
};

class UpcomingClassesCarousel extends Component {
  componentDidMount() {
    this.props.dispatch(liveClassActions.getMyLiveClasses());
  }

  render() {
    return (
      <React.Fragment>
        <h3>Upcoming Classes</h3>
        {this.props.liveClasses.results.length > 0 && (
          <Carousel className="mt-2">
            {this.props.liveClasses.results
              .filter((item) => !item.is_completed)
              .map((item, index) => {
                return (
                  <div className="bg-gray-500 p-4">
                    <Card title={item.name}>
                      <p>{item.description}</p>
                      <p>
                        <strong>Start time : </strong>
                        &nbsp;{" "}
                        {dayjs(item.start_time).format("DD/MM/YYYY hh:mm a")}
                      </p>
                      <p>
                        <strong>Duration : </strong>
                        &nbsp; {item.duration}
                        {item.duration > 1 ? "Minutes" : "Minute"}
                      </p>
                      <p>
                        <strong>Tutor : </strong>
                        &nbsp; {item.created_by}
                      </p>
                      <Button
                        type="primary"
                        onClick={() => startLiveClass(item.id)}
                        htmlType="submit"
                      >
                        Start Class
                      </Button>
                    </Card>
                  </div>
                );
              })}
          </Carousel>
        )}
        {this.props.liveClasses.results.length === 0 && (
          <Empty
            description={<h3>You do not have any live classes Scheduled.</h3>}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { liveClasses, error, loading } = state.myLiveClasses;
  return {
    liveClasses,
    error,
    loading,
  };
}

export default connect(mapStateToProps)(UpcomingClassesCarousel);
