import React from "react";

import { Layout, Menu } from "antd";

import Logo from "../../assets/img/logo_white.svg";
import { Link, withRouter } from "react-router-dom";
import Avatar from "../Avatar";
import { connect } from "react-redux";

const { SubMenu } = Menu;
const { Sider } = Layout;

class Sidebar extends React.Component {
  render() {
    return (
      <Sider
        breakpoint="xs"
        width="260px"
        collapsedWidth="0"
        trigger={null}
        onAfterChange={(e) => console.log(e)}
        collapsible
        collapsed={this.props.collapse}
      >
        <div className="logo">
          <a href="/">
            <img className="w-48 h-auto" src={Logo} alt="Sprucedutech logo" />
          </a>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[this.props.location.pathname]}
        >
          <Menu.Item
            key="/"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/">
              <span className="align-middle">Dashboard</span>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="/tutors"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/tutors">
              <span className="align-middle">Request Teacher</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/jobs"
            icon={
              <span>
                <svg
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/jobs">
              <span className="align-middle">Jobs</span>
            </Link>
          </Menu.Item>
        </Menu>
        <div
          className="flex mt-auto text-white p-4 items-center"
          style={{ background: "#222a37" }}
        >
          <div className="mr-4">
            <Avatar user={this.props.user} size="large" className="mr-2" />
          </div>
          <div className="text-white">
            <h4 className="text-white font-medium text-lg">
              {this.props.user.display_name}
            </h4>
            <div className="text-gray-300 my-0 flex items-baseline">
              <span className="bg-green-500 h-3 w-3 mr-2 rounded-full" />
              <span>Online</span>
            </div>
          </div>
        </div>
      </Sider>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withRouter(Sidebar));
