import { userConstants } from "../constants";

let initialState = {
  error: null,
  user: {},
  loading: false,
  updateProfileLoading: false,
  updateProfileError: null,
  updateProfileSuccess: false
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        error: null
      };
    case userConstants.GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfileLoading: true,
        updateProfileSuccess: false
      };
    case userConstants.UPDATE_PROFILE_SUCESS:
      return {
        ...state,
        user: action.profileDetails,
        updateProfileError: null,
        updateProfileLoading: false,
        updateProfileSuccess: true
      };
    case userConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileError: action.error,
        updateProfileLoading: false,
        updateProfileSuccess: false
      };
    default:
      return state;
  }
}
