import React, { Component } from "react";
import { examService } from "../../services";
import { Link } from "react-router-dom";
import { Table, Space, Layout, Input, Card, message } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Column } = Table;
const { Content } = Layout;
const { Search } = Input;

class ExamDashboard extends Component {
  constructor(props) {
    super();
    this.state = {
      exams: {},
    };
  }
  componentDidMount() {
    this.getExamList();
  }
  getExamList = () => {
    examService.getExams().then((res) => {
      this.setState({ exams: res });
      console.log(this.state.exams);
    });
  };
  deleteExam = (slug) => {
    examService
      .deleteExam(slug)
      .then((res) => {
        message.success("exam deleted!");
      })
      .catch((err) => message.error("can't delete exam"));
  };

  render() {
    return (
      <Layout className="bg-gray-100">
        <Content className="m-5 p-3">
          <h3>Exams Board</h3>
          <Card>
            <Search
              placeholder="search text"
              size="middle"
              style={{ width: "30%", marginLeft: "70%", marginBottom: "1%" }}
            />
            <Table
              dataSource={this.state.exams.results}
              showSorterTooltip="true"
              pagination={{
                current: this.state.exams.page,
                total: this.state.exams.count,
                pageSize: 10,
              }}
              rowKey="id"
            >
              <Column title="Id" dataIndex={"id"} key="displayName" />
              <Column title="Title" dataIndex={"title"} key="displayName" />
              <Column
                title="Start Date"
                dataIndex={"start_date"}
                key="date"
                render={(date) => dayjs(date).format("DD/MM/YYYY hh:mm A")}
              />
              <Column
                title="End Date"
                dataIndex={"end_date"}
                key="date"
                render={(date) =>
                  date == null ? "-" : dayjs(date).format("DD/MM/YYYY hh:mm A")
                }
              />
              <Column
                title="Duration"
                dataIndex={"duration"}
                key="displayName"
              />
              <Column
                title="No of Questions"
                dataIndex={"number_of_questions"}
                key="displayName"
              />
              <Column
                title="Action"
                key="action"
                render={(exam) => (
                  <Space size="middle">
                    <Link to={"/exams/" + exam.slug + "/questions"}>
                      <EyeOutlined />
                    </Link>
                    <Link to={"/exams/" + exam.slug}>
                      <EditOutlined />
                    </Link>
                    <Link
                      to="/examdashboard"
                      onClick={() => this.deleteExam(exam.slug)}
                    >
                      <DeleteOutlined />
                    </Link>
                  </Space>
                )}
              />
            </Table>
          </Card>
        </Content>
      </Layout>
    );
  }
}

export default ExamDashboard;
