import { jobConstants } from "../constants";

let initialState = {
  error: null,
  jobs: { results: [] },
  loading: false,
  success: false,
  create_job_loading: false,
  create_job_success: false,
  create_job_error: false,
  update_job_loading: false,
  update_job_success: false,
  update_job_error: false,
  delete_job_loading: false,
  delete_job_success: false,
  delete_job_error: false
};

export function job(state = initialState, action) {
  switch (action.type) {
    case jobConstants.JOBS_LIST_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.JOBS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.jobs,
        success: true,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.JOBS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.message,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.CREATE_JOB_REQUEST:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: true,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.CREATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: true,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.CREATE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: true,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.UPDATE_JOB_REQUEST:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: true,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: true,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.UPDATE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: true,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.DELETE_JOB_REQUEST:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: true,
        delete_job_success: false,
        delete_job_error: false
      };
    case jobConstants.DELETE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: true,
        delete_job_error: false
      };
    case jobConstants.DELETE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        create_job_loading: false,
        create_job_success: false,
        create_job_error: false,
        update_job_loading: false,
        update_job_success: false,
        update_job_error: false,
        delete_job_loading: false,
        delete_job_success: false,
        delete_job_error: true
      };
    default:
      return state;
  }
}
