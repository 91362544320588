import {
  Layout,
  Empty,
  Tag,
  Button,
  List,
  Card,
  Modal,
  message,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { examActions } from "../../actions";
import { examService } from "../../services";
import Loading from "../../components/Loading";
import { PieChartTwoTone } from "@ant-design/icons";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined, MailOutlined } from "@ant-design/icons";
import { strFormat } from "../../helpers";
import { examTemplate } from "./emailTemplates";

const { Content } = Layout;

const ExamsListCards = ({ exams, dispatch, history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [examToDelete, setExamToDelete] = useState(null);

  const handleOk = () => {
    if (!examToDelete) {
      return;
    }
    setConfirmLoading(true);

    examService.deleteExam(examToDelete).then(() => {
      setIsModalVisible(false);
      setConfirmLoading(false);
      dispatch(examActions.getExams());
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getTag = (exam) => {
    if (exam.is_public) {
      return <Tag color="blue">Public</Tag>;
    }
    return;
  };

  const getExtras = (exam) => {
    const url = `/exams/${exam.slug}/attempts`;
    return (
      <React.Fragment>
        {getTag(exam)}
        <Link to={url} className="mr-2">
          <PieChartTwoTone className="text-lg" />
        </Link>
        <Link
          to={{
            pathname: "/send-email",
            data: {
              subject:
                "SprucEdutech: Complete the Exam to proceed to the next round",
              content: strFormat(
                examTemplate,
                `https://app.sprucedutech.com/exam/${exam.slug}`
              ),
            },
          }}
          className="mr-2"
        >
          <MailOutlined />
        </Link>
        <CopyToClipboard
          text={`https://app.sprucedutech.com/exam/${exam.slug}`}
          onCopy={() => {
            message.info("Link copied to clipboard.");
          }}
        >
          <Tooltip title="Click to copy">
            {" "}
            <CopyOutlined />
          </Tooltip>
        </CopyToClipboard>
      </React.Fragment>
    );
  };

  return (
    <>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={exams}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Card title={item.title} extra={getExtras(item)}>
              <p>{item.description}</p>
              <p>
                <strong>Start Date : </strong>
                &nbsp; {dayjs(item.start_date).format("DD/MM/YYYY hh:mm a")}
              </p>

              {item.end_date && (
                <p>
                  <strong>End Date : </strong>
                  &nbsp; {dayjs(item.end_date).format("DD/MM/YYYY hh:mm a")}
                </p>
              )}

              <p>
                <strong>Duration : </strong>
                &nbsp; {item.duration}
              </p>
              <p>
                <strong>Number of Questions : </strong>
                &nbsp; {item.number_of_questions}
              </p>
              <br />
              <Button
                onClick={() => {
                  history.push(`/exams/${item.slug}`);
                }}
                type="default"
              >
                Edit
              </Button>
              <Button
                onClick={() => {
                  setIsModalVisible(true);
                  setExamToDelete(item.slug);
                }}
                className="ml-3"
                type="default"
                danger
              >
                Delete
              </Button>
              <Link to={`/exams/${item.slug}/questions`}>
                <Button className="ml-3" type="primary">
                  Questions
                </Button>{" "}
              </Link>
            </Card>
          </List.Item>
        )}
      />
      <Modal
        title="Delete"
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete the exam ?</p>
      </Modal>
    </>
  );
};

class ExamsList extends Component {
  componentDidMount() {
    this.props.dispatch(examActions.getExams());
  }

  renderDataOrEmptyPage = () => {
    if (this.props.exams.results.length === 0 || this.props.error) {
      return (
        <Empty description="You do not have any exams.">
          <Link to="/create-exam">
            <Button type="primary">Create Now</Button>
          </Link>
        </Empty>
      );
    }
    return (
      <ExamsListCards
        dispatch={this.props.dispatch}
        exams={this.props.exams.results}
        history={this.props.history}
      />
    );
  };

  render() {
    const { loading } = this.props;

    return (
      <>
        <Layout>
          <Content>
            <div className="flex mt-2 justify-between border-b-1 pb-2">
              <h3 className="text-left text-2xl font-bold">Exams</h3>
              <Link to="/create-exam">
                <Button type="primary">Create Now</Button>
              </Link>
            </div>
            {loading ? <Loading /> : this.renderDataOrEmptyPage()}
          </Content>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { exams, error, loading } = state.exams;
  return {
    exams,
    error,
    loading,
  };
}

const connectedExamsListComponent = connect(mapStateToProps)(ExamsList);
export default connectedExamsListComponent;
