import { classDetailConstants } from "../constants";
import { classDetailService } from "../services";

export const classDetailActions = {
  getClassDetails
};

function getClassDetails() {
  return dispatch => {
    dispatch(request());

    classDetailService.getClassDetails().then(
      classDetails => {
        dispatch(success(classDetails));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: classDetailConstants.LOADING };
  }

  function success(classDetails) {
    return { type: classDetailConstants.SUCCESS, classDetails };
  }

  function failure(error) {
    return { type: classDetailConstants.ERROR, error };
  }
}
