import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import { Signup } from "../../components/signup/SignUp";
import { connect } from "react-redux";
import { Row, Col } from "antd";

class SignupPage extends Component {
  render() {
    if (this.props.loggedIn) return <Redirect to="/" />;

    const required = [
      "first_name",
      "last_name",
      "username",
      "email",
      "password",
      "password1",
      "address",
      "phone",
      "school_name",
      "class_detail",
    ];

    return (
      <>
        <div>
          <section id="sign-in" className="bglight position-relative">
            <div className="container">

              <Row className="justify-center">
                <Col  md={12} sm={24}  className="whitebox">
                  <div className="widget logincontainer">
                    <h3 className="darkcolor bottom30 text-center lg:text-left">
                      Student Sign Up
                    </h3>
                    <Signup
                      title="Student Signup"
                      {...this.props}
                      requiredFields={required}
                      loginLink="/login"
                    />
                  </div>
                </Col>
                <Col  md={12} sm={24}  className="bg-white" style={{display: 'flex', flexDirection: 'column'}}>
      <div className="bg-gray-200 flex-1 d-flex items-center justify-center"
                  style={{display: "flex"}}>                                          <div className="py-8 px-4 ">

                    <h3 className="text-center">Benefits</h3>
                    <ul className="benefits">
                      <li> Get expert tutors at the comfort of your home</li>
                      <li>Operational at your convenience</li>
                      <li>Personalized care of student with online tracking</li>
                      <li>A Weekly report of pupil’s progress</li>
                      <li>Pay as you use, no membership fee</li>
                      <li>Flexible plans available</li>
                    </ul>
                                          </div>
                  </div>
                  <div className=" image login-image h-full flex-1"  style={{height: '500px'}}>
                    <img
                      src="https://sprucedutech.s3.ap-south-1.amazonaws.com/images/student_bg.svg"
                      alt=""
                      className=""
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  return {
    loggedIn,
  };
}

export default connect(mapStateToProps)(SignupPage);
