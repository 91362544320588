

export const examTemplate = `
<p>Dear User,</p>
Greetings!!! Trust you are doing well and staying safe.
Our team has verified the credibility and authenticity of your details. You can now proceed for the online screening test.
<br/>
<strong>Test Overview</strong>: The online screening test includes&nbsp;<strong>multiple-choice question (MCQ)</strong>
<ol>
<li>Number of questions: 40</li>
<li>Duration of test: 1hr 15 min</li>
<li>Pass mark: 28</li>
</ol>
Kindly follow the below procedure for the test:
<ol>
<li>First, please&nbsp;<a href="https://app.sprucedutech.com/login">sign in</a> to your account with your credentials</li>
<li>Second,&nbsp;<a href="{}" >click here</a> to take the test.</li>
</ol>
Once completed and evaluated you will have to appear for a final Viva Voice session that will happen the following day.
<br/>
<p>Questions? Call or WhatsApp to +91 81799 37641</p>
<br />
SprucEdutech makes learning interactive and fun by offering online learning solutions to both students and teachers. Check out our latest article on&nbsp;<strong><a href="https://blog.sprucedutech.com/2020/06/08/handpicked-e-learning-tools-for-students-and-teachers-in-2020/">Handpicked E-Learning Tools for Students and Teachers in 2020</a></strong>.
Use of the SprucEdutech service and website is subject to our&nbsp;<strong><a href="https://sprucedutech.com/terms_of_service.html">Terms of Use</a>&nbsp;</strong>and<strong>&nbsp;<a href="https://sprucedutech.com/privacy_policy.html">Privacy Policy</a></strong>.</p>
<br/>
Kind Regards
<strong>SprucEdutech Pvt. Ltd.</strong>
Email:&nbsp;<a href="mailto:info@sprucedutech.com">info@sprucedutech.com</a>
Mobile: +91 90141 15405</p>
MIG II, Mehdipatnam, Hyderabad, Telangana 500028
<a href="http://www.sprucedutech.com/"">www.sprucedutech.com</a>
`