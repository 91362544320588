import React from "react";
import {withRouter} from 'react-router-dom';
import { Link } from "react-router-dom/cjs/react-router-dom.min";


function ErrorPage(props) {
  return (
    <React.Fragment>
      <div>
        <div className="h-screen w-screen bg-blue-600 flex justify-center content-center flex-wrap">
          <p className="font-sans text-white text-5xl error-text">
            {props.error_code ? props.error_code : "Error Occurred"}
          </p>
        </div>
        <div className="absolute w-screen bottom-0 mb-6 text-white text-center font-sans text-xl">
          <Link onClick={() => {props.history.goBack()}} className="border-b" href="">
            <span className="opacity-50">Take me back </span>
          </Link>
        </div>
      </div>
      ;
    </React.Fragment>
  );
}

export default withRouter(ErrorPage);
