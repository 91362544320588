import { Button, Card, Empty, Layout, List } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { examActions } from "../../../actions";
import Loading from "../../../components/Loading";

const { Content } = Layout;

const ExamsListCards = ({ exams, dispatch, history }) => {
  return (
    <>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={exams}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Card title={item.title} className="shadow-md">
              <p>{item.description}</p>
              <p>
                <strong>Start Date : </strong>
                &nbsp; {dayjs(item.start_date).format("DD/MM/YYYY hh:mm a")}
              </p>

              {item.end_date && (
                <p>
                  <strong>End Date : </strong>
                  &nbsp; {dayjs(item.end_date).format("DD/MM/YYYY hh:mm a")}
                </p>
              )}

              <p>
                <strong>Duration : </strong>
                &nbsp; {item.duration}
              </p>
              <p>
                <strong>Number of Questions : </strong>
                &nbsp; {item.number_of_questions}
              </p>
              <div className="flex">
                <Link to={`/exam/${item.slug}/`}>
                  <Button type="primary">Attend</Button>{" "}
                </Link>
                {item.completed_attempt > 0 ?? (
                  <Link to={`/exams/${item.slug}/attempts`} className="ml-2">
                    <Button type="default">View Score</Button>
                  </Link>
                )}
              </div>
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};

class ExamsList extends Component {
  componentDidMount() {
    this.props.dispatch(examActions.getExams());
  }

  renderDataOrEmptyPage = () => {
    if (this.props.exams.results.length === 0 || this.props.error) {
      return <Empty description="You do not have any exams." />;
    }
    return (
      <ExamsListCards
        dispatch={this.props.dispatch}
        exams={this.props.exams.results}
        history={this.props.history}
      />
    );
  };

  render() {
    const { loading } = this.props;

    return (
      <>
        <Layout>
          <Content>
            <div className="flex">
              <h3 className="text-center text-2xl font-bold">
                Available Exams
              </h3>
            </div>
            {loading ? <Loading /> : this.renderDataOrEmptyPage()}
          </Content>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { exams, error, loading } = state.exams;
  return {
    exams,
    error,
    loading,
  };
}

const connectedExamsListComponent = connect(mapStateToProps)(ExamsList);
export default connectedExamsListComponent;
