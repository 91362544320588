import { Layout, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import UpcomingLiveClasses from "./UpcomingLiveClasses";
import { TutorTable } from "./TutorTable";
import { dashboardActions } from "../../../actions/dashboard.actions";
import Loading from "../../../components/Loading";
import MiniCommentsComponent from "./MiniCommentsComponent";
import { Calendar } from "antd";
import UpcomingClassesCarousel from "./UpcomingClassesCarousel";

const { Content } = Layout;
const { TabPane } = Tabs;

class HomeComponent extends React.Component {
  componentDidMount() {
    this.props.dispatch(dashboardActions.getData());
  }

  render() {
    const { user, loading, dashboardData } = this.props;
    return (
      <Layout>
        <Content>
          {loading ? <Loading /> : null}
          <h3 className="font-bold text-xl">
            Welcome back, {user.display_name}. You logged in as a Student!
          </h3>
          <div>
            <div class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-4 md:grid-cols-2">
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-chalkboard-teacher fa-2x text-orange-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.number_of_classes}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Number of Classes
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-white overflow-hidden shadow-sm rounded">
                <div class="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-pencil-alt fa-2x text-red-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd class="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.total_exams}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Total Exams
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-check-circle fa-2x text-blue-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.completed_exams}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Completed Exams
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="far fa-clock fa-2x text-green-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.upcoming_exams}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Upcoming Exams
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 my-4 overflow-hidden">
            <div className="w-full md:w-2/3 p-4">
              <div className="bg-white h-full p-4 shadow-sm">
                <TutorTable />
              </div>
            </div>
            <div className="w-full md:w-1/3">
              <div className="p-4">
                <div className="bg-white p-4">
                  <h4>Comments</h4>
                  <MiniCommentsComponent
                    comments={dashboardData ? dashboardData.comments : []}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 my-4 overflow-hidden">
            <div className="w-full md:w-2/3">
              <Calendar fullscreen={false} />
            </div>
            <div className="w-full md:w-1/3 px-4">
              <div className="bg-white p-4 h-full">
                <UpcomingClassesCarousel />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  const { currentStudentPage } = state.page;
  const { liveClasses } = state.myLiveClasses;
  const { loading, dashboardData, error } = state.dashboard;
  return {
    currentStudentPage,
    liveClasses,
    user,
    loading,
    dashboardData,
    error,
  };
}

export default connect(mapStateToProps)(HomeComponent);
