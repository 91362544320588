import { Avatar, Button, Input, Layout, List, message, Modal } from "antd";
import React, { Component } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { batchService } from "../../services";
import { Link } from "react-router-dom";

const { Content } = Layout;
const { confirm } = Modal;

class Batches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      page: 0,
      total_page: 1,
      batch_name: null,
      batch: { name: null, id: null },
    };
  }

  componentDidMount() {
    this.fetchBatches();
  }

  fetchBatches = (clear_existing = false) => {
    this.setState({
      loading: true,
    });
    if (clear_existing) {
      this.setState({
        list: [],
        page: 0,
      });
    }
    const queryParams = { page: this.state.page + 1 };
    batchService.getBatches(queryParams).then((response) => {
      this.setState((prevState) => ({
        list: [...prevState.list, ...response["results"]],
        initLoading: false,
        loading: false,
        page: response["page"],
        total_pages: response["total_pages"],
      }));
    });
  };

  onLoadMore = () => {
    this.fetchBatches();
  };

  deleteBatch = (id) => {
    batchService
      .deleteBatch(id)
      .then(() => {
        message.success("Successfully deleted batch");
        this.fetchBatches(true);
      })
      .catch(() => {
        message.error("Error in deleting batch");
      });
  };

  deleteBatchConfirmation = (batch_id) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this batch ?",
      onOk: () => {
        this.deleteBatch(batch_id);
      },
    });
  };

  createBatch = () => {
    if (this.state.batch.id) {
      batchService
        .updateBatch(this.state.batch.id, this.state.batch.name)
        .then((response) => {
          this.setState(
            { batch: { name: null, id: null }, visible: false, page: 0 },
            () => {
              this.fetchBatches(true);
            }
          );
          message.success("Successfully updated batch");
        })
        .catch((error) => {
          if (error.name) {
            message.error(error.name);
          } else {
            message.error("Error in updating batch. Please try again.");
          }
        });
    } else {
      batchService
        .createBatch(this.state.batch.name)
        .then((response) => {
          this.setState(
            { batch: { name: null, id: null }, visible: false, page: 0 },
            () => {
              this.fetchBatches(true);
            }
          );
          message.success("Successfully created batch");
        })
        .catch((error) => {
          if (error.name) {
            message.error(error.name);
          } else {
            message.error("Error in creating batch. Please try again.");
          }
        });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  renderModal = () => {
    return (
      <Modal
        title={this.state.batch.id ? "Update Batch" : "Create Batch"}
        visible={this.state.visible}
        confirmLoading={this.props.loading}
        onCancel={() => {
          this.setState({ visible: false });
        }}
        onOk={this.createBatch}
        okButtonProps={{ disabled: this.props.success === true }}
        okText="Submit"
      >
        <Input
          name="batch_name"
          value={this.state.batch.name}
          onChange={(e) => {
            this.setState({
              batch: { name: e.target.value, id: this.state.batch.id },
            });
          }}
          description="Enter brief description"
        />
      </Modal>
    );
  };

  openCreateBatchModal = () => {
    this.setState({ batch: { name: null, id: null }, visible: true });
  };

  render() {
    const { initLoading, loading, list, total_pages, page } = this.state;
    const loadMore =
      page < total_pages && !loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={this.onLoadMore}>Load More</Button>
        </div>
      ) : null;

    return (
      <>
        <Layout>
          <Content>
            {this.renderModal()}

            <div className="flex my-2 justify-between border-b-1 pb-2">
              <h3 className="text-center text-2xl font-bold">Batches</h3>
              <Button type="primary" onClick={this.openCreateBatchModal}>
                Create Batch
              </Button>
            </div>
            <div className="px-5 bg-white">
              <List
                itemLayout="horizontal"
                dataSource={list}
                loading={initLoading}
                loadMore={loadMore}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Link to={`/batches/${item.id}`}>
                        <Button type="primary">View Users</Button>
                      </Link>,
                      <Button
                        onClick={() => {
                          this.setState({
                            batch: { name: item.name, id: item.id },
                            visible: true,
                          });
                        }}
                        type="dashed"
                      >
                        Edit Name
                      </Button>,
                      <Button
                        type="danger"
                        onClick={() => {
                          this.deleteBatchConfirmation(item.id);
                        }}
                      >
                        Delete
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar style={{ backgroundColor: "#16B7D3" }}>
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <Link to={`/batches/${item.id}`}>{item.name}</Link>
                      }
                      description={
                        <span>No of Users : {item.number_of_users}</span>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </Content>
        </Layout>
      </>
    );
  }
}

export default Batches;
