import React from 'react';
import {Result} from 'antd';
import {SmileOutlined, MehOutlined} from '@ant-design/icons';

const ExamCompletion = ({location}) => {
  const {percentage, score} = location;

  function getDisplayInfo(percentage) {
    if (percentage >= 70) {
      return `Thank you for reaching out sprucedutech, you are qualified for interview round. Your score is ${score}. We will reach out through email.`
    } else {
      return "Sorry, Please try again."
    }
  }

  function getIcon() {
    if (percentage >= 70) {
      return <SmileOutlined />
    } else {
      return <MehOutlined />
    }
  }

  return (
    <>
      {percentage ? <Result
      icon={getIcon()}
      title={getDisplayInfo(percentage)}
    /> : <Result
      icon={<SmileOutlined />}
      title={`Great, You have completed the exam. We will assess and get back to you within 2 days!`}
    />}

    </>
  );
};

export default ExamCompletion;
