import { Alert, DatePicker, Form, Input, Modal } from "antd";

import React from "react";

export const JobModal = props => {
  const { visibilility, onCancel, onFormSubmit, job, title } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      title={title}
      visible={visibilility}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            if (Object.keys(job).length === 0) {
              values["action"] = "create";
            } else {
              values["action"] = "update";
            }
            form.resetFields();
            onFormSubmit(values);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
      okText="Submit"
    >
      {props.error ? (
        <Alert message={props.error} className="mb-2" type="error" />
      ) : null}
      {props.success ? (
        <h3>
          We have received your request. We will get back to you in 2 days
        </h3>
      ) : (
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={job}
        >
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                required: true,
                message: "Please input the role for which teacher should apply"
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="required_skill" label="Required Skills">
            <Input type="textarea" />
          </Form.Item>
          <Form.Item
            name="qualification"
            label="Qualification"
            rules={[
              {
                required: true,
                message: "Please input the qualification for teacher"
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="last_apply_date" label="DatePicker">
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default JobModal;
