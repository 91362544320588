import { addQueryParam, authHeader, config, handleResponse } from "../helpers";

export const jobService = {
  getJobs,
  createJob,
  updateJob,
  deleteJob
};

function getJobs(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    }
  };
  let url = `${config.apiUrl}/jobs/`;
  url = addQueryParam(url, queryParams);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(jobs => {
      return jobs;
    });
}

function createJob(jobDetails) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(jobDetails)
  };
  let url = `${config.apiUrl}/jobs/`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(jobs => {
      return jobs;
    });
}

function updateJob(jobDetails) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(jobDetails)
  };
  let url = `${config.apiUrl}/jobs/`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(jobs => {
      return jobs;
    });
}

function deleteJob(jobDetails) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization
    },
    body: JSON.stringify(jobDetails)
  };
  let url = `${config.apiUrl}/jobs/`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(jobs => {
      return jobs;
    });
}
