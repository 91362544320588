import {addQueryParam, authHeader, config, handleResponse} from "../helpers";

export const questionSetService = {
	getQuestionFolders, createQuestionFolder, editQuestionFolder, deleteQuestionFolder,
	getQuestionSetQuestions, removeQuestionFromQuestionSet, addQuestionToQuestionSet
}

function getQuestionFolders(queryParams) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		}
	};
	let url = `${config.apiUrl}/question_set/`
	url = addQueryParam(url, queryParams);

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}

function createQuestionFolder(data) {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify(data)
	};
	let url = `${config.apiUrl}/question_set/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}

function deleteQuestionFolder(folderSlug) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
	};
	let url = `${config.apiUrl}/question_set/${folderSlug}/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}

function editQuestionFolder(folderSlug, data) {
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify(data)
	};
	let url = `${config.apiUrl}/question_set/${folderSlug}/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}

function getQuestionSetQuestions(slug, queryParams) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		}
	};
	let url = `${config.apiUrl}/questions/`
	url = addQueryParam(url, {"question_set": slug});
	url = addQueryParam(url, queryParams)

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}

function removeQuestionFromQuestionSet(questionId) {
	const requestOptions = {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify({"question_set": null})
	};
	let url = `${config.apiUrl}/questions/${questionId}/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}

function addQuestionToQuestionSet(folderId, questionId) {
	const requestOptions = {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
			Authorization: authHeader().Authorization
		},
		body: JSON.stringify({"question_set": folderId})
	};
	let url = `${config.apiUrl}/questions/${questionId}/`

	return fetch(
			url,
			requestOptions
	)
			.then(handleResponse)
			.then(response => {
				return response;
			});
}