import React, { Component } from "react";

import { Input as AntInput } from "antd";

export default class Input extends Component {
  render() {
    return (
      <React.Fragment>
        <label className="block text-grey-darker text-sm mb-2">
          {this.props.label}
        </label>
        {this.props.type === "textarea" ? (
          <AntInput.TextArea
            name={this.props.name}
            value={this.props.value}
            size="large"
            onChange={this.props.onInputChange}
            required={this.props.required}
            placeholder={this.props.placeholder}
          />
        ) : this.props.type === "password" ? (
          <AntInput.Password
            type={this.props.type}
            name={this.props.name}
            value={this.props.value}
            size="large"
            onChange={this.props.onInputChange}
            required={this.props.required}
            placeholder={this.props.placeholder}
          />
        ) : (
          <AntInput
            type={this.props.type}
            name={this.props.name}
            value={this.props.value}
            size="large"
            onChange={this.props.onInputChange}
            required={this.props.required}
            placeholder={this.props.placeholder}
          />
        )}
      </React.Fragment>
    );
  }
}
