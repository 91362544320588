import React from "react";

import { Layout, Menu } from "antd";

import Logo from "../../assets/img/logo_white.svg";
import { Link, withRouter } from "react-router-dom";
import Avatar from "../Avatar";
import { connect } from "react-redux";

const { SubMenu } = Menu;
const { Sider } = Layout;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [],
    };
    this.rootSubmenuKeys = ["classes", "exams", "questions", "batches"];
  }

  onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: keys });
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  };

  render() {
    return (
      <Sider
        breakpoint="xs"
        width="260px"
        collapsedWidth="0"
        trigger={null}
        onAfterChange={(e) => console.log(e)}
        collapsible
        collapsed={this.props.collapse}
      >
        <div className="logo">
          <a href="/">
            <img className="w-48 h-auto" src={Logo} alt="Sprucedutech logo" />
          </a>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          selectedKeys={[this.props.location.pathname]}
        >
          <Menu.Item
            key="/"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/">
              <span className="align-middle">Dashboard</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="classes"
            icon={
              <span>
                <svg
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </span>
            }
            title={<span className="align-middle">Class</span>}
          >
            <Menu.Item key="/my_classes">
              <Link
                to="/my_classes"
                className="hover:text-black hover:bg-transparent"
              >
                All Classes
              </Link>
            </Menu.Item>
            <Menu.Item key="/live-class">
              <Link
                to="/live-class"
                className="hover:text-black hover:bg-transparent"
              >
                Live Classes
              </Link>
            </Menu.Item>
            <Menu.Item key="/create-live-class">
              <Link
                to="/create-live-class"
                className="hover:text-black hover:bg-transparent"
              >
                Create Classes
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="questions"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            }
            title={<span className="align-middle">Question</span>}
          >
            <Menu.Item key="/questions">
              <Link
                to="/questions"
                className="hover:text-black hover:bg-transparent"
              >
                Question Board
              </Link>
            </Menu.Item>
            <Menu.Item key="/questions/add">
              <Link
                to="/questions/add"
                className="hover:text-black hover:bg-transparent"
              >
                Add Questions
              </Link>
            </Menu.Item>
            <Menu.Item key="/bulk-upload">
              <Link
                to="/bulk-upload"
                className="hover:text-black hover:bg-transparent"
              >
                Bulk Upload Questions
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="exams"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </span>
            }
            title={<span className="align-middle">Exams</span>}
          >
            <Menu.Item key="/exams">
              <Link
                to="/exams"
                className="hover:text-black hover:bg-transparent"
              >
                Exams Board
              </Link>
            </Menu.Item>
            <Menu.Item key="/create-exam">
              <Link to="/create-exam" className="hover:bg-transparent">
                Create Exam
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="batches"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </span>
            }
            title={<span className="align-middle">Batch</span>}
          >
            <Menu.Item key="/batches">
              <Link
                to="/batches"
                className="hover:text-black hover:bg-transparent"
              >
                Batch Board
              </Link>
            </Menu.Item>
            <Menu.Item key="/create-batch">
              <Link
                to="/create-batch"
                className="hover:text-black hover:bg-transparent"
              >
                Create Batch
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="/tutors"
            icon={
              <span>
                <svg
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/tutors">
              <span className="align-middle">Teacher's List</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/students"
            icon={
              <span>
                <svg
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path
                    fill="#fff"
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/students">
              <span className="align-middle">Student's List</span>
            </Link>
          </Menu.Item>
        </Menu>
        <div
          className="flex mt-auto text-white p-4 items-center"
          style={{ background: "#222a37" }}
        >
          <div className="mr-4">
            <Avatar
              user={this.props.user ? this.props.user.user : null}
              size="large"
              className="mr-2"
            />
          </div>
          <div className="text-white">
            <h4 className="text-white font-medium text-lg">
              {this.props.user && this.props.user.display_name}
            </h4>
            <div className="text-gray-300 my-0 flex items-baseline">
              <span className="bg-green-500 h-3 w-3 mr-2 rounded-full" />
              <span>Online</span>
            </div>
          </div>
        </div>
      </Sider>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withRouter(Sidebar));
