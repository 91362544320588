import "../LoginPage.css";
import {Col, Row} from "antd";
import {Button, Input, Form, notification} from "antd";
import {userService} from "../../services"

import React, {Component} from 'react';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_error: null,
    }
  }

  onSubmit = (values) => {
    userService.forgotPassword(values)
      .then(() => {
        this.setState({email_error: null})
        notification["success"]({
          message: 'Password reset',
          description:
            'We have sent you a mail which contains steps to reset password.',
        });
      })
      .catch(error => this.setState({email_error: error.email[0]}))
  }

  onFinishFailed = () => {
  }

  render() {

    return (
      <>
        <section id="sign-in" className="bglight h-full position-relative py-10">
          <div className="container" style={{margin: "0 auto"}}>
            <div className="row">
              <div className="col-md-12 mb-8 text-center wow fadeIn" data-wow-delay="300ms">
                <h2 className="heading bottom40 darkcolor font-light2">Reset<span
                  className="font-normal"> Password</span>
                  <span className="divider-center"/>
                </h2>
              </div>

              <Row className="w-full">
                <Col span={12} offset={6} className="whitebox">
                  <div className="widget logincontainer">
                    <h3 className="darkcolor mb-3 text-center lg:text-left">
                      Reset Password
                    </h3>

                    <Form
                      name="basic"
                      onFinish={this.onSubmit}
                      onFinishFailed={this.onFinishFailed()}
                    >
                      <p>Please enter your email!</p>
                      <Form.Item
                        name="email"
                        help={this.state.email_error}
                        validateStatus={this.state.email_error != null ? "error":"success"}
                        rules={[{ required: true, type: 'email' }]}
                      >
                        <Input size="large" placeholder="Email Address"/>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Reset
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>

      </>
    );
  }
}

export default ForgotPassword;