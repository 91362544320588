import { Calendar, Layout } from "antd";
import ErrorPage from "../../components/ErrorPage";
import Loading from "../../components/Loading";
import React from "react";
import { connect } from "react-redux";
import MiniCommentsComponent from "../students/dashboard/MiniCommentsComponent";
import { dashboardActions } from "../../actions";

const { Content } = Layout;

export class HomePage extends React.Component {
  render() {
    const { user } = this.props.tutor;
    const { dashboardData } = this.props;
    return (
      <React.Fragment>
        <Content>
          <h3 className="font-bold text-xl">
            Welcome back, {user.display_name}. You logged in as a Tutor!
          </h3>
          <div>
            <div class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-4 md:grid-cols-2">
              <div class="bg-white overflow-hidden shadow-sm rounded">
                <div class="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-chalkboard-teacher fa-2x text-orange-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd class="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.number_of_classes}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Total Classes
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-star fa-2x text-red-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData.average_rating
                            ? dashboardData.average_rating
                            : "No ratings"}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Rating
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-laptop fa-2x text-blue-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData &&
                            dashboardData.number_of_live_classes}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Live Classes
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-comment-alt fa-2x text-green-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.reviews}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Reviews
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 my-4 overflow-hidden">
            <div className="w-full md:w-1/2 p-4 rounded-sm shadow-sm">
              <MiniCommentsComponent
                is_tutor={true}
                comments={dashboardData.comments}
              />
            </div>
            <div className="w-full md:w-1/2 p-4 rounded-sm shadow-sm">
              <Calendar fullscreen={false} />
            </div>
          </div>
        </Content>
      </React.Fragment>
    );
  }
}

class Home extends React.Component {
  isLoading() {
    return this.props.loading || this.props.dashboardLoading;
  }

  renderPage() {
    if (this.props.user.user_type) {
      this.props.history.push("/");
      return <Loading />;
    }
    if (this.isLoading()) {
      return <Loading />;
    }

    if (this.props.user) {
      return (
        <HomePage
          tutor={this.props.user}
          dashboardData={this.props.dashboardData}
        />
      );
    }

    return <ErrorPage error_code={404} />;
  }

  componentDidMount() {
    this.props.dispatch(dashboardActions.getData());
  }
  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { user, error, loading } = state.user;
  const { dashboardData } = state.dashboard;
  const dashboardLoading = state.dashboard.loading;
  const dashboardError = state.dashboard.error;
  return {
    user,
    error,
    loading,
    dashboardData,
    dashboardError,
    dashboardLoading,
  };
}

const connectedHomeComponent = connect(mapStateToProps)(Home);
export default connectedHomeComponent;
