import { Button, Card, Empty, Layout, List } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import { classDetailService } from "../../../services";
import { liveClassActions } from "../../../actions";

const { Content } = Layout;

const LiveClassList = ({ classes }) => {
  const filteredClasses = classes.filter(
    (liveClass) => !liveClass.is_completed
  );

  const startLiveClass = (id) => {
    classDetailService.getStartUrl(id).then((live_class) => {
      window.open(live_class.start_url);
    });
  };

  return (
    <>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={filteredClasses}
        renderItem={(item) => (
          <List.Item>
            <Card title={item.name}>
              <p>{item.description}</p>
              <p>
                <strong>Start time : </strong>
                &nbsp; {dayjs(item.start_time).format("DD/MM/YYYY hh:mm a")}
              </p>
              <p>
                <strong>Duration : </strong>
                &nbsp; {item.duration}{" "}
                {item.duration > 1 ? "Minutes" : "Minute"}
              </p>
              <p>
                <strong>Tutor : </strong>
                &nbsp; {item.created_by}
              </p>
              <Button
                type="primary"
                onClick={() => startLiveClass(item.id)}
                htmlType="submit"
              >
                Start Class
              </Button>
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};

class UpcomingLiveClasses extends Component {
  componentDidMount() {
    this.props.dispatch(liveClassActions.getMyLiveClasses());
  }

  renderDataOrEmptyPage = () => {
    if (this.props.liveClasses.results.length === 0 || this.props.error) {
      return (
        <Empty
          description={<h3>You do not have any live classes Scheduled.</h3>}
        />
      );
    }
    return <LiveClassList classes={this.props.liveClasses.results} />;
  };

  render() {
    const { loading } = this.props;

    return (
      <>
        <Layout>
          <Content>
            <div className="flex">
              <h3 className="text-center text-2xl font-bold">Live Classes</h3>
            </div>
            {loading ? <Loading /> : this.renderDataOrEmptyPage()}
          </Content>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { liveClasses, error, loading } = state.myLiveClasses;
  return {
    liveClasses,
    error,
    loading,
  };
}

const connectedUpcomingLiveClassesComponent = connect(mapStateToProps)(
  UpcomingLiveClasses
);
export default connectedUpcomingLiveClassesComponent;
