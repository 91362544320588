import { Layout, Empty, Button, List, Card, Modal } from "antd";
import dayjs from "dayjs";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { liveClassActions } from "../../actions";
import { classDetailService } from "../../services";
import Loading from "../../components/Loading";

const { Content } = Layout;

const LiveClassList = ({ user, classes, dispatch }) => {
  const filteredClasses = classes.filter(
    (liveClass) => !liveClass.is_completed
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [classToDelete, setClassToDelete] = useState(null);

  const handleOk = () => {
    if (!classToDelete) {
      return;
    }
    setConfirmLoading(true);

    classDetailService.deleteLiveClasses(classToDelete).then(() => {
      setIsModalVisible(false);
      setConfirmLoading(false);
      dispatch(liveClassActions.getLiveClasses());
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={user.user_type === 0 ? classes : filteredClasses}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Card title={item.name}>
              <p>{item.description}</p>
              <p>
                <strong>Start time : </strong>
                &nbsp; {dayjs(item.start_time).format("DD/MM/YYYY hh:mm a")}
              </p>
              <p>
                <strong>Duration : </strong>
                &nbsp; {item.duration}
                {item.duration > 1 ? "Minutes" : "Minute"}
              </p>
              <p>
                <strong>Students : </strong>
                {item.students.map((student) => {
                  return (
                    <span key={student.id}>&nbsp; {student.display_name}</span>
                  );
                })}
              </p>

              <Button
                target="_blank"
                type="primary"
                href={item.url}
                htmlType="submit"
              >
                Start Class
              </Button>

              {dayjs(item.start_time).diff(dayjs(), "h") >= 24 && (
                <Link to={`/live-class/${item.id}`}>
                  <Button className="ml-3" target="_blank" type="secondary">
                    Reschedule
                  </Button>
                </Link>
              )}
            </Card>
          </List.Item>
        )}
      />
      <Modal
        title="Delete"
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to cancel the live class ?</p>
      </Modal>
    </>
  );
};

class LiveClass extends Component {
  componentDidMount() {
    this.props.dispatch(liveClassActions.getLiveClasses());
  }

  renderDataOrEmptyPage = () => {
    if (this.props.liveClasses.results.length === 0 || this.props.error) {
      return (
        <Empty description="You do not have any live classes.">
          <Link to="/create-live-class">
            <Button type="primary">Create Now</Button>
          </Link>
        </Empty>
      );
    }
    return (
      <LiveClassList
        dispatch={this.props.dispatch}
        classes={this.props.liveClasses.results}
        user={this.props.user}
      />
    );
  };

  render() {
    const { loading } = this.props;

    return (
      <>
        <Layout>
          <Content>
            <div className="flex mt-2 justify-between border-b-1 pb-2">
              <h3 className="text-center text-2xl font-bold">Live Classes</h3>
              <Link to="/create-live-class">
                <Button type="primary">Create Now</Button>
              </Link>
            </div>
            {loading ? <Loading /> : this.renderDataOrEmptyPage()}
          </Content>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { liveClasses, error, loading } = state.liveClasses;
  return {
    liveClasses,
    error,
    loading,
  };
}

const connectedLiveClassComponent = connect(mapStateToProps)(LiveClass);
export default connectedLiveClassComponent;
