import {examConstants, liveClassConstants} from "../constants";
import {classDetailService, examService} from "../services";

export const examActions = {
  getExamDetails, getQuestions, getExams, getAttempts
};

function getExamDetails(slug) {
  return dispatch => {
    dispatch(request())

    examService.getExamDetails(slug).then(
      examDetails => dispatch(success(examDetails)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: examConstants.EXAM_DETAILS_REQUEST };
  }

  function success(examDetails) {
    return { type: examConstants.EXAM_DETAILS_SUCCESS, examDetails };
  }

  function failure(error) {
    return { type: examConstants.EXAM_DETAILS_ERROR, error };
  }
}

function getQuestions(slug) {
  return dispatch => {
    dispatch(request())

    examService.getQuestions(slug).then(
      examQuestions => dispatch(success(examQuestions)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: examConstants.EXAM_QUESTIONS_REQUEST };
  }

  function success(examQuestions) {
    return { type: examConstants.EXAM_QUESTIONS_SUCCESS, examQuestions };
  }

  function failure(error) {
    return { type: examConstants.EXAM_QUESTIONS_ERROR, error };
  }
}

function getExams() {
  return dispatch => {
    dispatch(request());

    examService.getExams().then(
      exams => {
        dispatch(success(exams));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: examConstants.GET_EXAMS_REQUEST };
  }

  function success(exams) {
    return { type: examConstants.GET_EXAMS_SUCCESS, exams };
  }

  function failure(error) {
    return { type: examConstants.GET_EXAMS_ERROR, error };
  }
}

function getAttempts(slug) {
  return dispatch => {
    dispatch(request());

    examService.getAttempts(slug).then(
        attempts => {
          dispatch(success(attempts));
        },
        error => {
          dispatch(failure(error));
        }
    );
  };

  function request() {
    return { type: examConstants.ATTEMPTS_LIST_REQUEST };
  }

  function success(attempts) {
    return { type: examConstants.ATTEMPTS_LIST_SUCCESS, slug: slug, attempts };
  }

  function failure(error) {
    return { type: examConstants.ATTEMPTS_LIST_ERROR, error };
  }
}