import { Calendar, Layout } from "antd";
import ErrorPage from "../../components/ErrorPage";
import Loading from "../../components/Loading";
import React from "react";
import { connect } from "react-redux";
import MiniCommentsComponent from "../students/dashboard/MiniCommentsComponent";
import { dashboardActions } from "../../actions";

const { Content } = Layout;

export class HomePage extends React.Component {
  render() {
    const { dashboardData, user } = this.props;
    return (
      <React.Fragment>
        <Content>
          <h3 className="font-bold text-xl">
            Welcome back, {user.display_name}. You logged in as a Admin!
          </h3>
          <div>
            <div class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-4 md:grid-cols-2">
              <div class="bg-white overflow-hidden shadow-sm rounded">
                <div class="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <svg
                        className="w-8 h-8 inline-flex mr-2 align-middle text-indigo-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd class="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.number_of_classes}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Total Classes
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <svg
                        className="w-8 h-8 inline-flex mr-2 align-middle text-orange-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path
                          fill="#fff"
                          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                        />
                      </svg>
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.tutors}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Tutors
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <svg
                        className="w-8 h-8 inline-flex mr-2 align-middle text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.students}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Students
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <svg
                        className="w-8 h-8 inline-flex mr-2 align-middle text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                        />
                      </svg>
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.institutes}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Institutes
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 my-4 overflow-hidden">
            <div className="w-full md:w-1/2 p-4 rounded-sm shadow-sm">
              <MiniCommentsComponent
                is_tutor={true}
                comments={dashboardData.comments}
              />
            </div>
            <div className="w-full md:w-1/2 p-4 rounded-sm shadow-sm">
              <Calendar fullscreen={false} />
            </div>
          </div>
        </Content>
      </React.Fragment>
    );
  }
}

class Home extends React.Component {
  isLoading() {
    return this.props.loading || this.props.dashboardLoading;
  }

  renderPage() {
    if (this.props.user.user_type) {
      this.props.history.push("/");
      return <Loading />;
    }
    if (this.isLoading()) {
      return <Loading />;
    }

    if (this.props.user) {
      return (
        <HomePage
          user={this.props.user}
          dashboardData={this.props.dashboardData}
        />
      );
    }

    return <ErrorPage error_code={404} />;
  }

  componentDidMount() {
    this.props.dispatch(dashboardActions.getData());
  }
  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { user, error, loading } = state.user;
  const { dashboardData } = state.dashboard;
  const dashboardLoading = state.dashboard.loading;
  const dashboardError = state.dashboard.error;
  return {
    user,
    error,
    loading,
    dashboardData,
    dashboardError,
    dashboardLoading,
  };
}

const connectedHomeComponent = connect(mapStateToProps)(Home);
export default connectedHomeComponent;
