import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Layout,
  message,
  Row,
  Select,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { questionSetService, questionsService } from "../../services";
import AsyncSelect from "react-select/async/dist/react-select.esm";

const { Content } = Layout;
const { Option } = Select;

export const AddQuestion = ({ id, exam_slug }) => {
  const answer = {
    text_html: "",
    is_correct: false,
  };
  const history = useHistory();
  const [editmode, changeMode] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState();
  const [questionSet, setQuestionSet] = useState();
  const [options, addOptions] = useState([answer]);
  const [question, setQuestion] = useState();
  const [ans, setAnswer] = useState();
  const [question_type, updateQType] = useState("R");
  const [marks, updateMarks] = useState("1");
  const [explanation, setExplanation] = useState();

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "clean",
  ];

  useEffect(() => {
    if (id !== undefined) {
      questionsService
        .getQuestion(id)
        .then((res) => {
          setQuestion(res.question_html);
          res.answers.map((answer, index) => {
            if (answer.is_correct) {
              setAnswer(index);
            }
          });
          addOptions(res.answers);
          updateQType(res.question_type);
          updateMarks(res.marks);
          setSelectedQuestionSet(res.question_set);
          setExplanation(res.explanation_html);
          if (res.question_set) {
            setQuestionSet({
              id: res.question_set,
              name: res.question_set_name,
            });
          }
          changeMode(true);
        })
        .catch((err) => {
          console.log(err);
          message.error("Question Not Found");
        });
    }
  }, []);

  const toggleOptionIsCorrect = (index) => {
    let tmpAry = [...options];
    tmpAry[index].is_correct = !options[index].is_correct;
    addOptions([...tmpAry]);
  };

  const addQuestionToDB = (e) => {
    e.preventDefault();
    var questionObj = {
      question_html: question,
      answers: options,
      question_type,
      marks,
      explanation_html: explanation,
      question_set: selectedQuestionSet,
      exam_slug: exam_slug,
    };
    if (!selectedQuestionSet) {
      message.error("Please select a question folder");
      return;
    }
    if (!question) {
      message.error("Please type question");
      return;
    }

    if (options.length < 2) {
      message.error("Please add atleast 2 options");
      return;
    }

    for (const option of options) {
      if (!option.text_html || options.text_html === "") {
        message.error("Option should not be empty");
        return;
      }
    }
    options.map((option) => {
      if (options.text_html === "") {
        console.log("options : ", options);
        message.error("Option should not be empty");
      }
    });

    if (editmode) {
      questionsService.updateQuestion(id, questionObj).then((question) => {
        message.success("Question updated successfully");
        setTimeout(history.go(0), 1000);
      });
    } else {
      questionsService.addQuestion(questionObj).then((question) => {
        message.success("Question added successfully");
        let path = `/questions`;
        if (exam_slug) {
          path = `/exams/${exam_slug}/questions`;
        }
        history.push(path);
      });
    }
  };

  const getOptionValue = (option) => option.id;

  const getOptionLabel = (option) => option.name;

  const fetchQuestionSet = (value) => {
    setFetching(true);
    return questionSetService
      .getQuestionFolders({ search: value })
      .then((response) => {
        setFetching(false);
        return response["results"];
      })
      .catch(() => {
        setFetching(false);
      });
  };

  const deleteOptions = (index) => {
    if (options.length > 2) {
      var tmpAry = [...options];
      tmpAry.splice(index, 1);
      addOptions([...tmpAry]);
    } else {
      message.warning("A question should have at least 2 options.");
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <Content>
          <Card title={editmode ? "Edit Question" : "Create Question"}>
            <Form
              className=" flex flex-col w-full"
              name="basic"
              layout="vertical"
            >
              <Form.Item label="Question">
                <ReactQuill
                  value={question || ""}
                  theme="snow"
                  placeholder="Enter your question"
                  onChange={(content) => {
                    setQuestion(content);
                  }}
                  className="mb-4"
                  modules={modules}
                  formats={formats}
                />
              </Form.Item>
              <Row>
                <Col span={6}>
                  <Form.Item label="Type">
                    <Select
                      value={question_type}
                      onChange={(val) => updateQType(val)}
                      style={{ width: "90%" }}
                      size="medium"
                    >
                      <Option value="R">MCQ, SingleCorrect</Option>
                      <Option value="C">MCQ, MultipleCorrect</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Marks">
                    <Select
                      value={marks}
                      onChange={(val) => updateMarks(val)}
                      style={{ width: "90%" }}
                      size="medium"
                    >
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="5">5</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Folder">
                    <AsyncSelect
                      cacheOptions
                      loadOptions={fetchQuestionSet}
                      defaultOptions
                      value={questionSet}
                      name="selectedQuestionSet"
                      onChange={(selectedOption) => {
                        setSelectedQuestionSet(selectedOption.id);
                        setQuestionSet({
                          id: selectedOption.id,
                          name: selectedOption.name,
                        });
                      }}
                      getOptionValue={getOptionValue}
                      getOptionLabel={getOptionLabel}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                {options.map((option, index) => {
                  return (
                    <Form.Item
                      label={"Option" + (index + 1)}
                      key={index}
                      rules={[{ required: true }]}
                    >
                      <Row>
                        <Col span={18}>
                          <ReactQuill
                            value={option.text_html}
                            theme="snow"
                            placeholder="Enter you option"
                            onChange={(content) => {
                              option.text_html = content;
                            }}
                            modules={modules}
                            formats={formats}
                            className="mb-4"
                          />
                        </Col>
                        <Col span={6}>
                          <Form.Item name="isCorrect">
                            <Checkbox
                              style={{ marginLeft: "10px" }}
                              checked={option.is_correct}
                              onChange={(e) => toggleOptionIsCorrect(index)}
                            >
                              Correct ?
                            </Checkbox>
                          </Form.Item>
                          <Button
                            danger
                            type="dashed"
                            icon={<DeleteOutlined />}
                            style={{ marginLeft: "10px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteOptions(index);
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  );
                })}
                <Button
                  type="dashed"
                  className="text-green"
                  icon={<PlusOutlined />}
                  onClick={(e) => {
                    e.preventDefault();
                    addOptions([...options, answer]);
                  }}
                >
                  AddOption
                </Button>
              </Form.Item>
              <Form.Item label="Explanation">
                <ReactQuill
                  theme="snow"
                  placeholder="Enter your explanation"
                  onChange={(content) => {
                    setExplanation(content);
                  }}
                  className="mb-4"
                  modules={modules}
                  formats={formats}
                />
              </Form.Item>
              {editmode ? (
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      addQuestionToDB(e);
                    }}
                  >
                    Update
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      addQuestionToDB(e);
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </Form>
          </Card>
        </Content>
      </Layout>
    </React.Fragment>
  );
};
