import { Alert, Button, Input, Modal } from "antd";
import React, { Component } from "react";

import ErrorPage from "../../components/ErrorPage";
import Loading from "../../components/Loading";
import { connect } from "react-redux";
import { enquiryActions } from "../../actions";
import { tutorService } from "../../services";

class DetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, description: "", confirmLoading: false };
  }

  submitEnquiry = (e) => {
    e.preventDefault();
    if (!this.state.description) {
      return;
    }

    const enquiry = {
      object_id: this.props.tutor.id,
      description: this.state.description,
      content_type: "employees.Employee",
    };
    this.props.dispatch(enquiryActions.sendEnquiry(enquiry));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  renderEnquiryStatus = (enquiry_status) => {
    switch (enquiry_status) {
      case "Pending":
        return (
          <Alert
            message="Awaiting for confirmation"
            className="mt-4"
            type="info"
            showIcon
          />
        );
      case "Approved":
        return (
          <Alert
            message="Request Approved"
            className="mt-4"
            type="success"
            showIcon
          />
        );
      default:
        return (
          <Button
            block
            onClick={(e) => {
              this.setState({ visible: true });
            }}
            className="bg-blue-500 text-white font-bold text-center mt-3"
          >
            Enquire now
          </Button>
        );
    }
  };

  render() {
    const { tutor } = this.props;
    const profile_photo_url = tutor.user.profile_photo_url
      ? tutor.user.profile_photo_url
      : "https://via.placeholder.com/150";

    return (
      <React.Fragment>
        <Modal
          title={`Enquire for  ${tutor.user.username}`}
          visible={this.state.visible}
          confirmLoading={this.props.loading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          onOk={this.submitEnquiry}
          okButtonProps={{ disabled: this.props.success === true }}
          okText="Submit"
        >
          {this.props.error ? (
            <Alert message={this.props.error} className="mb-2" type="error" />
          ) : null}
          {this.props.success ? (
            <h3>
              We have received your request. We will get back to you as soon as
              possible.
            </h3>
          ) : (
            <Input.TextArea
              rows={4}
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
              description="Enter brief description"
            />
          )}
        </Modal>
        <div className="bg-gray-200 h-full">
          <div className="container pt-10 mx-auto">
            <div className="flex flex-col sm:flex-row">
              <div className="sm:w-1/3 m-4">
                <div className="max-w-xs shadow-lg bg-white rounded overflow-hidden">
                  <img
                    className="w-full"
                    src={profile_photo_url}
                    alt={this.props.tutor.user.username}
                  />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">
                      {this.props.tutor.user.username}
                    </div>
                    <div class="flex items-center mt-1">
                      <svg
                        class="w-4 h-4 fill-current text-yellow-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                      </svg>
                      <svg
                        class="w-4 h-4 fill-current text-yellow-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                      </svg>
                      <svg
                        class="w-4 h-4 fill-current text-yellow-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                      </svg>
                      <svg
                        class="w-4 h-4 fill-current text-yellow-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                      </svg>
                      <svg
                        class="w-4 h-4 fill-current text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                      </svg>
                    </div>
                    {this.renderEnquiryStatus(tutor.enquiry_status)}
                  </div>
                </div>
                <div className="max-w-xs my-5 p-5 shadow-lg bg-white rounded overflow-hidden">
                  <h4>Biography</h4>
                  <p className="text-gray-600 mt-2 text-sm">
                    {tutor.biography
                      ? tutor.biography
                      : "It seems tutor didn't update details yet"}
                  </p>
                </div>
              </div>

              <div className="sm:w-2/3 py-5 px-5 rounded m-4 bg-white shadow-lg">
                <div>
                  <p>Educational Details</p>
                  <table className="w-full table-auto text-left mt-3 text-gray-600 text-sm">
                    <tr>
                      <th className="border px-4 py-2">Institution Name</th>
                      <th className="border px-4 py-2">Degree</th>
                      <th className="border px-4 py-2">Start Year</th>
                      <th className="border px-4 py-2">End Year</th>
                    </tr>
                    {tutor.education.map((education) => {
                      return (
                        <tr>
                          <td className="border px-4 py-2">
                            {education.institute_name}
                          </td>
                          <td className="border px-4 py-2">
                            {education.degree}
                          </td>
                          <td className="border px-4 py-2">
                            {education.start_date}
                          </td>
                          <td className="border px-4 py-2">
                            {education.end_date}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
                <div className="mt-5">
                  <p>Experience</p>
                  <table className="w-full mt-3 table-auto text-left text-gray-600 text-sm">
                    <tr>
                      <th className="border px-4 py-2">Institution Name</th>
                      <th className="border px-4 py-2">Specialization</th>
                      <th className="border px-4 py-2">Start Year</th>
                      <th className="border px-4 py-2">End Year</th>
                    </tr>
                    {tutor.experience.map((education) => {
                      return (
                        <tr>
                          <td className="border px-4 py-2">
                            {education.institute_name}
                          </td>
                          <td className="border px-4 py-2">
                            {education.subjects.map((subject) => {
                              return <p>{subject.name}</p>;
                            })}
                          </td>
                          <td className="border px-4 py-2">
                            {education.start_date}
                          </td>
                          <td className="border px-4 py-2">
                            {education.end_date}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
                <div className="mt-5 text-gray-600">
                  <p className="text-blue-500  underline font-bold">
                    Tutor Details
                  </p>
                  <table className="text-sm mt-3">
                    <tr>
                      <td>Name</td>
                      <td className="w-6"> : </td>
                      <td>{tutor.user.first_name + tutor.user.last_name}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td className="w-6"> : </td>
                      <td>{tutor.user.state}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td className="w-6"> : </td>
                      <td>{tutor.user.city}</td>
                    </tr>
                  </table>
                </div>

                <div className="mt-5 text-gray-600">
                  <p className="text-blue-500">Proficient Subjects :</p>
                  {tutor.subjects.map((subject) => {
                    return <p>{subject.name}</p>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { enquiry, loading, success, error } = state.enquiry;
  return {
    enquiry,
    loading,
    success,
    error,
  };
}

const DetailPageComponent = connect(mapStateToProps)(DetailPage);

export class TutorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutor: null,
      error: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    tutorService.getTutor(id).then(
      (tutor) => this.setState({ tutor: tutor }),
      (error) => this.setState({ error: error })
    );
  }

  isLoading() {
    return !this.state.tutor && !this.state.error;
  }

  renderPage() {
    if (this.isLoading()) {
      return <Loading />;
    }

    if (this.state.tutor) {
      return <DetailPageComponent tutor={this.state.tutor} />;
    }

    return <ErrorPage error_code={404} />;
  }

  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }
}

export default TutorDetail;
