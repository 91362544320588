import React, { Component } from "react";
import { BulkUploadPage } from "../pages/admin/BulkUpload";

import HomePage from "../pages/tutors/HomePage";
import InstituteHomePage from "../pages/institute/Home";
import StudentHomePage from "../pages/students/dashboard/Dashboard";
import { connect } from "react-redux";
import ExamsList from "../pages/admin/ExamsList";
import Dashboard from "../pages/admin/Dashboard";

class Home extends Component {
  renderNavbar = () => {
    if (this.props.user) {
      if (this.props.user.user) {
        return <HomePage {...this.props} />;
      }

      switch (this.props.user.user_type) {
        case 0:
          return <Dashboard {...this.props} />;
        case 1:
          return <StudentHomePage {...this.props} />;
        case 2:
          return <HomePage {...this.props} />;
        case 3:
          return <InstituteHomePage {...this.props} />;
        default:
          return <StudentHomePage {...this.props} />;
      }
    }
  };

  render() {
    return <React.Fragment>{this.renderNavbar()}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Home);
