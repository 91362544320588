import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import { Signup } from "../../components/signup";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import "../LoginPage.css"

class SignUpPage extends Component {
  render() {
    if (this.props.loggedIn) return <Redirect to="/update-details" />;
    const required = [
      "first_name",
      "last_name",
      "username",
      "email",
      "phone",
      "password",
      "password1",
      "city",
      "state",
      "address",
    ];

    return (
      <>
        <div>
          <section id="sign-in" className="bglight position-relative">
            <div className="container">
              <Row className="justify-center">
                <Col  md={12} sm={24}  className="whitebox">
                  <div className="widget logincontainer">
                    <h3 className="darkcolor bottom30 text-center lg:text-left">
                      Tutor Sign Up
                    </h3>
                    <Signup
                      title="Tutor Signup"
                      {...this.props}
                      userType={2}
                      requiredFields={required}
                      loginLink="/login"
                    />
                  </div>
                </Col>
                <Col  md={12} sm={24}  className="bg-white" style={{display: 'flex', flexDirection: 'column'}}>
                  <div className="bg-gray-200 flex-1 d-flex items-center justify-center"
                  style={{display: "flex"}}>
                                        <div className="py-8 px-8 ">
                    <h3 className="text-center">Benefits</h3>
                    <ul className="benefits">
                      <li>Find students near you</li>
                      <li>Flexible timings</li>
                      <li>Enhance your portfolio</li>
                      <li>Skill development programs</li>
                      <li>Opportunity to work with the Sprucedutech brand</li>
                      <li>Competitive membership fee</li>
                    </ul>
                                        </div>
                  </div>
                   <div className="flex-1 image login-image h-full"  style={{height: '500px'}}>
                    <img
                      src="https://sprucedutech.s3.ap-south-1.amazonaws.com/images/tutor_bg.svg"
                      alt=""
                      className=""
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  return {
    loggedIn,
  };
}

export default connect(mapStateToProps)(SignUpPage);
