import { userConstants } from "../constants";

let auth_token = localStorage.getItem(userConstants.AUTH_TOKEN);
const initialState = {
  loggedIn: auth_token !== undefined && auth_token !== null,
  signingUp: false,
  error: null,
  loggingIn: false,
  signedUp: false
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
      };
    case userConstants.SIGNUP_REQUEST:
      return {
        ...state,
        signingUp: true,
        loggingIn: false,
      };
    case userConstants.SIGNUP_FAILURE:
      return {
        ...state,
        signingUp: false,
        loggingIn: false,
        error: action.error
      };
    case userConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        signingUp: false,
        loggingIn: false,
        error: null,
        signedUp: true
      };
    default:
      return state;
  }
}
