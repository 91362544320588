import React from "react";
import {
  Alert,
  Button,
  Row,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Table,
} from "antd";
import { courseService, tutorService } from "../../../services";
import { enquiryActions, tutorActions } from "../../../actions";
import { connect } from "react-redux";
import { MailOutlined } from "@ant-design/icons";
import AsyncSelect from "react-select/async/dist/react-select.esm";

const { Column } = Table;
const { Content } = Layout;

export class TutorTable extends React.Component {
  constructor(props) {
    super();
    this.state = {
      tutors: {},
      visible: false,
      description: "",
      confirmLoading: false,
      enquiring_tutor: {},
    };
  }

  submitEnquiry = (e) => {
    e.preventDefault();
    if (!this.state.description) {
      return;
    }

    const enquiry = {
      object_id: this.props.tutor.id,
      description: this.state.description,
      content_type: "employees.Employee",
    };
    this.props.dispatch(enquiryActions.sendEnquiry(enquiry));
  };

  componentDidMount() {
    this.getTutors();
  }

  handleSelectChange = (selectedOption) => {
    const queryParams = {
      course_preference__name: selectedOption.name,
    };
    this.getTutors(queryParams);
  };

  getTutors = (query = {}) => {
    tutorService.getTutors(query).then((res) => {
      this.setState({ tutors: res });
    });
  };

  getCourses = (query) => {
    return courseService.getCourses(query).then(
      (courses) => courses["results"],
      (error) => {
        console.err(error);
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          title={`Enquire for  ${
            this.state.enquiring_tutor.user &&
            this.state.enquiring_tutor.user.username
          }`}
          visible={this.state.visible}
          confirmLoading={this.props.loading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          onOk={this.submitEnquiry}
          okButtonProps={{ disabled: this.props.success === true }}
          okText="Submit"
        >
          {this.props.error ? (
            <Alert message={this.props.error} className="mb-2" type="error" />
          ) : null}
          {this.props.success ? (
            <h3>
              We have received your request. We will get back to you as soon as
              possible.
            </h3>
          ) : (
            <Input.TextArea
              rows={4}
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
              description="Enter brief description"
            />
          )}
        </Modal>

        <div>
          <h3>Tutors</h3>
          <Row className="my-4">
            <Col span={8}>
              <p className="mb-0">Subject</p>
              <AsyncSelect
                cacheOptions
                closeMenuOnSelect={true}
                loadOptions={this.getCourses}
                defaultOptions
                placeholder="Select/Enter Subject"
                onChange={this.handleSelectChange}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                name="subject"
                className=""
              />
            </Col>
          </Row>
          <Table
            dataSource={this.state.tutors.results}
            showSorterTooltip="true"
            pagination={{
              current: this.state.tutors.page,
              total: this.state.tutors.count,
              pageSize: 10,
            }}
            rowKey="id"
          >
            <Column
              title="Index"
              key="index"
              render={(value, item, index) =>
                (this.state.tutors.page - 1) * 10 + index + 1
              }
            />
            <Column
              title="Tutor Name"
              dataIndex={["user", "display_name"]}
              key="displayName"
            />
            <Column
              title="Subjects"
              key="subjects"
              render={(text, record) => {
                return (
                  <div>
                    {record.subjects
                      .map(function (e) {
                        return e.name;
                      })
                      .join(", ")}
                  </div>
                );
              }}
            />
            <Column
              title="Contact Us"
              key="contact"
              render={(text, record) => {
                return (
                  <MailOutlined
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        enquiring_tutor: record,
                        visible: true,
                      });
                    }}
                  />
                );
              }}
            />
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { enquiry, loading, success, error } = state.enquiry;
  return {
    enquiry,
    loading,
    success,
    error,
  };
}
export default connect(mapStateToProps)(TutorTable);
