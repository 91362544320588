import React, {Component} from "react";
import {Button, Card, Checkbox, Col, Form, Input, Layout, message, Row} from "antd";
import {connect} from "react-redux";
import {userService} from "../../services";
import {userActions} from "../../actions";


const {Content} = Layout;


class UpdateProfilePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false
		}
	}

	onFinish = (values) => {
		this.setState({saving: true})
		userService.updateUserProfile(values)
				.then(response => {
					this.props.dispatch(userActions.getCurrentUser());
					message.success("Profile Updated successfully")
				})
				.catch(error => message.error("Error in updating profile"))
	};

	onFinishFailed = (errorInfo) => {
		message.error("Cannot update profile");
		console.log("Failed:", errorInfo);
	};


	render() {
		const {user} = this.props;
		return (
				<Layout className="bg-gray-100">
					<Content className="bg-white m-10 p-5">
						<Row>
							<Col span={12} offset={6}>
								<Card className="mx-auto">
									<h3 className="text-center">Edit Profile</h3>

									<Form
											className="flex flex-col w-full"
											name="basic"
											initialValues={{
												first_name: user.first_name,
												last_name: user.last_name,
												address: user.address,
												phone: user.phone,
												state: user.state,
												email: user.email
											}}
											onFinish={this.onFinish}
											layout="vertical"
											onFinishFailed={this.onFinishFailed}
									>
										<Form.Item name="first_name" label="First Name" rules={[
											{
												required: true,
												message: "Please input your first name",
											},
										]}>
											<Input/>
										</Form.Item>
										<Form.Item name="last_name" label="Last Name" rules={[
											{
												required: true,
												message: "Please input your last name",
											},
										]}>
											<Input/>
										</Form.Item>
										<Form.Item name="email" label="Email">
											<Input disabled={true}/>
										</Form.Item>
										<Form.Item name="phone" label="Phone" rules={[
											{
												required: true,
												message: "Please input your mobile number",
											},
										]}>
											<Input/>
										</Form.Item>
										<Form.Item name="city" label="City">
											<Input/>
										</Form.Item>
										<Form.Item name="state" label="State" rules={[
											{
												required: true,
												message: "Please input your state",
											},
										]}>
											<Input/>
										</Form.Item>
										<Form.Item name="address" label="Address" rules={[
											{
												required: true,
												message: "Please input your address",
											},
										]}>
											<Input/>
										</Form.Item>

										<Form.Item className="text-left">
											<Button type="primary" htmlType="submit">
												Save
											</Button>
										</Form.Item>
									</Form>
								</Card>
							</Col>
						</Row>
					</Content>
				</Layout>
		)
	}
}


function mapStateToProps(state) {
	const {user} = state.user;
	if (user.user) {
		return user
	}
	return {
		user,
	};
}

export default connect(mapStateToProps)(UpdateProfilePage);