import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  List,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import React, { Component } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { batchService, examService } from "../../services";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import TimePicker from "../common/TimePicker";

const { Content } = Layout;
const { confirm } = Modal;

class CreateBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      page: 0,
      total_page: 1,
      batch_name: null,
      batch: { name: null, id: null },
    };
  }

  createBatch = (name) => {
    batchService
      .createBatch(name)
      .then((response) => {
        message.success("Successfully created batch");
      })
      .catch((error) => {
        if (error.name) {
          message.error(error.name);
        } else {
          message.error("Error in creating batch. Please try again.");
        }
      });
  };

  onFinish = (values) => {
    this.createBatch(values.title);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <>
        <Layout>
          <Content>
            <Row>
              <Col span={12} offset={6}>
                <Card className="bg-white mx-auto">
                  <h3 className="text-center">Create Batch</h3>

                  <Form
                    className=" flex flex-col w-full"
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    layout="vertical"
                    onFinishFailed={this.onFinishFailed}
                  >
                    <Form.Item
                      name="title"
                      label="Batch Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the title of exam",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item className="text-left">
                      <Button
                        type="primary"
                        loading={this.state.saving}
                        htmlType="submit"
                      >
                        Create
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}

export default CreateBatch;
