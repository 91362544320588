import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { batchService, examService } from "../../services";
import TimePicker from "../common/TimePicker";
import queryString from "query-string";

const { Content } = Layout;
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

class CreateExam extends Component {
  constructor(props) {
    super(props);
    const queryParams = queryString.parse(props.location.search);

    this.state = {
      start_time: dayjs().format(timeFormat),
      start_date: dayjs().format(dateFormat),
      end_time: null,
      end_date: null,
      saving: false,
      duration: null,
      selected_batches: [],
      selected_batch_ids: [],
      batches: [],
      fetching: false,
      question_set_slug: queryParams.folder,
    };
  }

  onFinish = (values) => {
    const data = {
      title: values.title,
      description: values.description,
      start_date: `${this.state.start_date} ${this.state.start_time}`,
      end_date: `${this.state.end_date} ${this.state.end_time}`,
      duration: `${this.state.duration}`,
      is_public: values.is_public,
      batches: this.state.selected_batch_ids,
      question_set: this.state.question_set_slug,
    };
    if (!this.state.end_date) {
      delete data["end_date"];
    } else if (this.state.end_date && !this.state.end_time) {
      message.info("End time is required");
      return;
    }

    this.setState({ saving: true });
    examService.createExam(data).then((response) => {
      this.setState({ saving: false });
      this.props.history.push("/exams");
    });
  };

  onFinishFailed = (errorInfo) => {
    message.error("Cannot create exam");
    console.log("Failed:", errorInfo);
  };

  fetchBatches = (value) => {
    this.setState({ fetching: true });
    batchService.getBatches({ search: value }).then((response) => {
      let results = response["results"];
      this.setState({ batches: results, fetching: false });
    });
  };

  handleBatchesChange = (values) => {
    let selected_batch_ids = values.map((user) => parseInt(user.value));

    this.setState({
      selected_batches: values,
      selected_batch_ids: selected_batch_ids,
      batches: [],
      fetching: false,
    });
  };

  render() {
    const { fetching, batches, selected_batches } = this.state;

    return (
      <Layout className="bg-gray-100">
        <Content className="m-10 p-5">
          <Row>
            <Col span={12} offset={6}>
              <Card className="bg-white mx-auto">
                <h3 className="text-center">Create Exam</h3>

                <Form
                  className=" flex flex-col w-full"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                  layout="vertical"
                  onFinishFailed={this.onFinishFailed}
                >
                  <Form.Item
                    name="title"
                    label="Exam Title"
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of exam",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="description" label="Description">
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item name="start_time" label="Start Date and time">
                    <DatePicker
                      format={dateFormat}
                      onChange={(date) =>
                        this.setState({ start_date: date.format(dateFormat) })
                      }
                      defaultValue={dayjs(
                        dayjs().format(dateFormat),
                        dateFormat
                      )}
                      allowClear={false}
                    />
                    &nbsp;
                    <TimePicker
                      defaultValue={dayjs(
                        dayjs().format(timeFormat),
                        timeFormat
                      )}
                      allowClear={false}
                      format={timeFormat}
                      onChange={(time) =>
                        this.setState({ start_time: time.format(timeFormat) })
                      }
                      use12Hours={true}
                    />
                  </Form.Item>
                  <Form.Item name="end_time" label="End Date and time">
                    <DatePicker
                      format={dateFormat}
                      onChange={(date) =>
                        this.setState({ end_date: date.format(dateFormat) })
                      }
                      allowClear={false}
                    />
                    &nbsp;
                    <TimePicker
                      allowClear={false}
                      format={timeFormat}
                      onChange={(time) =>
                        this.setState({ end_time: time.format(timeFormat) })
                      }
                      use12Hours={true}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Duration"
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: "Please input the duration of exam",
                      },
                    ]}
                  >
                    <TimePicker
                      allowClear={false}
                      label="Select duration"
                      format="HH:mm:ss"
                      onChange={(time) =>
                        this.setState({ duration: time.format("HH:mm:ss") })
                      }
                      use12Hours={false}
                    />
                  </Form.Item>
                  <Form.Item label="Select Batches (Optional)">
                    <Select
                      mode="multiple"
                      name="users"
                      labelInValue
                      value={selected_batches}
                      placeholder="Type to search Batches"
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      filterOption={false}
                      onSearch={this.fetchBatches}
                      onChange={this.handleBatchesChange}
                      style={{ width: "100%" }}
                      size="medium"
                      showArrow={true}
                    >
                      {batches.map((d) => (
                        <Option key={d.id}>{d.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="is_public">
                    <Checkbox defaultChecked={true}>Is Public?</Checkbox>
                  </Form.Item>
                  <Form.Item className="text-left">
                    <Button
                      type="primary"
                      loading={this.state.saving}
                      htmlType="submit"
                    >
                      Create Exam
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, loading } = state.user;
  return {
    user,
    loading,
  };
}

export const CreateExamPage = connect(mapStateToProps)(CreateExam);

export default CreateExamPage;
