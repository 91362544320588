import { pageConstants } from "../constants";

const initialState = {
  currentStudentPage: null
};

export function page(state = initialState, action) {
  switch (action.type) {
    case pageConstants.STUDENT_APPROVED_PAGE:
      return {
        ...state,
        currentStudentPage: pageConstants.STUDENT_APPROVED_PAGE
      };
    case pageConstants.STUDENT_REJECTED_PAGE:
      return {
        ...state,

        currentStudentPage: pageConstants.STUDENT_REJECTED_PAGE
      };
    case pageConstants.STUDENT_PENDING_PAGE:
      return {
        ...state,

        currentStudentPage: pageConstants.STUDENT_PENDING_PAGE
      };
    default:
      return state;
  }
}
