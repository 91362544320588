import React, { Component } from "react";

import Avatar from "../Avatar";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo_white.svg";
import Transition from "../Transition";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userConstants } from "../../constants";


function logout() {
  localStorage.clear(userConstants.AUTH_TOKEN);
  window.location = "/";
}

class TutorNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isMobileMenuOpen: false,
    };
  }

  render() {
    return (
      <nav className="bg-primary">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a href="/">
                  <img
                    className="w-48 h-auto"
                    src={Logo}
                    alt="Sprucedutech logo"
                  />
                </a>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <Link
                  className="px-3 py-2 rounded-md text-sm hover:bg-white font-medium text-white focus:outline-none"
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className="px-3 py-2 rounded-md text-sm font-medium hover:bg-white text-white focus:outline-none"
                  to="/search-jobs"
                >
                  Search Jobs
                </Link>
                <Link
                  className="px-3 py-2 rounded-md text-sm font-medium hover:bg-white text-white focus:outline-none "
                  to="/live-class"
                >
                  Live Class
                </Link>
                <Link
                    className="px-3 py-2 rounded-md text-sm hover:bg-white font-medium text-white focus:outline-none"
                    to="/exams"
                >
                  Exams
                </Link>
                <Link
                    className="px-3 py-2 rounded-md text-sm hover:bg-white font-medium text-white focus:outline-none"
                    to="/batches"
                >
                  Batches
                </Link>
                <Link
                    className="px-3 py-2 rounded-md text-sm hover:bg-white font-medium text-white focus:outline-none"
                    to="/questions"
                >
                  Questions
                </Link>
                {/* Profile dropdown */}
                <div
                  className="ml-3 relative"
                  ref={(node) => (this.node = node)}
                >
                  <div>
                    <button
                      className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                      id="user-menu"
                      aria-label="User menu"
                      aria-haspopup="false"
                      onClick={() => {
                        this.setState({
                          isDropdownOpen: !this.state.isDropdownOpen,
                        });
                      }}
                    >
                      <Avatar user={this.props.user.user} />
                    </button>
                  </div>
                  <Transition
                    show={this.state.isDropdownOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                      <div
                        className="py-1 rounded-md bg-white shadow-xs"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                      >
                        <p className="block px-4 py-2 text-sm mb-0 text-gray-700 hover:bg-gray-100">
                          {" "}
                          Signed in as{" "}
                          <strong>{this.props.user.user.username} </strong>
                        </p>
                        <a
                            href="/update-profile"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                        >
                          Edit Profile
                        </a>
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          onClick={logout}
                        >
                          Sign out
                        </a>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => {
                  this.setState({
                    isMobileMenuOpen: !this.state.isMobileMenuOpen,
                  });
                }}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
              >
                <svg
                  className={`${
                    this.state.isMobileMenuOpen ? "hidden" : "block"
                  } h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`${
                    this.state.isMobileMenuOpen ? "block" : "hidden"
                  } h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${
            this.state.isMobileMenuOpen ? "block" : "hidden"
          } md:hidden`}
        >
          <div className="pt-4 pb-3">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <Avatar user={this.props.user.user} />
              </div>
              <div className="ml-3">
                <div className="text-base font-medium leading-none text-white">
                  {this.props.user.user.username}
                </div>
                <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                  {this.props.user.user.email}
                </div>
              </div>
            </div>
            <div className="mt-3 px-2">
              <a
                href="#"
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
                onClick={logout}
              >
                Sign out
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

const connectedTutorNavbar = connect(mapStateToProps)(withRouter(TutorNavbar));
export default connectedTutorNavbar;
