import {examConstants} from "../constants";

let initialState = { error: null}

export function exam(state = initialState, action) {
  switch (action.type) {
    case examConstants.EXAM_DETAILS_REQUEST:
       return {
        ...state,
        loading: true,
      };
    case examConstants.EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        examDetails: action.examDetails,
        success: true,
        error: null
      };
    case examConstants.EXAM_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false
      };
    default:
      return state;
  }
}

export function examQuestions(state={}, action) {
  switch (action.type) {
    case examConstants.EXAM_QUESTIONS_REQUEST:
       return {
        ...state,
        loading: true
      };
    case examConstants.EXAM_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        examQuestions: action.examQuestions,
        success: true,
        error: null
      };
    case examConstants.EXAM_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
        examQuestions: [],
        success: false
      };
    default:
      return state;
  }
}

export function exams(state={exams: {results: []}}, action) {
  switch (action.type) {
    case examConstants.GET_EXAMS_REQUEST:
       return {
        ...state,
        loading: true
      };
    case examConstants.GET_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        exams: action.exams,
        success: true,
        error: null
      };
    case examConstants.GET_EXAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
        exams: [],
        success: false
      };
    default:
      return state;
  }
}

export function attempts(state={attempts: {results: []}}, action) {
  switch (action.type) {
    case examConstants.ATTEMPTS_LIST_REQUEST:
      return {
        ...state,
        loading:true
      };
    case examConstants.ATTEMPTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        attempts: action.attempts
      };
    case examConstants.ATTEMPTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}