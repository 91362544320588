import { Button, Card, Layout, List } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { examService } from "../../../services";

const { Content } = Layout;

const ExamsListCards = ({
  exams,
  initLoading,
  loadMore,
  dispatch,
  history,
}) => {
  return (
    <>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        loading={initLoading}
        loadMore={loadMore}
        dataSource={exams}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Card title={item.title} className="shadow-md">
              <p>{item.description}</p>
              <p>
                <strong>Start Date : </strong>
                &nbsp; {dayjs(item.start_date).format("DD/MM/YYYY hh:mm a")}
              </p>

              {item.end_date && (
                <p>
                  <strong>End Date : </strong>
                  &nbsp; {dayjs(item.end_date).format("DD/MM/YYYY hh:mm a")}
                </p>
              )}

              <p>
                <strong>Duration : </strong>
                &nbsp; {item.duration}
              </p>
              <p>
                <strong>Number of Questions : </strong>
                &nbsp; {item.number_of_questions}
              </p>
              <div className="flex">
                <Link to={`/exams/${item.slug}/attempts`}>
                  <Button type="default">View Score</Button>
                </Link>
              </div>
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};

class UpcomingExamsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      page: 0,
      total_page: 1,
    };
  }

  componentDidMount() {
    this.fetchExams();
  }

  fetchExams = (clear_existing = false) => {
    this.setState({
      loading: true,
    });
    if (clear_existing) {
      this.setState({
        list: [],
        page: 0,
      });
    }
    const queryParams = { page: this.state.page + 1 };
    examService.getCompletedExams(queryParams).then((response) => {
      this.setState((prevState) => ({
        list: [...prevState.list, ...response["results"]],
        initLoading: false,
        loading: false,
        page: response["page"],
        total_pages: response["total_pages"],
      }));
    });
  };

  onLoadMore = () => {
    this.fetchExams();
  };

  renderDataOrEmptyPage = () => {
    const { initLoading, loading, list, total_pages, page } = this.state;

    const loadMore =
      page < total_pages && !loading ? (
        <div
          className="mb-2"
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={this.onLoadMore}>Load More</Button>
        </div>
      ) : null;

    return (
      <ExamsListCards
        dispatch={this.props.dispatch}
        exams={list}
        loadMore={loadMore}
        history={this.props.history}
        initLoading={initLoading}
        loading={loading}
      />
    );
  };

  render() {
    return (
      <>
        <Layout>
          <Content>
            <div className="flex">
              <h3 className="text-center text-2xl font-bold">
                Completed Exams
              </h3>
            </div>
            {this.renderDataOrEmptyPage()}
          </Content>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { exams, error, loading } = state.exams;
  return {
    exams,
    error,
    loading,
  };
}

const connectedExamsListComponent = connect(mapStateToProps)(UpcomingExamsList);
export default connectedExamsListComponent;
