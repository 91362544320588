import { userConstants } from "../constants";


let initialState = {
  loading: false,
  items: [],
  error: null,
}

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
