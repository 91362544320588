import { classDetailConstants } from "../constants";

let initialState = {
  error: null,
  classDetails: {
    results: []
  },
  loading: false
};

export function classDetails(state = initialState, action) {
  switch (action.type) {
    case classDetailConstants.LOADING:
      return {
        ...state,
        loading: true
      };
    case classDetailConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        classDetails: action.classDetails
      };
    case classDetailConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
