import React from "react";

import { Layout, Menu } from "antd";
import { Link, withRouter } from "react-router-dom";

import Logo from "../../assets/img/logo_white.svg";
import Avatar from "../Avatar";
import { connect } from "react-redux";

const { SubMenu } = Menu;
const { Sider } = Layout;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [],
    };
    this.rootSubmenuKeys = ["classes", "exams"];
  }

  onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: keys });
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  };

  render() {
    return (
      <Sider
        breakpoint="xs"
        width="260px"
        collapsedWidth="0"
        trigger={null}
        onAfterChange={(e) => console.log(e)}
        collapsible
        collapsed={this.props.collapse}
      >
        <div className="logo">
          <a href="/">
            <img className="w-48 h-auto" src={Logo} alt="Sprucedutech logo" />
          </a>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          selectedKeys={[this.props.location.pathname]}
        >
          <Menu.Item
            key="dashboard"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/">
              <span className="align-middle">Dashboard</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="classes"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              </span>
            }
            title={<span className="align-middle">My Classes</span>}
          >
            <Menu.Item key="/ongoing_classes">
              <Link
                to="/ongoing_classes"
                className="hover:text-black hover:bg-transparent"
              >
                Ongoing Classes
              </Link>
            </Menu.Item>
            <Menu.Item key="/pending_requests">
              <Link to="/pending_requests" className="hover:bg-transparent">
                Requested Classes
              </Link>
            </Menu.Item>
            <Menu.Item key="/live_classes">
              <Link to="/live_classes" className="hover:bg-transparent">
                Live Classes
              </Link>
            </Menu.Item>
            <Menu.Item key="/request_tutor">
              <Link to="/request_tutor" className="hover:bg-transparent">
                Request Classes
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="exams"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </span>
            }
            title={<span className="align-middle">Exams</span>}
          >
            <Menu.Item key="/available_exams">
              <Link
                to="/available_exams"
                className="hover:text-black hover:bg-transparent"
              >
                Available Exams
              </Link>
            </Menu.Item>
            <Menu.Item key="/upcoming_exams">
              <Link to="/upcoming_exams" className="hover:bg-transparent">
                Upcoming Exams
              </Link>
            </Menu.Item>
            <Menu.Item key="/completed_exams">
              <Link to="/completed_exams" className="hover:bg-transparent">
                Completed Exams
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="reviews"
            icon={
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline-flex mr-2 align-middle"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </span>
            }
          >
            <Link to="/reviews">
              <span className="align-middle">Reviews</span>
            </Link>
          </Menu.Item>
        </Menu>
        <div
          className="flex mt-auto text-white p-4 items-center"
          style={{ background: "#222a37" }}
        >
          <div className="mr-4">
            <Avatar user={this.props.user} size="large" className="mr-2" />
          </div>
          <div className="text-white">
            <h4 className="text-white font-medium text-lg">
              {this.props.user.display_name}
            </h4>
            <div className="text-gray-300 my-0 flex items-baseline">
              <span className="bg-green-500 h-3 w-3 mr-2 rounded-full" />
              <span>Online</span>
            </div>
          </div>
        </div>
      </Sider>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withRouter(Sidebar));
