import React, { useState } from 'react'
import {userService} from "../services";

import {Form, Input, Button, notification} from 'antd';

const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};

const ResendActivationEmail = () => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const onFinish = (values) => {
		setLoading(true);
		userService.resendActivationMail({"email": values.email})
			.then(() => {
				setLoading(false);
				form.resetFields();
				notification["success"]({
					message: 'Email Sent',
					description:
							'Please click activation link from your email.',
				});

			})
			.catch(() => {
				setLoading(false);
				notification["error"]({
					message: 'Error in sending email',
					description:
							'Please try again later.',
				});
			})
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
			<div className="flex bg-gray-100 flex-col justify-center h-full items-center">
			<div className="bg-white p-8 shadow-lg">
				<h3 className="text-center mb-3">Resend Activation Link</h3>
				<Form
						name="basic"
						layout="vertical"
						style={{'width': '400px'}}
						onFinish={onFinish}
						form={form}
						onFinishFailed={onFinishFailed}
				>
					<Form.Item
							name="email"
							label="Email Address"
							rules={[
								{
									required: true,
									type: 'email',
									message: 'Please input your email!',
								},
							]}
					>
						<Input />
					</Form.Item>
					<p className="my-2">This email address should be same as the one you provided during signup</p>

					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit" loading={loading}>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</div>
			</div>
	);
};

export default ResendActivationEmail;
