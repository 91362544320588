import { Avatar, Card, Modal, Skeleton, Tag } from "antd";

import React, { useState } from "react";

export function PendingEnquiryCard(props) {
  function getAvatar(user) {
    if (user.profile_photo_url) {
      return <Avatar src={user.profile_photo_url} />;
    }
    return <Avatar>{user.display_name.charAt(0)}</Avatar>;
  }

  function getStatusColor(status) {
    switch (status) {
      case "Approved":
        return "#4bbf73";
      case "Pending":
        return "#f0ad4e";
      case "Rejected":
        return "#d9534f";
    }
  }

  const { loading, enquiry } = props;
  const [visible, setVisible] = useState(false);

  return (
    <div className="flex">
      <Card
        className="m-4 shadow-md margin-side-less md:max-w-md max-w-full"
        style={{
          width: 400,
        }}
      >
        <Skeleton loading={loading} avatar active>
          <div className="md:flex bg-white overflow-hidden">
            <div></div>
            <div className="w-full mt-2 sm:mt-0">
              <h2 className="font-bold text-lg mb-0">
                {enquiry ? enquiry.requested_for.display_name : null}
              </h2>
              <p className="whitespace-pre-wrap capitalize">
                {enquiry ? enquiry.description : null}
              </p>
              <div className="my-4">
                <strong className="mr-2">Status:</strong>
                <Tag
                  color={enquiry ? getStatusColor(enquiry.status) : null}
                  className="rounded-lg font-bold"
                >
                  {enquiry ? enquiry.status : null}
                </Tag>
              </div>
              <p onClick={() => setVisible(true)}>
                <strong className="mr-2">Tutor:</strong>
                <Avatar
                  src={enquiry ? getAvatar(enquiry.requested_for) : null}
                />
              </p>
            </div>
            <Modal
              title="About Tutor"
              centered
              cancelButtonProps={{ style: { display: "none" } }}
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              okText="Close"
            >
              <p>
                <strong>Name:</strong>
                {enquiry ? enquiry.requested_for.display_name : null}
              </p>
              <p onClick={() => setVisible(true)}>
                <strong>Bio:</strong>
                {enquiry ? enquiry.requested_for.biography : null}
              </p>
            </Modal>
          </div>
        </Skeleton>
      </Card>
    </div>
  );
}
