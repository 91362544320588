import { addQueryParam, authHeader, config, handleResponse } from "../helpers";

export const dashboardService = {
  getData,
};

function getData(queryParams) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };
  let url = `${config.apiUrl}/dashboard/`;
  url = addQueryParam(url, queryParams);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
