import { jobConstants } from "../constants";
import { jobService } from "../services";

export const jobActions = {
  getJobs,
  createJob,
  updateJob,
  deleteJob
};

function getJobs(queryParams) {
  return dispatch => {
    dispatch(request());

    jobService.getJobs(queryParams).then(
      jobs => {
        dispatch(success(jobs));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: jobConstants.JOBS_LIST_REQUEST };
  }
  function success(jobs) {
    return { type: jobConstants.JOBS_LIST_SUCCESS, jobs };
  }
  function failure(error) {
    return { type: jobConstants.JOBS_LIST_FAILURE, error };
  }
}

function createJob(jobDetails) {
  return dispatch => {
    dispatch(request());

    jobService.createJob(jobDetails).then(
      jobs => {
        dispatch(success(jobs));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: jobConstants.CREATE_JOB_REQUEST };
  }
  function success(jobs) {
    return { type: jobConstants.CREATE_JOB_SUCCESS, jobs };
  }
  function failure(error) {
    return { type: jobConstants.CREATE_JOB_FAILURE, error };
  }
}

function updateJob(jobDetails) {
  return dispatch => {
    dispatch(request());

    jobService.updateJob(jobDetails).then(
      jobs => {
        dispatch(success(jobs));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: jobConstants.UPDATE_JOB_REQUEST };
  }
  function success(jobs) {
    return { type: jobConstants.UPDATE_JOB_SUCCESS, jobs };
  }
  function failure(error) {
    return { type: jobConstants.UPDATE_JOB_FAILURE, error };
  }
}

function deleteJob(jobDetails) {
  return dispatch => {
    dispatch(request());

    jobService.deleteJob(jobDetails).then(
      jobs => {
        dispatch(success(jobs));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: jobConstants.DELETE_JOB_REQUEST };
  }
  function success(jobs) {
    return { type: jobConstants.DELETE_JOB_SUCCESS, jobs };
  }
  function failure(error) {
    return { type: jobConstants.DELETE_JOB_FAILURE, error };
  }
}
