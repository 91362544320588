import { courseService, utilService } from "../../services";

import AsyncSelect from "react-select/async";
import React from "react";
import ReactS3Uploader from "react-s3-uploader";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import {Radio, Form, Input, Button, Space, DatePicker, Select} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

class TutorDetailComponent extends React.Component {
  constructor(props) {
    console.log("rendering update");
    super(props);
    this.state = {
      educationDetailComponentKey: 1,
      experieceDataComponentKey: 1,
      inputValue: "",
      experienceData: [
        {
          index: 0,
          institute: "",
          qualification: "",
          start_date: "",
          end_date: ""
        }
      ],
      educationData: [
        {
          index: 0,
          institute: "",
          degree: "",
          start_date: "",
          end_date: ""
        }
      ],
      resume: "",
      referenceLetter: "",
      biography: "",
      prefferedRole: "",
      prefferedLevel: "",
      specialization: [],
      linkedinProfileLink: ""
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = values => {
    values.education.map((education) => {
      education.start_date = education.start_date.format("YYYY-MM-DD")
      education.end_date = education.end_date.format("YYYY-MM-DD")
    })
    values.experience.map((experience) => {
      experience.start_date = experience.start_date.format("YYYY-MM-DD")
      experience.end_date = experience.end_date.format("YYYY-MM-DD")
    })
    this.props.dispatch(
      userActions.updateProfile({
        id: this.props.user.id,
        experience: values.experience,
        education: values.education,
        course_preference: this.state.specialization,
        biography: this.state.biography,
        resume: this.state.resume,
        reference: this.state.referenceLetter,
        linkedin_url: this.state.linkedinProfileLink,
        preferred_role: this.state.prefferedRole,
        preferred_level: this.state.prefferedLevel
      })
    );
  };

  getOptionValue = option => option.id;

  getOptionLabel = option => option.name;

  handleChange = (selectedOption, meta) => {
    const { name } = meta;

    if (!selectedOption) {
      this.setState({ [name]: [] });
      return;
    }
    let selected_values = selectedOption.map(course => course.id);
    this.setState({ [name]: selected_values });
  };

  getCourses = query => {
    return courseService.getCourses(query).then(
      courses => courses["results"],
      error => {
        console.err(error);
      }
    );
  };

  onResumeUploadFinish = (result, file) => {
    var parsedUrl = result["signedUrl"].split("?")[0];
    var pathname = new URL(parsedUrl).pathname;
    this.setState({
      resume: pathname
    });
  };

  onReferenceLetterFinish = result => {
    var parsedUrl = result["signedUrl"].split("?")[0];
    var pathname = new URL(parsedUrl).pathname;
    this.setState({
      referenceLetter: pathname
    });
  };

  getSignedUrl = (file, callback) => {
    const uploadDetails = {
      filename: file.name,
      public: true
    };
    utilService.uploadFile(uploadDetails).then(data => {
      const result = { signedUrl: data };
      callback(result);
    });
  };

  render() {
    if (this.props.updateProfileSuccess || this.props.user.is_profile_complete)
      this.props.history.push("/");

    return (
      <Form
        className="bg-gray-300 min-h-screen flex flex-col"
        onSubmit={this.submit}
        onFinish={this.submit}
        style={{ maxWidth: '100%'}}
        initialValues={{education:[{institute:""}], experience:[{institute:""}]}}
      >
        <div className=" w-2/3 my-10 mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 className="mb-8 text-3xl text-center">Update details</h1>
            <div className="flex justify-between">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Education details
              </label>
            </div>
            <div className="flex justify-between w-full">
              <Form.List name="education" className="w-full">
                {(fields, { add, remove }) => {
                  return (
                    <div className="w-full">
                      {fields.map((field, index) => (
                        <Space key={field.key} className="w-full" align="start">
                          <Form.Item
                            className="w-full"
                            {...field}
                            name={[field.name, 'institute_name']}
                            fieldKey={[field.fieldKey, 'institute_name']}
                            rules={[{ required: true, message: 'Please enter your college name' }]}
                          >
                            <Input placeholder="College Name" className="w-full" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            className="w-full"
                            name={[field.name, 'degree']}
                            fieldKey={[field.fieldKey, 'degree']}
                            rules={[{ required: true, message: 'Please select your degree' }]}
                          >
                            <Select placeholder="Select your degree"
                              style={{ width: '100%' }}>
                              <Option value="Diploma">Diploma</Option>
                              <Option value="Bachelors">Bachelors</Option>
                              <Option value="Masters">Masters</Option>
                              <Option value="PhD">PhD</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            className="w-full"
                            name={[field.name, 'start_date']}
                            fieldKey={[field.fieldKey, 'start_date']}
                            rules={[{ required: true, message: 'Please select your start year' }]}
                          >
                          <DatePicker placeholder="Start year" picker="month" />
                        </Form.Item>

                            <Form.Item
                            className="w-full"
                            name={[field.name, 'end_date']}
                            fieldKey={[field.fieldKey, 'end_date']}
                            rules={[{ required: true, message: 'Please select your end year' }]}
                          >
                          <DatePicker placeholder="End year" picker="month" />
                        </Form.Item>
                          {
                            index > 0 &&
                            <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          }

                        </Space>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add field
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </div>
            <div className="flex justify-between">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Professional details
              </label>


            </div>
            <div className="flex justify-between w-full">
              <Form.List name="experience" className="w-full">
                {(fields, { add, remove }) => {
                  return (
                    <div className="w-full">
                      {fields.map((field, index) => (
                        <Space key={field.key} className="w-full" align="start">
                          <Form.Item
                            className="w-full"
                            {...field}
                            name={[field.name, 'institute_name']}
                            fieldKey={[field.fieldKey, 'institute_name']}
                            rules={[{ required: true, message: 'Please enter the institution name where you worked' }]}
                          >
                            <Input placeholder="Institution Name" className="w-full" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            className="w-full"
                            name={[field.name, 'qualification']}
                            fieldKey={[field.fieldKey, 'qualification']}
                            rules={[{ required: true, message: 'Missing Qualification' }]}
                          >
                            <Input placeholder="Qualification" />
                          </Form.Item>
                          <Form.Item
                            className="w-full"
                            name={[field.name, 'start_date']}
                            fieldKey={[field.fieldKey, 'start_date']}
                            rules={[{ required: true, message: 'Please select your start year' }]}
                          >
                          <DatePicker placeholder="Start year" picker="month" />
                        </Form.Item>

                            <Form.Item
                            className="w-full"
                            name={[field.name, 'end_date']}
                            fieldKey={[field.fieldKey, 'end_date']}
                            rules={[{ required: true, message: 'Please select your end year' }]}
                          >
                          <DatePicker placeholder="End year" picker="month" />
                        </Form.Item>
                          {
                            index > 0 &&
                            <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          }

                        </Space>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add field
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </div>
            <div className="mt-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Resume
              </label>

              <div className="flex  flex-wrap mb-2">
                <ReactS3Uploader
                  name="resume"
                  getSignedUrl={this.getSignedUrl}
                  onFinish={this.onResumeUploadFinish}
                  required={true}
                />
              </div>
            </div>
            <div className="mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Reference letter (Optional)
              </label>
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <ReactS3Uploader
                    name="reference"
                    getSignedUrl={this.getSignedUrl}
                    onFinish={this.onReferenceLetterFinish}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Name and address of currently workplace
              </label>
              <Form.Item name="workplace_details">
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  placeholder="Workplace Details"
                  name="workplace_details"
                  onChange={this.onChange} />
              </Form.Item>
            </div>
            <div className="mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Enter your biography
              </label>
              <Form.Item name="biography">
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  placeholder="Biography"
                  name="biography"
                  onChange={this.onChange} />
              </Form.Item>
            </div>
            <div className="mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Preffered Role
              </label>
              <Form.Item
                name="prefferedRole"
                rules={[{ required: true, message: 'Please select your preferred role' }]}
              >
                <Radio.Group onChange={this.onChange} name="prefferedRole" value={this.state.prefferedRole}>
                  <Radio value={1}>Part time</Radio>
                  <Radio value={2}>Full time</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Preffered Level
              </label>
              <Form.Item
                name="prefferedLevel"
                rules={[{ required: true, message: 'Please select your preferred level' }]}
              >
              <Radio.Group onChange={this.onChange} name="prefferedLevel" value={this.state.prefferedLevel}>
                <Radio value={1}>Play School</Radio>
                <Radio value={2}>Primary</Radio>
                <Radio value={3}>Higher Secondary</Radio>
              </Radio.Group>
              </Form.Item>
            </div>
            <div className="mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Preferred Subjects
              </label>
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <AsyncSelect
                    cacheOptions
                    closeMenuOnSelect={false}
                    loadOptions={this.getCourses}
                    defaultOptions
                    name="specialization"
                    onChange={this.handleChange}
                    getOptionValue={this.getOptionValue}
                    getOptionLabel={this.getOptionLabel}
                    isMulti
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Linkedin profile link
              </label>
              <Form.Item
                name="linkedinProfileLink"
                rules={[{type: 'url', message: "Please enter a valid url"}]}
              >
                <Input size="large" name="linkedinProfileLink" onChange={this.onChange} placeholder="Linkedin Profile link" className="w-full" />
              </Form.Item>

            </div>
            <div className="text-center mt-8">
              <Button
                htmlType="submit"
                className="custom-bottom gradient-btn"
                type="primary"
                shape="circle"
                block={false}
                style={{ width: "100px" }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const { user, updateProfileSuccess } = state.user;
  return {
    user,
    updateProfileSuccess
  };
}

const connectedTutoreDetailPage = connect(mapStateToProps)(
  TutorDetailComponent
);
export { connectedTutoreDetailPage as UpdateTutorDetails };
