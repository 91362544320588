import React, {Component} from 'react';
import {userService, utilService} from "../../services"
import {
	Button,
	Card,
	Col,
	Select,
	Form,
	Input,
	Layout,
	message,
	Row,
	Spin
} from "antd";
import ReactQuill, {Quill} from "react-quill";
import ImageUploader from "quill-image-uploader";

import 'react-quill/dist/quill.snow.css';
import "./Admin.css"

const {Content} = Layout;
const {Option} = Select;
Quill.register("modules/imageUploader", ImageUploader);


class SendEmail extends Component {

	constructor(props) {
		super(props);
		const {location} = props;

		this.state = {
			saving: false,
			selected_users: [],
			selected_user_ids: [],
			users: [],
			initialData: location.data
		};

		this.formRef = React.createRef();
	}

	fetchUsers = (value) => {
		this.setState({fetching: true});
		userService.getAll({search: value})
				.then(users => {
					let results = users["results"]
					this.setState({users: results, fetching: false});
				})
	};

	modules = {
		toolbar: [
			[{'header': [1, 2, 3, 4, 5, 6, false]}],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
			['link', 'image'],
			[{'color': []}, {'background': []}],
			['clean']
		],

		imageUploader: {
			upload: file => {
				return new Promise((resolve, reject) => {
					const formData = new FormData();
					formData.append('file', file);

					const uploadDetails = {
						filename: file.name,
						public: true
					};
					utilService.uploadFile(uploadDetails).then(data => {
						return data;
					}).then(signedUrl => {
						fetch(signedUrl, {
							method: "PUT",
							headers: {
								"x-amz-acl": "public-read",
								"Content-Type": file.type
							},
							body: file
						})
								.then(result => {
									const parsedUrl = signedUrl.split("?")[0];
									const pathname = new URL(parsedUrl).pathname;
									resolve(`https://media.sprucedutech.com${pathname}`);
								})
								.catch(error => {
									reject("Upload failed");
									console.error("Error:", error);
								});
					})
				});
			}
		}
	}

	formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link', 'image', 'color', 'background', 'clean'
	]

	handleUsersChange = values => {
		let selected_user_ids = values.map(user => parseInt(user.value))

		this.setState({
			selected_users: values,
			selected_user_ids: selected_user_ids,
			users: [],
			fetching: false,
		});
	};

	onFinish = (values) => {
		values["users"] = this.state.selected_user_ids;
		values["message"] = this.state.message;

		this.setState({saving: true});
		utilService.sendEmail(values)
				.then((response) => {
					this.setState({saving: false});
					this.formRef.current.resetFields();
					message.success("Mail sent sucessfully")
				})
				.catch(() => {
					this.setState({saving: false})
					message.error("Error in sending mail")
				})
	};

	onFinishFailed = (errorInfo) => {
		message.error("Error in sending mail");
		console.log("Failed:", errorInfo);
	};

	render() {
		const {fetching, users, selected_users, initialData} = this.state;

		return (
				<Layout className="bg-gray-100">
					<Content className="m-10 p-5">
						<Row>
							<Col span={12} offset={6}>
								<Card className="bg-white mx-auto">
									<h3 className="text-center">Send Mail</h3>
									<Form
											className=" flex flex-col w-full"
											name="basic"
											ref={this.formRef}
											initialValues={{to: [], subject: initialData ? initialData.subject : null}}
											onFinish={this.onFinish}
											layout="vertical"
											onFinishFailed={this.onFinishFailed}
									>
										<Form.Item name="subject" label="Subject" rules={[
											{
												required: true,
												message: "Please input the subject",
											},
										]}>
											<Input/>
										</Form.Item>

										<Form.Item name="to" label="To">
											<Select mode="tags"/>
										</Form.Item>
										<Form.Item name="cc" label="CC">
											<Select mode="tags"/>
										</Form.Item>
										<Form.Item name="bcc" label="BCC">
											<Select mode="tags"/>
										</Form.Item>
										<Form.Item
												label="Select Users"
										>
											<Select
													mode="multiple"
													name="users"
													labelInValue
													value={selected_users}
													placeholder="Type to search users"
													notFoundContent={fetching ? <Spin size="small"/> : null}
													filterOption={false}
													onSearch={this.fetchUsers}
													onChange={this.handleUsersChange}
													style={{width: '100%'}}
													size="medium"
													showArrow={true}
											>
												{users.map(d => (
														<Option key={d.id}>{d.display_name}</Option>
												))}
											</Select>
										</Form.Item>
										<ReactQuill defaultValue={initialData ? initialData.content : null} theme="snow"
																placeholder="Enter your message" onChange={(content) => {
											this.setState({message: content})
										}} className="mb-4" modules={this.modules} formats={this.formats}/>


										<Form.Item className="text-left">
											<Button type="primary" loading={this.state.saving} htmlType="submit">
												Send
											</Button>
										</Form.Item>
									</Form>
								</Card>
							</Col>
						</Row>
					</Content>
				</Layout>
		);
	}
}


export default SendEmail;