export const jobConstants = {
  JOBS_LIST_REQUEST: "JOBS_LIST_REQUEST",
  JOBS_LIST_SUCCESS: "JOBS_LIST_SUCCESS",
  JOBS_LIST_FAILURE: "JOBS_LIST_FAILURE",
  CREATE_JOB_REQUEST: "CREATE_JOB_REQUEST",
  CREATE_JOB_SUCCESS: "CREATE_JOB_SUCCESS",
  CREATE_JOB_FAILURE: "CREATE_JOB_FAILURE",
  UPDATE_JOB_REQUEST: "UPDATE_JOB_REQUEST",
  UPDATE_JOB_SUCCESS: "UPDATE_JOB_SUCCESS",
  UPDATE_JOB_FAILURE: "UPDATE_JOB_FAILURE",
  DELETE_JOB_REQUEST: "DELETE_JOB_REQUEST",
  DELETE_JOB_SUCCESS: "DELETE_JOB_SUCCESS",
  DELETE_JOB_FAILURE: "DELETE_JOB_FAILURE"
};
