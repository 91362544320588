import { Calendar, Layout, Tabs } from "antd";

import JobsComponent from "./components/JobsComponent";
import React from "react";
import TutorsListComponent from "./components/TutorsListComponent";
import MiniCommentsComponent from "../students/dashboard/MiniCommentsComponent";
import { connect } from "react-redux";
import { TutorTable } from "../students/dashboard/TutorTable";
import { dashboardActions } from "../../actions";

const { Content } = Layout;

export class Home extends React.Component {
  componentDidMount() {
    this.props.dispatch(dashboardActions.getData());
  }

  render() {
    const { dashboardData, user } = this.props;

    return (
      <React.Fragment>
        <Content>
          <h3 className="font-bold text-xl">
            Welcome back, {user.display_name}. You logged in as a Admin!
          </h3>
          <div>
            <div class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-4 md:grid-cols-2">
              <div class="bg-white overflow-hidden shadow-sm rounded">
                <div class="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-user-graduate fa-2x text-blue-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd class="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.requested_tutors}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Requested Tutors
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow-sm rounded">
                <div className="px-4 py-3 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 p-3">
                      <i className="fas fa-users fa-2x text-red-500" />
                    </div>
                    <div className="w-0 flex-1">
                      <dl>
                        <dd className="mb-0 text-3xl font-semibold text-gray-900">
                          {dashboardData && dashboardData.tutors}
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                          Tutors
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 my-4 overflow-hidden">
            <div className="w-full md:w-1/2 p-4 rounded-sm shadow-sm">
              <div className="bg-white p-4">
                <TutorTable />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-4 rounded-sm shadow-sm">
              <Calendar fullscreen={false} />
            </div>
          </div>
        </Content>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;
  const { loading, dashboardData, error } = state.dashboard;
  return {
    user,
    loading,
    dashboardData,
    error,
  };
}

export default connect(mapStateToProps)(Home);
