import React, { Component } from "react";
import { Avatar, Button, Input, Layout, List, message, Modal } from "antd";
import { questionSetService } from "../../services";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { confirm } = Modal;

class QuestionFolderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      page: 0,
      total_page: 1,
      folder: { name: null, slug: null },
    };
  }

  componentDidMount() {
    this.fetchFolders();
  }

  fetchFolders = (clear_existing = false) => {
    this.setState({
      loading: true,
    });
    if (clear_existing) {
      this.setState({
        list: [],
        page: 0,
      });
    }
    const queryParams = { page: this.state.page + 1 };
    questionSetService.getQuestionFolders(queryParams).then((response) => {
      this.setState((prevState) => ({
        list: [...prevState.list, ...response["results"]],
        initLoading: false,
        loading: false,
        page: response["page"],
        total_pages: response["total_pages"],
      }));
    });
  };

  onLoadMore = () => {
    this.fetchFolders();
  };

  deleteFolder = (slug) => {
    questionSetService
      .deleteQuestionFolder(slug)
      .then(() => {
        message.success("Successfully deleted folder");
        this.fetchFolders(true);
      })
      .catch(() => {
        message.error("Error in deleting folder");
      });
  };

  deleteFolderConfirmation = (slug) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this folder ?",
      onOk: () => {
        this.deleteFolder(slug);
      },
    });
  };

  createBatch = () => {
    if (this.state.folder.slug) {
      questionSetService
        .editQuestionFolder(this.state.folder.slug, {
          name: this.state.folder.name,
        })
        .then((response) => {
          this.setState(
            { folder: { name: null, slug: null }, visible: false, page: 0 },
            () => {
              this.fetchFolders(true);
            }
          );
          message.success("Successfully updated folder");
        })
        .catch((error) => {
          if (error.name) {
            message.error(error.name);
          } else {
            message.error("Error in updating folder. Please try again.");
          }
        });
    } else {
      questionSetService
        .createQuestionFolder({ name: this.state.folder.name })
        .then((response) => {
          this.setState(
            { folder: { name: null, slug: null }, visible: false, page: 0 },
            () => {
              this.fetchFolders(true);
            }
          );
          message.success("Successfully created folder");
        })
        .catch((error) => {
          if (error.name) {
            message.error(error.name);
          } else {
            message.error("Error in creating folder. Please try again.");
          }
        });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  renderModal = () => {
    return (
      <Modal
        title={this.state.folder.slug ? "Update Folder" : "Create Folder"}
        visible={this.state.visible}
        confirmLoading={this.props.loading}
        onCancel={() => {
          this.setState({ visible: false });
        }}
        onOk={this.createBatch}
        okButtonProps={{ disabled: this.props.success === true }}
        okText="Submit"
      >
        <Input
          name="folder_name"
          value={this.state.folder.name}
          onChange={(e) => {
            this.setState({
              folder: { name: e.target.value, slug: this.state.folder.slug },
            });
          }}
          description="Enter brief description"
        />
      </Modal>
    );
  };

  openCreateFolderModal = () => {
    this.setState({ folder: { name: null, slug: null }, visible: true });
  };

  render() {
    const { initLoading, loading, list, total_pages, page } = this.state;
    const loadMore =
      page < total_pages && !loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={this.onLoadMore}>Load More</Button>
        </div>
      ) : null;

    return (
      <>
        <Layout>
          <Content>
            {this.renderModal()}
            <div className="flex my-2 justify-between border-b-1 pb-2">
              <h3 className="text-center text-2xl font-bold">
                Question Folders
              </h3>
              <div>
                <Button type="primary" onClick={this.openCreateFolderModal}>
                  Create Folder
                </Button>
                <Link to={`/questions/add`} className="ml-3">
                  <Button type="primary">Add New Question</Button>
                </Link>
              </div>
            </div>
            <div className="px-5 bg-white shadow-sm">
              <List
                itemLayout="horizontal"
                dataSource={list}
                loading={initLoading}
                loadMore={loadMore}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Link to={`/questions/${item.slug}`}>
                        <Button type="primary">View Questions</Button>
                      </Link>,
                      <Link to={`/create-exam/?folder=${item.slug}`}>
                        <Button type="default">Create Exam</Button>
                      </Link>,
                      <Button
                        onClick={() => {
                          this.setState({
                            folder: { name: item.name, slug: item.slug },
                            visible: true,
                          });
                        }}
                        type="dashed"
                      >
                        Edit Name
                      </Button>,
                      <Button
                        type="default"
                        danger
                        onClick={() => {
                          this.deleteFolderConfirmation(item.slug);
                        }}
                      >
                        Delete
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar style={{ backgroundColor: "#16B7D3" }}>
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <Link to={`/questions/${item.slug}`}>{item.name}</Link>
                      }
                      description={
                        <span>No of Questions : {item.questions_count}</span>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </Content>
        </Layout>
      </>
    );
  }
}

export default QuestionFolderList;
