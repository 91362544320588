import { authHeader, config, handleResponse } from "../helpers";

export const reviewService = {
  getReviews,
  addReview,
};

function getReviews(pageNumber) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
  };
  let url = `${config.apiUrl}/reviews/`;
  if (pageNumber) {
    url += `?page=${pageNumber}`;
  }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reviews) => {
      return reviews;
    });
}

function addReview(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    },
    body: JSON.stringify(data),
  };
  let url = `${config.apiUrl}/reviews/`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reviews) => {
      return reviews;
    });
}
